import React, { useEffect } from 'react';
import './RawMaterial.css'
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import led from '../ledimg.png';
import api from '../../Components/Registration/Api';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


// finished products


function RawMaterial() {
    const navigate=useNavigate()
    const [isCategoryOpen, setCategoryOpen] = useState(false);
    const [isSubCategoryOpen, setSubCategoryOpen]=useState(false)

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories]=useState([])

  const [isPriceOpen, setPriceOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [selectedRatings, setSelectedRatings] = useState([]);
const [loading, setLoading]=useState(true)

const [subcategoryPage, setSubCategoryPage]=useState(1)
const [subCategoryPagination, setSubCategoryPagination]=useState({next:null})

const [products, setProducts]=useState([])
const [page, setPage]=useState(1)
const [pagination, setPagination]=useState({next:null})

const [categoryList, setCategoryList]=useState([])
 const [subCategoryList, setSubCategoryList]=useState([])

const fetchCategoryData=async()=>{
  try {
   const link=process.env.REACT_APP_BASE_URL
   const endpoint='/api/listproductCategory'
   const url=link+endpoint

   const res=await api.get(`${url}`)
   if(res.status==200){
       const data=res.data.results
       console.log(data)
       setCategoryList(data)
       setLoading(false)
   }
  } catch (error) {
   console.error(error)
  }
}


const fetchSubCategoryData=async()=>{
  try {
   const link=process.env.REACT_APP_BASE_URL
   const endpoint=`/api/listproductSubCategory?page=${subcategoryPage}`
   const url=link+endpoint

   const res=await api.get(`${url}`)
   if(res.status==200){
       const data=res.data.results
       setSubCategoryList(data)
       setSubCategoryPagination({next:res.data.next})
       setLoading(false)
   }
  } catch (error) {
   console.error(error)
  }
}



useEffect(
  ()=>{
    fetchCategoryData()
  }
,[page])
useEffect(()=>{
  fetchSubCategoryData()
}, [subcategoryPage])

console.log('category list', categoryList)


  const toggleCategory = () => {
    setCategoryOpen(!isCategoryOpen);
  };
  const toggleSubCategory=()=>{
    setSubCategoryOpen(!isSubCategoryOpen)
  }
  const togglePrice = () => {
    setPriceOpen(!isPriceOpen);
  };
   // Toggle dropdown visibility
   const toggleRating = () => {
    setIsRatingOpen(!isRatingOpen);
  };


  // Handle checkbox change
  const handleRatingChange = (event) => {
    const value = event.target.value;
    setSelectedRatings((prevRatings) => {
      if (prevRatings.includes(value)) {
        // Remove rating if it's already selected
        return prevRatings.filter((rating) => rating !== value);
      } else {
        // Add rating if it's not selected
        return [...prevRatings, value];
      }
    });
  };

  // const handleCategoryChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedCategories((prevSelected) => {
  //     if (prevSelected.includes(value)) {
  //       return prevSelected.filter((item) => item !== value);
  //     } else {
  //       return [...prevSelected, value];
  //     }
  //   });
  // };
  const [currentCategory, setCurrentCategory] = useState(null); // Currently opened category

  const handleCategoryChange = async(event) => {
    const categoryId = parseInt(event.target.value, 10);
    // if (event.target.checked) {
    //   setSelectedCategories((prev) => [...prev, categoryId]);
    // } else {
    //   setSelectedCategories((prev) =>
    //     prev.filter((id) => id !== categoryId)
    //   );
    // }
    setSelectedCategories([categoryId]); // Reset and set the selected category
    setCurrentCategory(categoryId); // Track which category is selected
    // "selectedCategoryIds": [4, 2]
    try {
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/filter/product/by/category'
      const url=link+endpoint

      const data={
        category_id:categoryId
      }

      const response = await api.post(
        url,
        data,{
          headers:{
            "Content-Type":'application/json'
          }
        }
      );
      
      console.log('category list nnn',response)
      setProducts(response.data)
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    }
  };


    // Handle price range selection
    const handlePriceChange = (event) => {
        setPrice(event.target.value);
      };




      useEffect(() => {
       
        fetchData()
        },[page])

        const fetchData=async()=>{
          const link = process.env.REACT_APP_BASE_URL;
          const endpoint = `/api/user/listproductitem/?page=${page}`;
          const url = link + endpoint;
          try {
            const res = await axios.get(`${url}`);  
            if (res.status == 200) {
              setProducts(res.data.results);
              setPagination({next:res.data.next})
              setLoading(false)
            } 
          } catch (error) {
            console.error(error);
          };
        }  
        
        const viewMore = (productId) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(`/userProductDetail/${productId}`);
        };

        
  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleBackSubcategroyClick=()=>{
    if(subcategoryPage>1){
      setSubCategoryPage(subcategoryPage-1)
    }
  }
  const handleForwareSubCategoryClick=()=>{
    if(subCategoryPagination.next){
      setSubCategoryPage((prev)=>prev+1)
    }
  }

  const handleForwardClick = () => {
    if(pagination.next){
      setPage((prevPage) => prevPage + 1);
    }  
  };

  const Download_catalog = () => {
    // Construct the file path relative to the public folder
    const filePath = '/Catalogue.pdf';
    const link = document.createElement('a');
    link.href = filePath; // Set the file path
    link.download = 'catalog.pdf'; // Set the desired file name for download
    document.body.appendChild(link); // Append the link to the document
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Remove the link after the download
  };





    // Show loader while data is being fetched
    if (loading) {
      return (
        <div className="loader-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      );
    }



  return (    
    <div>


      <div className='main_subdropdown_div_customer'>

 
      
        <div className='main_left_div_customer'>
          <div className='led_head_para_customer'>
            <h4>Finished Materials</h4>
            {/* <p>Showing 61-80 out of 10000 products</p> */}

            <button className='download_catalog' onClick={Download_catalog} style={{marginBottom:'20px'}}> Catalogue <FontAwesomeIcon icon={faDownload} />
            </button>

      
          </div>

          {/* <div className='dropdown_container_rawmaterial_customer'>
          <select className='dropdown_rawmaterial_customer'>
            <option value="relevance">Sort By: relevance</option>
            <option value="led1">LED 1</option>
            <option value="led2">LED 2</option>
            <option value="led3">LED 3</option>
            <option value="led4">LED 4</option>
            <option value="led5">LED 5</option>
          </select>
        </div> */}

        <div class="filter_customer">
  <p class="filter-title_customer">FILTERS</p>
  <hr class="filter-divider_customer" />
  
  <div className="filter-option_customer" onClick={toggleCategory}>
              <p>Category</p>
              <span>&#9662;</span>
            </div>

<div className="checkbox-group_customer">
               {isCategoryOpen && categoryList && categoryList.map((category) => (                
                <label key={category.id}>
                  <input
                    type="checkbox"
                    value={category?.id}
                    checked={selectedCategories.includes(category?.id)}
                    onChange={handleCategoryChange}
                  />
                  {category?.category_name}
                </label>              
              ))}
         </div>  



      
         <div className="filter-option_customer" onClick={toggleSubCategory}>
              <p>Sub Category</p>
              <span>&#9662;</span>
            </div>
            
         <div className="checkbox-group_customer">
               {isSubCategoryOpen && subCategoryList && subCategoryList.map((category) => (                
                <label key={category.id}>
                  {/* <input
                    type="checkbox"
                    value={category?.id}
                    // checked={selectedCategories.includes(category?.id)}
                    // onChange={handleCategoryChange}
                  /> */}
                  {category?.sub_category_name}
                </label>              
              ))}
         </div>  

         {
          isSubCategoryOpen && (
            <div className='subcategorypaginationclass'>
          <button onClick={handleBackSubcategroyClick} disabled={subcategoryPage==1}>
            <IoIosArrowBack />
          </button>
          <span>{subcategoryPage}</span>
          <button onClick={handleForwareSubCategoryClick} disabled={!subCategoryPagination.next}>
            <IoIosArrowForward  />
          </button>
          </div>
          )
         }
        


        </div>

        </div>
       



        <div className='rightdivforshowingproductdetails'>
        <div className='paginationclass'>
          <button onClick={handleBackClick} disabled={page == 1}>
            <IoIosArrowBack />
          </button>
          <span><b>{page}</b></span>
          <button onClick={handleForwardClick} disabled={!pagination.next}>
            <IoIosArrowForward  />
          </button>
          </div>
   

   <div className='main_right_div_raw_customer'>
        {products.length==0 && <p style={{textAlign:'center'}}>Loading...</p>}
{
  products.map((product, idx)=>{
    return (
      <div className='material_card_content_customer'>
      <div className='pro_material_img_customer'>
        <img src={product.product_image_url.split(',')[0]} // Show first image
                alt={product.product_name}/>
      </div>
      <div className='material_product_content_customer'>
        <p className='product_name_customer'>{product.product_name}</p>
        <div className="price_section_customer">
        <span className="discounted_price_customer">{`${product.product_fixed_price}`}</span>
          <span className="original_price_customer">{`${product.product_fixed_price}`}</span>
          <span className="discount_percentage_customer">7% Off</span>
        </div>
        <div className="rating_customer">
          {product?.average_rating}&nbsp; <FaStar />
        </div>
      </div>
      <button className="view_more_button_customer"  onClick={() => viewMore(product.id)}>View More
      </button>
    </div>
    )
  })
}
</div>
</div>





          
      </div>
    </div>
  );
}

export default RawMaterial;