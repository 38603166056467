import React, { useEffect, useState } from "react";
import "./Addtocart.css";
import logo from "../ctilogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../Components/Registration/Api";
import ProductDetail from "../../Web/ProductDetail/ProductDetail";
import { useAuth } from "../../AuthContext";


// Main CartPage Component
const Addtocart = () => {
  const location=useLocation()
  const [quantity, setQuantity] = useState(1);
  const admin_id = localStorage.getItem("admin_id");
  const navigate = useNavigate();
  const [showDiv, setShowDiv]=useState(false)
  const [cartItems, setCartItems] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [inputQty, setInputQty]=useState({})
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [discountCoupon, setDiscountCoupon]=useState(null)

const {setCartCountLength}=useAuth()
const {decrementCartCount}=useAuth()
  

  // Handle Product Deletion
  const deleteProduct = async(id) => {
    try {
      const link=process.env.REACT_APP_BASE_URL
      const url=`/api/user/removecartitem/${id}`
      const res=await api.delete(link+url)
      fetchCartData()
      decrementCartCount()
    } catch (error) {
      console.error(error)
    }
  };


  const discountcouponprice=discountCoupon ? discountCoupon.discounted_price : actualTotalPrice
  const priceDetailData=[
   totalProductPrice,totalDiscount, discountcouponprice
]

  const Continue = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    nextStep();
    navigate("/deliveryaddress", { state: { currentStep: 2 , priceDetailData:priceDetailData} });
  };

  const [currentStep, setCurrentStep] = useState(1); // Track the current step

  // Function to handle moving to the next step
  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };


  useEffect(() => {
      fetchCartData();
  }, []);


  const fetchCartData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `/api/user/cart/${admin_id}`;
    const res = await api.get(`${link + endpoint}`);

    if (res.status == 200) {
      setCartItems(res.data.cart_items);
      const initialQty=res.data.cart_items.reduce((acc, item)=>{
          acc[item.id]=item.quantity || 1
          return acc;
      },{})
      setInputQty(initialQty)
      setCartCountLength(res.data.cart_items.length)
    }
    } catch (error) {
      
    }
  };


  // Calculate Prices
  useEffect(() => {
    const calculatePrices = () => {
      let productPrice = 0;
      let discount = 0;
  
      cartItems.forEach((cart) => {
        const itemQuantity = inputQty[cart.id] || 1; // Use updated quantity
        let priceKey = "product_fixed_price";
  
        if (itemQuantity > 5 && itemQuantity <= 10) {
          priceKey = "q1_price";
        } else if (itemQuantity > 10 && itemQuantity <= 20) {
          priceKey = "q2_price";
        } else if (itemQuantity > 20 && itemQuantity <= 50) {
          priceKey = "q3_price";
        } else if (itemQuantity > 50 && itemQuantity <= 100) {
          priceKey = "q4_price";
        } else if (itemQuantity > 100) {
          priceKey = "q5_price";
        }
  
        const productPricePerUnit = cart?.product_details[priceKey] || 0;
        productPrice += productPricePerUnit * itemQuantity;
        discount +=
          (cart?.product_details?.product_price - productPricePerUnit) * itemQuantity;
      });
  
      setTotalProductPrice(productPrice);
      setTotalDiscount(discount);
      setOrderTotal(productPrice);
    };
  
    calculatePrices();
  }, [cartItems, inputQty]);

  
  const handleQtyChange=(id, value)=>{
      const val=Math.max(1, Number(value))
      setInputQty((prev)=>({...prev , [id]:val}))
      updateQty(id, val)
  }


  useEffect(() => {
    calculateTotalPrice();
  }, [cartItems]);

  // Function to calculate the discounted price
  const calculateDiscountedPrice = (productPrice) => {
    let discountPercentage = 0;

    if (productPrice > 5000) {
      discountPercentage = 10;  // 10% discount for products priced above 2000
    } else if (productPrice >= 1000 && productPrice <= 2000) {
      discountPercentage = 5;   // 5% discount for products priced between 1000 and 2000
    }

    const discountAmount = (productPrice * discountPercentage) / 100;
    const discountedPrice = (productPrice - discountAmount).toFixed(2);

    return {
      discountedPrice: parseFloat(discountedPrice),
      discountAmount: parseFloat(discountAmount),
    };
  };


  // Function to calculate the total price and discount 
  const calculateTotalPrice = () => {
    let totalBeforeDiscount = 0;
    let totalAfterDiscount = 0;
    let totalDiscountAmount = 0;

    cartItems.forEach((item) => {
      const { quantity, product_price, product_details } = item;
      let pricePerUnit = parseFloat(product_price);

      // Check if quantity is <= 5 (use product_fixed_price)
      if (quantity <= 5) {
        pricePerUnit = parseFloat(product_details?.product_fixed_price || 0);
      } else {
        // For quantity > 5, use tiered pricing (e.g., q1_price)
        pricePerUnit = parseFloat(product_details?.q1_price || 0);
      }

      // Calculate total price before and after discount
      totalBeforeDiscount += pricePerUnit * quantity;
      const { discountedPrice, discountAmount } = calculateDiscountedPrice(pricePerUnit * quantity);
      totalAfterDiscount += discountedPrice;
      totalDiscountAmount += discountAmount;
    });

    // Set the state for total product price, total discount, and actual total price
    setTotalProductPrice(totalBeforeDiscount.toFixed(2));
    setTotalDiscount(totalDiscountAmount.toFixed(2));
    setActualTotalPrice((totalBeforeDiscount - totalDiscountAmount).toFixed(2));
  };

  const updateQty = async (id, newQuantity) => {
    console.log("id, newqty", id,newQuantity)
    const link = process.env.REACT_APP_BASE_URL;
    const url = `${link}/api/user/updatecartitem`;
  
    // Find the item in cartItems
    const cartItem = cartItems.find((item) => item.id === id);
    if (!cartItem) return;
  
    let updatedProductPrice;
  
    if (newQuantity <= 5) {
      // Use fixed price when quantity <= 5
      const productFixedPrice = parseFloat(cartItem?.product_details?.product_fixed_price || 0);
      updatedProductPrice = (productFixedPrice * newQuantity).toFixed(2);
    } else {
      // Determine price tier for quantity > 5
      let priceKey;
      if (newQuantity > 5 && newQuantity <= 10) {
        priceKey = "q1_price";
      } else if (newQuantity > 10 && newQuantity <= 50) {
        priceKey = "q2_price";
      } else if (newQuantity > 50 && newQuantity <= 100) {
        priceKey = "q3_price";
      } else if (newQuantity > 100 && newQuantity<=500) {
        priceKey = "q4_price";
      }
      else if(newQuantity>500){
        priceKey="q5_price"
      }
  
      // Calculate price using tiered price
      const tieredPricePerUnit = parseFloat(cartItem.product_details[priceKey] || 0);
      updatedProductPrice = (tieredPricePerUnit * newQuantity).toFixed(2);
    }
  
   // If quantity is unchanged, keep the original price
  if (newQuantity == cartItem.quantity) {
    updatedProductPrice = cartItem.product_price;
  }

  // Apply discount on the updated product price
  const { discountedPrice } = calculateDiscountedPrice(parseFloat(updatedProductPrice));

    // Prepare data for API
    const updateData = {
      cart_item_id: id,
      quantity: newQuantity,
      product_price: updatedProductPrice,
    };
  
    // API call to update the quantity and price
    const response = await api.put(url, updateData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  
    if (response.status === 200) {
      console.log("Quantity updated successfully");
      // Update local cart state
      setCartItems((prev) =>
        prev.map((item) =>
          item.id === id
            ? { ...item, quantity: newQuantity, product_price: updatedProductPrice }
            : item
        )
      );
      
    }
  };
  console.log("cart items", cartItems)
  
  const increaseQuantity=(id)=>{
    handleQtyChange(id, ((inputQty[id] || 1) + 1))
  }

  const decreaseQuantity=(id)=>{
    handleQtyChange(id, ((inputQty[id] || 1) - 1))
  }


  const [code, setCode]=useState('')
  
  const handleApplyCode=async()=>{
    if(!code){
      alert('Enter Code')
      return
    }
    try {
      const formData=new FormData()
      formData.append('code', code)
      formData.append('total_amount',parseInt(actualTotalPrice, 10))
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/user/apply/coupon'
      const url=link+endpoint
      const res=await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if(res.status==200){
        setDiscountCoupon(res.data)
      }      
    } catch (error) {
      console.error(error)
      alert(error?.response?.data?.detail)
    }
  }



  return (
    <div className="cart-page_customer">
      {/* Step Navigation */}
      <div>
        <div className="step-navigation_customer">
          <div className="step-item_customer">
            <div
              className={`step_customer ${
                currentStep >= 1 ? "completed_customer" : ""
              }`}
            >
              1
            </div>
            <p className="step-label_customer">Cart</p>
          </div>
          <div
            className={`line_customer ${
              currentStep >= 2 ? "completed_customer" : ""
            }`}
          ></div>
          <div className="step-item_customer">
            <div
              className={`step_customer ${
                currentStep >= 2 ? "completed_customer" : ""
              }`}
            >
              2
            </div>
            <p className="step-label_customer">Address</p>
          </div>
          <div
            className={`line_customer ${
              currentStep >= 3 ? "completed_customer" : ""
            }`}
          ></div>
          <div className="step-item_customer">
            <div
              className={`step_customer ${
                currentStep >= 3 ? "completed_customer" : ""
              }`}
            >
              3
            </div>
            <p className="step-label_customer">Payment</p>
          </div>
          <div
            className={`line_customer ${
              currentStep >= 4 ? "completed_customer" : ""
            }`}
          ></div>
          <div className="step-item_customer">
            <div
              className={`step_customer ${
                currentStep === 4 ? "completed_customer" : ""
              }`}
            >
              4
            </div>
            <p className="step-label_customer">Summary</p>
          </div>
        </div>
      </div>
      <div onClick={()=>setShowDiv(!showDiv)} style={{cursor:'pointer'}}>&#9660; <button>Show Discount Prices</button> {/* Down arrow symbol */}</div>



              <br/>
             
      <div className="product_head_customer">
            <h4>Product Details</h4>
          </div>



      {/* Cart Content */}
      <div className="product_add_main_div_customer">
        {/* Left side - Product Details */}

        <div className="product_left_side_customer">
          

          {cartItems.length>0 ?
            cartItems.map((cart, idx) => {

              return (
                <div className="cart-content_customer" key={cart.id}>
                  
                  {/* Product Details */}
                  <div className="product-details_customer">
                    <div className="product-left_customer">
                      <div className="product-image_customer">
                        <img
                          src={cart?.product_details?.product_image_url.split(",")[0]}
                          alt="Product Image"
                        />
                      </div>
                      <div className="product-info_customer">
                        <h3>{cart?.product_details?.product_name} {cart?.product_details?.details}</h3>
                        <p>{cart?.product_details?.product_description}</p>
                        <p style={{fontWeight:'bold', fontSize:'15px'}}>&#8377;{cart?.product_price}</p>
                      </div>
                    </div>

                    <div className="product-actions_customer">
                      <div>
                        {" "}
                        <span
                          className="delete_customer"
                          onClick={()=>deleteProduct(cart.id)}
                        >
                          X
                        </span>
                      </div>

                      <div className="quantity-counter_customer">
                        <button className="minus_customer" disabled={cart.quantity<=1}  onClick={()=>decreaseQuantity(cart.id)}>-</button>
                        <input
                          type="number"
                          style={{width:'5rem', textAlign:'center'}}
                          className="quantity-input_customer"
                          min="1"
                          value={inputQty[cart.id] || 1}
                          onChange={(e)=>handleQtyChange(cart.id, e.target.value)}
                        />
                        <button className="plus_customer" onClick={()=>increaseQuantity(cart.id)}>+</button>
                      </div>
                    </div>
                  </div>
                  
                  {
                    showDiv && <div className="product-left-discount_customer">
                    <p>(5+ units:&nbsp;<span>{cart?.product_details?.q1_price}</span>)</p>
                    <p>(10+ units:&nbsp;<span>{cart?.product_details?.q2_price}</span>)</p>
                    <p>(50+ units:&nbsp;<span>{cart?.product_details?.q3_price}</span>)</p>
                    <p>(100+ units:&nbsp;<span>{cart?.product_details?.q4_price}</span>)</p>
                    <p>(500+ units:&nbsp;<span>{cart?.product_details?.q5_price}</span>)</p>
                                        </div>
                  }
                </div>
              );
            }) : <p>No Items Found</p>}
        </div>


        {/* Right side - Price Details */}
       {
        cartItems.length>0 ? 
        <div className="product_right_side_customer">
        <div className="price_detail_container_customer">
          <div className="pice_detail_customer">
            <h4>Price Detail</h4>
            <div>
              <div className="total_price_customer">
                <p>Total product price</p>
                <p>&#8377;{totalProductPrice}</p>
              </div>
              <div className="total_discount_customer">
                <p>Total Discount</p>
                <p>&#8377;{totalDiscount}</p>
              </div>
            </div>
          </div>

          <hr />
          <div className="order-total_customer">
            <h4>Order Total</h4>
            {/* <p>&#8377;{actualTotalPrice}</p> */}
            <p>&#8377;{discountCoupon ? discountCoupon.discounted_price : actualTotalPrice}</p>
          </div>
          {priceDetailData?.[1] > 0 && (
<div className="total_discount_msg_customer">
  <p>🎉 Yah, your total discount is {priceDetailData?.[1]} rs
    {discountCoupon ? ` and got extra ${discountCoupon.discount_value}%`:''}
  </p>
</div>
)}

{
  discountCoupon?<div className="total_discount_msg_customer">
  <p>🎉{discountCoupon ? ` You got extra ${discountCoupon.discount_value}% discount`:''}
    
  </p>
</div>:''
}


<div className="userdiscountentercouponcode">
  <p><span>Enter Coupon Code:</span><input value={code} onChange={(e)=>setCode(e.target.value)}/></p>
<button className="applycodebtn" onClick={handleApplyCode}>Apply Code</button>
</div>
          <div className="continue_customer">
            <p>Click on continue will not deduct any money</p>
            <button              
            onClick={()=>{
              if(cartItems.length>0){
                Continue()
              }
              else{
                alert('First Add Items on Cart')
              }
            }}
            >Continue
            </button>
          </div>
        </div>
      </div>:<div></div>
       }
        
      </div>
    </div>
  );
};

export default Addtocart;