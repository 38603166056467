import React, { useState } from "react";
import "./ForgotPassword.css";
import api from "../../Components/Registration/Api";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [selection, setSelection] = useState("email");
  const handleSelection = (type) => {
    setSelection(type);
  };

  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate=useNavigate();


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 5; // Add more rules as needed
  };

  const getOtp = async () => {
    if(selection=='email' && !email){
        alert('Enter email')
        return
    }
    if(selection=='mobile' && !mobile){
        alert('Enter Mobile Number')
        return
    }

    try {
      const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/user/userotp`;
      const url =
        selection == "email"
          ? `${baseUrl}?email=${email}`
          : `${baseUrl}?mobile_no=${mobile}`;

      const res = await api.get(url);
      if (res.status == 200) {
      }
    } catch (error) {
        if(error.response){
            alert(error?.response?.data?.error)
        }
    }
  };

  const handleUpdatePassword = async () => {
    if (!otp) {
      alert("Enter OTP");
      return;
    }

    if (!password || !confirmPass) {
      alert("Enter Passwords");
      return;
    }

    if (password !== confirmPass) {
      alert("Password must be same");
      return;
    }

    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/user/forgot/password";

      const payload =
        selection == "email"
          ? {
              email: email,
              password: password,
              confirm_password: confirmPass,
              otp: otp,
            }
          : {
              mobile_no: mobile,
              password: password,
              confirm_password: confirmPass,
              otp: otp,
            };

      const res = await api.patch(`${link}${endpoint}`, payload);
      if (res.status == 201) {
        alert("Password Update Successfully");
        setEmail('')
        setMobile('')
        setPassword('')
        setConfirmPass('')
        setOtp('')
        navigate('/')
      }
    } catch (error) {
      if(error.response){
        alert(error.response?.data?.Error)
      }
    }
  };

  return (
    <div className="forgotpassword__customer">
      <div className="forgotpassword_customer_main">
        <div className="forgotpassword_details_customer">
          <div className="forgotpasswordheading">
            <h5
              className={selection == "email" ? "emailactive" : ""}
              onClick={() => handleSelection("email")}
            >
              Email
            </h5>
            <h5
              className={selection == "mobile" ? "emailactive" : ""}
              onClick={() => handleSelection("mobile")}
            >
              Mobile
            </h5>
          </div>
          <div className="forgotpasswordemail_customer">
            {selection == "email" ? (
              <input
                placeholder="Enter email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            ) : (
              <input
                placeholder="Enter Mobile"
                type="number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            )}
          </div>

          <div className="forgotpasswordotp_customer">
            <input
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button className="forgotpassword_otpbtn" onClick={getOtp}>
              Get OTP
            </button>
          </div>

          <div className="forgotpassword_customer">
            <div className="forgotconfirmpassword_customer">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={!otp}
              />
             

            </div >

            <div className="forgotconfirmpassword_customer">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              disabled={!otp}
            />
            <button
                type="button"
                className="toggle-forgotpassword-btn"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? "🙈" : "👁️"}
              </button>
              </div>
          </div>

          <div className="forgotpassword_update_btn">
            <button
              className="forgotpassword_otpbtn"
              onClick={handleUpdatePassword}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;


