import React from 'react'
import { IoBagRemoveOutline } from "react-icons/io5";
import { TfiCup } from "react-icons/tfi";
import { RxLapTimer } from "react-icons/rx";
import { GrUserManager } from "react-icons/gr";
import { TfiFaceSmile } from "react-icons/tfi";
import { FcBusinessman } from "react-icons/fc";
import './CounterArea.css'

function CounterArea() {
  return (
    <div>
      <div class="dreamit-counter-area">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-6">
					<div class="dreamit-single-counter">
						<div class="counter-content d-flex">
							<div class="dreamit-counter-icon">
								<i class="flaticon-suitcase"><IoBagRemoveOutline /></i>
							</div>
							<div class="counter-text">
								<h1><span class="counter">150</span><span>+</span></h1>
								<div class="counter-title">
									<h4>Project Done</h4>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="col-lg-3 col-md-6">
					<div class="dreamit-single-counter">
						<div class="counter-content d-flex">
							<div class="dreamit-counter-icon">
								{/* <i class="flaticon-web-1"><GrUserManager /></i> */}
                                <i class="flaticon-trophy"><TfiFaceSmile /></i>
							</div>
							<div class="counter-text">
								<h1> <span class="counter">500</span><span>+</span></h1>
								<div class="counter-title">
									<h4>Happy Clients</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="dreamit-single-counter">
						<div class="counter-content d-flex">
							<div class="dreamit-counter-icon">
								<i class="flaticon-trophy"><TfiCup /></i>
							</div>
							<div class="counter-text">
								<h1><span class="counter">10</span><span>+</span></h1>
								<div class="counter-title">
									<h4>Award Winner</h4>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="col-lg-3 col-md-6">
					<div class="dreamit-single-counter">
						<div class="counter-content d-flex">
							<div class="dreamit-counter-icon">
								<i class="flaticon-stopwatch"><RxLapTimer /></i>
							</div>
							<div class="counter-text">
								<h1><span class="counter">12</span><span>+</span></h1>
								<div class="counter-title">
									<h4>Running Project</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </div>
  )
}

export default CounterArea
