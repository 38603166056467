import MainHome from "./mainHome";
import Home from "./Components/Home/Home";
import Home2 from "./Components/Home2/Home2";
import Automotive from "./Components/Automotive/Automotive";
import IotSol from "./Components/IotSol/IotSol";
import ServiceSection from "./Components/ServiceSection/ServiceSection";
import { TeamSection } from "./Components/TeamSection/TeamSection";
import Testimonials from "./Components/Testimonials/Testimonials";
import Consulting from "./Components/Consulting/Consulting";
import About from "./Components/About/About";
import Product from "./Components/Products/Products";
import Clients from "./Components/Clients/Clients";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Contact from "./Components/Contact/Contact";
import InContact from "./Components/InContact/InContact";
import Certificates from "./Components/Certificates/Certificates";
import NewProducts from "./Components/NewProducts/NewProducts";
import { HashRouter as Router, Routes, Route, Link, useNavigate, HashRouter, BrowserRouter } from "react-router-dom";
import ProductDetails from "./Components/Products/ProductDetails";
import Auto_Details from "./Components/Automotive/Auto_Details";
import IotSol_Details from "./Components/IotSol/IotSol_Details";
import Login from "./Components/Registration/Login/Login";
import ConsultingServices from "./Components/ConsultingServices/ConsultingServices";
import ElectronicProducts from "./Components/ElectronicProducts/ElectronicProducts";
// import InContact from "./Components/InContact/InContact";
import Products from "./Components/Products/Products";
import ContFoot from "./Components/ContFoot/ContFoot";
// import NewProducts from "./Components/NewProducts/NewProducts";
import Prod_Foot from "./Components/NewProducts/Prod_Foot";
import Presentation from "./Components/Navbar/Presentation";
import Career from "./Components/Career/Career";
import CertificateSection from "./Components/CertificateSection/CertificateSection";
import Ratings from "./Components/Ratings/Ratings";
import Register from "./Components/Registration/Register/Register";
import { AuthProvider } from "./AuthContext";
import EcomHome from "./Web/EcomHome/EcomHome";
import ProductDetail from "./Web/ProductDetail/ProductDetail";
import EcomRegistration from "./Ecom/EcomRegistration/EcomRegister/EcomRegister";
import Layout from "./Ecom/Layout/Layout";
import DashBoard from "./Ecom/DashBoard/DashBoard";
import EcomAllEmployees from "./Ecom/EcomAllUsers/EcomAllEmployees";
import EcomAllProducts from "./Ecom/EcomAllProducts/EcomAllProducts";
import EcomEmployeeDetail from "./Ecom/EcomAllUsers/EcomEmployeeDetailed/EcomEmployeeDetail";
import EcomAllServices from "./Ecom/EcomAllServices/EcomAllServices";
import EcomEndUsers from "./Ecom/EcomEndUsers/EcomEndUsers";
import EndUserDetail from "./Ecom/EcomEndUsers/EndUserDetail";
import EcomProductDetail from "./Ecom/EcomAllProducts/EcomProductDetail";
import { useLocation } from "react-router-dom";
import DashBoardHead from "./Ecom/DashBoard/DashBoardHead";
import EcomAllCategory from "./Ecom/EcomAllProducts/EcomAllCategory/EcomAllCategory";
import EcomAllSubCategory from "./Ecom/EcomAllProducts/EcomAllSubCategory/EcomAllSubCategory";
import EcomAllSubCategoryDetail from "./Ecom/EcomAllProducts/EcomAllSubCategory/EcomAllSubCategoryDetail";
import EcomAddAllSubCategory from "./Ecom/EcomAllProducts/EcomAllSubCategory/EcomAddAllSubCategory";
import EcomAddAllProduct from "./Ecom/EcomAllProducts/EcomAddAllProduct";
import EcomAllCategoryDetail from "./Ecom/EcomAllProducts/EcomAllCategory/EcomAllCategoryDetail";
import EcomAddAllCategory from "./Ecom/EcomAllProducts/EcomAllCategory/EcomAddAllCategory";
import DashboardCustomer from "./Subscriber/DashboardCustomer";
import NavbarCustomer from "./Subscriber/NavbarCustomer";
import ProductDetailCustomer from './Subscriber/ProductDetailCustomer'
import Addtocart from './Subscriber/Addtocart/Addtocart'
import Address from './Subscriber/Address/Address'
import Payment from './Subscriber/Payment/Payment'
import RawMaterial from './Subscriber/RawMaterial/RawMaterial'
import UserProfileEdit from './Subscriber/UserPofileEdit/UserProfileEdit'
import ProfileSidebar from "./Subscriber/ProfilesideBar/ProfileSidebar";
import Manage_Address from './Subscriber/Manage_Address/Manage_address'
import MyOrder from "./Subscriber/OrderPageCustomer/MyOrder";
import OrderDeliver from "./Subscriber/OrderPageCustomer/OrderDeliver";
import BuyProduct from "./Subscriber/OrderPageCustomer/BuyProduct";
import ProtectedRoute from "./ProtectedRoute";
import Summary from "./Subscriber/Summary/Summary";
import EcomOrderHistory from "./Ecom/EcomOrderHistory/EcomOrderHistory";
import BuySingleItem from "./Subscriber/BuySingleItem/BuySingleItem";
import EcomOrderHistoryDetail from "./Ecom/EcomOrderHistory/EcomOrderHistoryDetail";
import ForgotPassword from "./Subscriber/ForgotPassword/ForgotPassword";
import ExactRawMaterial from "./Subscriber/RawMaterial/ExactRawMaterial";
import ExactRawDetail from "./Subscriber/RawMaterial/ExactRawDetail";
import CreateCoupon from "./Ecom/DashBoard/CreateCoupon";
import { useEffect, useState } from "react";
import { Switch } from "react-router-dom";



// function myApp() {
//   return (
//     <>
//       <Home />
//       <Home2 />
//       <Automotive />
//       <IotSol />
//       <ServiceSection />
//       <Consulting />
//       <About />
//       <TeamSection />
//       <Testimonials />
//       <Certificates/>
//       {/* <Product /> */}

//       <NewProducts/>
//       <Contact/>
//       <Clients/>
//       <InContact/>
//       <Footer/>

//     </>
//   );
// }

const AppRoutes = () => {
  const location = useLocation();
  const hideNavbarRoutes = [
    "/register",
    "/admin",
    "/admin/",
    "/admin/allEmployees",
    "/admin/allProducts",
    "/admin/viewProductDetail/:userId",
    "/admin/allEndUsers",
    "/admin/endUserDetail",
    "/admin/viewEmpDetail",
    "/admin/allServices",
  ];

  const showNavbarRoutes = [
    "/",
    "/product",
    "/products",
    "/autoDetails",
    "/iotDetails",
    "/consulting",
    "/electronic",
    "/contact",
    "/present",
    "/career",
    "/register",
    "/certificates",
  ];

  const userNavbar=['/ecom','/userProductDetail/:productId','/userProductRawDetail/:rawId','/useraddtocart','/deliveryaddress','/productRawMaterial','/productexactraw','/userprofile',
    '/manageAddress','/myorderuser','/Ordercanceldelivery','/paymentMethod','/summary','/Orderdelivery/:orderid','/buySingleItem/:buySingleId'
  ]

  // const isAdminRoutes = location.pathname.startsWith('/admin');
  // const isAdminRoute = window.location.hash.startsWith('#/admin');
  // const dynamicRoutesRegex = /^\/admin\/viewProductDetail\/[a-zA-Z0-9]+$/;
  // const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname) || dynamicRoutesRegex.test(location.pathname);
  const dynamicRoutesRegex = /^\/admin\/viewProductDetail\/[a-zA-Z0-9]+$/;
  // Check if the current path should display the navbar
  const shouldShowNavbar = showNavbarRoutes.includes(location.pathname);
  // const showUserNavbar=userNavbar.includes(location.pathname)
  const dynamicPathRegex = /^\/userProductDetail\/[a-zA-Z0-9]+$/ ;
  const dynamicRawRegex= /^\/userProductRawDetail\/[a-zA-Z0-9]+$/ ;
  const dynamicBuyRegex=/^\/buySingleItem\/[a-zA-Z0-9]+$/;
  const dynamicOrderRegex=/^\/Orderdelivery\/[a-zA-Z0-9]+$/;

  const showUserNavbar =
  userNavbar.some((path) => path == location.pathname) || dynamicPathRegex.test(location.pathname) || dynamicRawRegex.test(location.pathname)|| dynamicBuyRegex.test(location.pathname) || dynamicOrderRegex.test(location.pathname);

  const showUserFooter=userNavbar.some((path) => path == location.pathname) || dynamicPathRegex.test(location.pathname)||dynamicRawRegex.test(location.pathname) ;

  const navigate = useNavigate();
  const [isRedirected, setIsRedirected] = useState(false);

  // useEffect(() => {
  
  //   const accessToken = localStorage.getItem("accessToken");
  //   const role = localStorage.getItem("role"); // Add role to localStorage when logging in

  //   if (accessToken) {
  //     if (role == "Admin") {
  //       navigate("/admin"); // Redirect to admin page
  //     } else if (role == "Customer") {
  //       navigate("/ecom"); // Redirect to customer page
  //     }
  //   } else {
  //     navigate("/register"); // Redirect to login page if no token
  //   }

  // }, []);


  return (
    <>
      {/* Conditionally render Navbar based on route */}
      {shouldShowNavbar && <Navbar />}
      {/* {!shouldHideNavbar && (isAdminRoute||isAdminRoutes ? <DashBoardHead /> : <Navbar />)} */}

      {showUserNavbar && <NavbarCustomer/>}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<MainHome />} />
        <Route path="/product" element={<ProductDetails />} />
        <Route path="/products" element={<Prod_Foot />} />
        <Route path="/autoDetails" element={<Auto_Details />} />
        <Route path="/iotDetails" element={<IotSol_Details />} />
        <Route path="/consulting" element={<ConsultingServices />} />
        <Route path="/electronic" element={<ElectronicProducts />} />
        <Route path="/contact" element={<ContFoot />} />
        <Route path="/present" element={<Presentation />} />
        <Route path="/career" element={<Career />} />
        <Route path="/register" element={<EcomRegistration />} />
        <Route path="/certificates" element={<CertificateSection />} />
        <Route path='/forgotpassword' element={<ForgotPassword/>}/>
        

        {/* Protected/Admin Routes under Layout */}
        <Route path="/admin" element={<Layout />}>
          <Route index element={<DashBoard />} />
          <Route path='coupon' element={<CreateCoupon/>}/>
          <Route path="allEmployees" element={<EcomAllEmployees />} />
          <Route path="allProducts" element={<EcomAllProducts />} />
          <Route path="addproduct" element={<EcomAddAllProduct />} />
          <Route path="allcategory" element={<EcomAllCategory />} />
          <Route
            path="categoryDetail/:categoryId"
            element={<EcomAllCategoryDetail />}
          />
          <Route path="allsubcategory" element={<EcomAllSubCategory />} />
          <Route
            path="subcategoryDetail/:subcategoryId"
            element={<EcomAllSubCategoryDetail />}
          />
          <Route path="addsubcategory" element={<EcomAddAllSubCategory />} />
          <Route path="addcategory" element={<EcomAddAllCategory />} />
          <Route
            path="viewProductDetail/:userId"
            element={<EcomProductDetail />}
          />
        
          <Route path="allEndUsers" element={<EcomEndUsers />} />
          <Route path="endUserDetail" element={<EndUserDetail />} />
          <Route path="viewEmpDetail" element={<EcomEmployeeDetail />} />
          <Route path="allServices" element={<EcomAllServices />} />
          <Route path='orderHistory' element={<EcomOrderHistory/>}/>
          <Route path='customerhistoryDetail/:orderHistoryId' element={<EcomOrderHistoryDetail/>}/>
        </Route>


        <Route path='/ecom' element={<DashboardCustomer/>}/>
        <Route path='/userProductDetail/:productId' element={<ProductDetailCustomer/>} /> 
        <Route path='/userProductRawDetail/:rawId' element={<ExactRawDetail/>}/> 
        <Route path='/useraddtocart' element={<ProtectedRoute><Addtocart/></ProtectedRoute>}/>    
        <Route path='/deliveryaddress' element={<ProtectedRoute><Address/></ProtectedRoute>}/>
        <Route path='/paymentMethod' element={<ProtectedRoute><Payment/></ProtectedRoute>}/>
        <Route path='/summary' element={<ProtectedRoute><Summary/></ProtectedRoute>}/>
        <Route path='/productRawMaterial' element={<RawMaterial/>}/> 
        <Route path='/productexactraw' element={<ExactRawMaterial/>}/>       
        <Route path="/userprofile" element={<ProtectedRoute><UserProfileEdit/></ProtectedRoute>}/>
        <Route path='/manageAddress' element={<ProtectedRoute><Manage_Address/></ProtectedRoute>}/>
        <Route path='/myorderuser' element={<ProtectedRoute><MyOrder/></ProtectedRoute>}/>
        <Route path='/Orderdelivery/:orderid' element={<ProtectedRoute><OrderDeliver/></ProtectedRoute>}/>
        <Route path='/buySingleItem/:buySingleId' element={<ProtectedRoute><BuySingleItem/></ProtectedRoute>}/>
        <Route path='/buyProduct' element={<BuyProduct/>}/>

      </Routes>
{showUserFooter && <Footer/>}
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;


