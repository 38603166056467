import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../../Components/Registration/Api';
import DashBoardHead from '../../DashBoard/DashBoardHead';
import { Link } from 'react-router-dom';

function EcomAllCategoryDetail() {

    const location = useLocation();
    const {categoryId}=useParams();
  
    const navigate=useNavigate()
  
    const users = location.state?.user;
    const admin_id=localStorage.getItem('admin_id')
  
  
    const [product, setProduct] = useState(null);
    const [name, setName] = useState('');
    const [watt, setWatt] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState(true)
  
  
    useEffect(() => {
      // Fetch data using the userId
      const fetchProduct = async () => {
        const link=process.env.REACT_APP_BASE_URL
        const response = await api(`${link}/api/product-category-details/?product_category_id=${categoryId}`);  // Assume the API is set up
        const data = response.data;
        console.log(data)
        setProduct(data);
        setName(data.category_name || '');
      setDescription(data.category_description || '');
      setActiveStatus(data.Productcategory_active_status || true);     
      };
  
      fetchProduct();
    }, [categoryId]);
   
    
  
    const handleUpdateCategory = async () => {
      const link = process.env.REACT_APP_BASE_URL;
      const updatedDate = new Date().toISOString();

      const updatedProduct = {
        category_name: name || product?.category_name,
        category_description: description || product?.category_description,
        Productcategory_active_status: activeStatus,
        updated_date: updatedDate,
        created_by:admin_id,
        updated_by:admin_id
      };
  
      // Send the updated product data back to the API using PUT method
      const response = await api.put(`${link}/api/productCategory-update/${categoryId}`, 
        updatedProduct,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
  
      const data = response.data || response;
      console.log(data); // Log data to verify

      if (response.status === 200 && data?.id) {
        alert('Product updated successfully!');
      } else {
        alert('Failed to update product.');
      }
    };
  
  
    return (
      <div>
        <div className="ecom-allusers-container">
         <DashBoardHead/>
  
          <div className='ecom-product-detail-main-section'>
              <div><Link to='/admin/allcategory'>Back</Link></div>
          <div className='ecom-product-detail-container'>
  
  
  <div className="ecom-product-detail-section">
      <div className="ecom-product-left-detail-section">
       
     <div className='ecom-subcategory-activestate'>
     <p>Active Status: </p>
     <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="true"
                    checked={activeStatus === true}
                    onChange={() => setActiveStatus(true)}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="false"
                    checked={activeStatus === false}
                    onChange={() => setActiveStatus(false)}
                  />
                  False
                </label>
     </div>
            <p>Created Date: ({product?.created_date})</p>
            <p>Last Updated: ({product?.updated_date})</p>
          <p>Name: <input type="text" placeholder="Street Light 2b" defaultValue={product?.category_name} value={name} onChange={(e) => setName(e.target.value)} /></p>        
      </div>
  </div>
  
  
  <div className='ecom-product-detail-desc-section'>
  <p>Description:</p>
  <div className='ecom-product-detail-desc-text'>
  <textarea
                placeholder="Category Description"
                defaultValue={product?.category_description}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    resize: 'none', // Optional: Remove resize handle
                  }}
              />
  </div>
  </div>
  
  
  <div className='ecom-product-detail-btns'>
      <button className='ecom-product-detail-cancel-btn' onClick={() => navigate('/admin/allcategory')}>Cancel</button>
      <button className='ecom-product-detail-save-btn' onClick={handleUpdateCategory}>Save</button>
  </div>
          </div>
          </div>
          </div>
      </div>
    )
  }

export default EcomAllCategoryDetail;