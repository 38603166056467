import React, { useEffect, useState } from "react";
import "./EcomAllProducts.css";
import { FaShoppingCart, FaSketch } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import axios from "axios";
import api from "../../Components/Registration/Api";
import DashBoardHead from "../DashBoard/DashBoardHead";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

function EcomAllProducts() {
  const [activeTab, setACtiveTab] = useState("led");
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({}); // Tracks selected checkboxes for each product
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ next: null });

  const [searchProduct, setSearchProduct] = useState("");

  const handleTabClick = (tab) => {
    setACtiveTab(tab);
  };

  const fetchProductData = async (page) => {
    const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `/api/listproductitem/?page=${page}`;
    const url = link + endpoint;

    try {
      // const res=await axios.get(`${link}/api/listproductitem/?page=1`,{
      //   headers:{
      //     Authorization:`Bearer ${token}`
      //   }
      // })
      const res = await api.get(`${url}`);

      if (res.status == 200) {
        setProductList(res.data.results);
        setPagination({ next: res.data.next });
        setLoading(false);
        console.log("product list", productList);
      } else if (res.status == 401) {
        console.warn("Session Expired , LogIn Again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAllDelete = (e) => {
    setSelectAll(e.target.checked);
    const updatedSelection = {};
    productList.forEach((product) => {
      updatedSelection[product.id] = e.target.checked;
    });
    setSelectedProducts(updatedSelection);
  };

  const handleProductDelete = (e, productId) => {
    const updatedSelection = {
      ...selectedProducts,
      [productId]: e.target.checked,
    };
    setSelectedProducts(updatedSelection);
  };

  useEffect(() => {
    if (selectAll) {
      const updatedSelection = {};
      productList.forEach((product) => {
        updatedSelection[product.id] = true;
      });
      setSelectedProducts(updatedSelection);
    } else {
      setSelectedProducts({});
    }
  }, [selectAll, productList]);

  const [selectedItems, setSelectedItems] = useState([]); // Track selected items for raw materials checkbox
  // Handle checkbox change for raw materials
  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((item) => item !== id); // Remove from selection if already selected
      } else {
        return [...prevSelectedItems, id]; // Add to selection
      }
    });
  };

  // Handle Delete API request
  const handledeleteProduct = async () => {
    if (rawMaterial == "finish") {
      // Filter out the selected product IDs
      const selectedIds = Object.keys(selectedProducts).filter(
        (id) => selectedProducts[id]
      );
      if (selectedIds.length === 0) {
        alert("Please select products to delete.");
        return;
      }

      console.log("object delet item", selectedIds);

      // Call delete API with the selected product IDs
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/product-delete";
      const url = link + endpoint;

      // const response = await api.delete(url, { ids: selectedIds });
      const response = await api.delete(url, {
        data: { ids: selectedIds.map(Number) }, // Convert ids to numbers if necessary
      });

      if (response.status === 200) {
        alert("Selected products deleted successfully.");
        // Optionally, you can refresh the product list after deletion
        fetchProductData(page);
        setSelectedProducts({});
        setSelectAll(false);
      } else {
        console.error("Error deleting products:", response.data);
        alert("Failed to delete selected products");
      }
    }

    if (rawMaterial == "raw") {
      if (selectedItems.length == 0) {
        alert("Select Items to delete");
        return;
      }

      try {
        const link = process.env.REACT_APP_BASE_URL;
        const endpoint = `/api/rawitemdelete/`;
        const url = link + endpoint;
        const res = await api.delete(url, {
          data: { ids: selectedItems },
        });

        if (res.status == 200) {
          fetchRawMaterialData();
          setSelectedItems([]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleForwardClick = () => {
    if (pagination.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Raw Material data handled
  const [rawMaterial, setRawMaterial] = useState("finish");
  const handleMaterialChange = (e) => {
    setRawMaterial(e.target.value);
  };

  const [rawMaterialList, setRawMaterialList] = useState([]);

  const fetchRawMaterialData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/listproductrawitem/";
      const res = await api.get(`${link + endpoint}`);

      if (res.status == 200) {
        setRawMaterialList(res.data.results);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Store the selected user's details

  // Function to open the modal and set the user data
  const handleViewDetails = (user) => {
    setSelectedUser(user); // Set the details of the clicked user
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedUser(null); // Clear the selected user's details
  };

  useEffect(() => {
    if (activeTab == "led" && rawMaterial == "finish") {
      fetchProductData(page);
    }
    if (activeTab == "led" && rawMaterial == "raw") {
      fetchRawMaterialData();
    }
  }, [activeTab, page, rawMaterial]);

  const handleAdminLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("admin_id");
    alert("Logged out");
    navigate("/register");
  };
  const handleSearchName = (val) => {
    setSearchProduct(val);
  };

  // Show loader while data is being fetched
  if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="ecom-allusers-container">
        <div className="ecom-dashboard-header">
          <div className="ecom-dashboard-search">
            <input
              className="ecom-dashboard-search-input"
              value={searchProduct}
              onChange={(e) => handleSearchName(e.target.value)}
              placeholder="Search Here..."
            />
            <CiSearch className="ecom-dashboard-search-icon" />
          </div>

          <div className="ecom-dashboard-user-details">
            <div>{/* <b>Hello, {name}</b> */}</div>
            {/* <div className='ecom-dashboard-icon'><FaShoppingCart/></div> */}
            <div className="ecom-dashboard-icon">
              <IoMdNotifications />
            </div>
            <div
              className="ecom-dashboard-exit-icon"
              onClick={handleAdminLogOut}
            >
              <BiExit />
            </div>
          </div>
        </div>

        <div className="ecom-endusers-headers">
          <div className="ecom-endusers-left-header">
            <div className="ecom-endusers-above-heading">
              <p
                onClick={() => handleTabClick("led")}
                className={activeTab == "led" ? "ecom-product-active" : ""}
              >
                LEDs
              </p>
              <p
                onClick={() => handleTabClick("simulator")}
                className={
                  activeTab == "simulator" ? "ecom-product-active" : ""
                }
              >
                Testing (Simulators)
              </p>
              <p
                onClick={() => handleTabClick("solar")}
                className={activeTab == "solar" ? "ecom-product-active" : ""}
              >
                Solar Turnkey
              </p>
              <p
                onClick={() => handleTabClick("electrical")}
                className={
                  activeTab == "electrical" ? "ecom-product-active" : ""
                }
              >
                Electrical Turnkey
              </p>
              <p
                onClick={() => handleTabClick("refrigerator")}
                className={
                  activeTab == "refrigerator" ? "ecom-product-active" : ""
                }
              >
                Refrigerator
              </p>
              <p
                onClick={() => handleTabClick("accessories")}
                className={
                  activeTab == "accessories" ? "ecom-product-active" : ""
                }
              >
                Accessories
              </p>
            </div>
          </div>
        </div>

        <div className="ecom-subcategory-newdiv">
          <button onClick={handleBackClick} disabled={page == 1}>
            <IoIosArrowBack />
          </button>
          <span>{page}</span>
          <button onClick={handleForwardClick} disabled={!pagination.next}>
            <IoIosArrowForward />
          </button>

          <div>
            <select value={rawMaterial} onChange={handleMaterialChange}>
              <option value="finish">Finished</option>
              <option value="raw">Raw Material</option>
            </select>
          </div>

          <button
            className="ecom-subcategory-addbtn"
            onClick={() => navigate("/admin/addproduct")}
          >
            <IoMdAdd className="ecom-subcategory-add" />
            New Product
          </button>
          <button>
            <RiDeleteBin6Line
              className="ecom-subcategory-delicon"
              onClick={handledeleteProduct}
            />
          </button>
        </div>

        <div>
          {activeTab == "led" && rawMaterial == "finish" && (
            <div>
              <div className="ecom-allusers-left-details">
                <div className="">
                  <table className="user-table">
                    <thead>
                      <tr>
                        <th>
                          {/* <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAllDelete}
                          /> */}
                        </th>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Weight</th>
                        <th>Size</th>
                        <th>Watts</th>
                        <th>Flux</th>
                        <th>Voltage</th>
                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* Render table rows based on active tab */}
                      {productList.map((user, index) => {
                        console.log(user);
                        return (
                          <tr key={index}>
                            {/* <td>{user.id}</td> */}
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedProducts[user.id] || false}
                                onChange={(e) =>
                                  handleProductDelete(e, user.id)
                                }
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{user.product_name}</td>
                            <td>{user.weight !== null ? user.weight : "NA"}</td>
                            <td>
                              {user.dimension != null ? user.dimension : "NA"}
                            </td>
                            <td>
                              {user.wattage !== null &&
                              user.wattage !== undefined
                                ? user.wattage
                                : "NA"}
                            </td>
                            <td>{user?.light_flux ?? "NA"}</td>
                            <td>{user?.voltage ?? "NA"}</td>
                            <td>{user.product_fixed_price}</td>
                            <td>
                              <Link
                                // to='/admin/viewProductDetail'
                                to={{
                                  pathname: `/admin/viewProductDetail/${user.id}`,
                                  state: { user },
                                }}
                              >
                                <button className="ecom-alluser-view-details">
                                  View Details
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          {activeTab == "led" && rawMaterial == "raw" && (
            <div>
              <div className="ecom-allusers-left-details">
                <div className="">
                  <table className="user-table">
                    <thead>
                      <tr>
                        <th>
                          {/* <input
                            type="checkbox"
                            checked={
                              selectedItems.length === rawMaterialList.length
                            }
                            onChange={() => {
                              if (
                                selectedItems.length === rawMaterialList.length
                              ) {
                                setSelectedItems([]); // Uncheck all if all are selected
                              } else {
                                setSelectedItems(
                                  rawMaterialList.map((user) => user.id)
                                ); // Select all
                              }
                            }}
                          /> */}
                        </th>
                        <th>S.No.</th>
                        <th>Details</th>
                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* Render table rows based on active tab */}
                      {rawMaterialList &&
                        rawMaterialList.map((user, index) => {
                          return (
                            <tr key={index}>
                              {/* <td>{user.id}</td> */}
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedItems.includes(user.id)}
                                  onChange={() => handleCheckboxChange(user.id)}
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td>{user.details}</td>
                              <td>{user.q1_price}</td>
                              <td>
                                <button
                                  className="ecom-alluser-view-details"
                                  onClick={() => handleViewDetails(user)}
                                >
                                  View Details
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* Modal for Viewing User Details */}
                  {isModalOpen && (
                    <Modal user={selectedUser} onClose={handleCloseModal} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Modal component to update raw material details
const Modal = ({ user, onClose }) => {
  const [rawImg, setRawImg] = useState([]); //from props
  const [newRawImg, setNewRawImg] = useState([]); //uploaded by user
  const [rawCategoryList, setRawCategoryList] = useState(null);
  const [rawSubCategoryList, setRawSubCategoryList] = useState(null);
  const [rawSubCatPage, setRawSubCatPage] = useState(1);
  const [rawSubCatPagination, setRawSubCatPagination] = useState({
    next: null,
  });

  const [formData, setFormData] = useState({
    details: "",
    product_category: "",
    product_sub_category: "",
    product_fixed_price: "",
    q1_price: "",
    q2_price: "",
    q3_price: "",
    q4_price: "",
    q5_price: "",
    product_image_url: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({ ...user, product_image_url: user.product_image_url });
      const image = user.product_image_url
        ? user.product_image_url.split(",")
        : [];
      setRawImg(image);
    }
    fetchCategoryData();
    fetchSubCategoryData();
  }, [user]);

  useEffect(()=>{
    fetchSubCategoryData()
  },[rawSubCatPage])

  const fetchCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/listproductCategory";
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        setRawCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/listproductSubCategory?page=${rawSubCatPage}`;
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        setRawSubCategoryList(data);
        setRawSubCatPagination({next:res.data.next})
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRawForwardBtn=()=>{
    if(rawSubCatPagination.next){
      setRawSubCatPage((prev)=>prev+1)
    }
  }
  
  const handleRawBackBtn=()=>{   
    if(rawSubCatPage>1){
      setRawSubCatPage(rawSubCatPage-1)
    }
  }

  if (!user) return null;
  console.log(user);

  const handleInputChangeFields = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateRawProduct = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/rawitemupdate/${user.id}`;
      const url = link + endpoint;

      const formDataObj = new FormData();
      formDataObj.append("details", formData.details);
      formDataObj.append("product_category", formData.product_category);
      formDataObj.append("product_sub_category", formData.product_sub_category);
      formDataObj.append("product_fixed_price", formData.product_fixed_price);
      formDataObj.append("q1_price", formData.q1_price);
      formDataObj.append("q2_price", formData.q2_price);
      formDataObj.append("q3_price", formData.q3_price);
      formDataObj.append("q4_price", formData.q4_price);
      formDataObj.append("q5_price", formData.q5_price);
      formDataObj.append("updated_by", user.updated_by);

      const allImages = [...rawImg, ...newRawImg];
      allImages.forEach((image, index) => {
        if (typeof image === "string") {
          formDataObj.append(`files`, image);
        } else if (image instanceof File) {
          formDataObj.append(`files`, image);
        }
      });
      const res = await api.put(url, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res, "updated data");
    } catch (error) {
      console.error(error);
    }
  };

  // 🟢 Handle new image upload
  const handleNewImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewRawImg((prevFiles) => [...prevFiles, ...files]); // 🟢 Add new images to the list
  };

  // 🟢 Handle editing an existing image
  const handleEditImage = (index, file) => {
    const newProductImages = [...rawImg];
    const reader = new FileReader();
    reader.onloadend = () => {
      newProductImages[index] = reader.result; // 🟢 Replace the image at the specified index
      setRawImg(newProductImages);
    };
    reader.readAsDataURL(file);
  };

  // 🟢 Handle deleting an existing image
  const handleDeleteImage = (index) => {
    const updatedImages = rawImg.filter((_, i) => i !== index);
    setRawImg(updatedImages);
  };

  // 🟢 Handle deleting a newly uploaded image
  const handleDeleteNewImage = (index) => {
    setNewRawImg((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="raw-modal-overlay">
      <div className="raw-modal-content">
        <button className="raw-modal-close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>Product Details</h2>
        <div className="raw-modal-container">
          <div className="raw-modal-leftsidediv">
            <div className="rawmaterialimagessscontainer">
              {rawImg.map((url, index) => (
                <div key={index} className="rawmaterialimagecontainer">
                  <img
                    src={url}
                    alt={`product-${index}`}
                    className="rawmaterialimgsizediv"
                  />
                  <div className="rawmaterialinpandbtn">
                    <input
                      type="file"
                      id={`rawfile-input-${index}`}
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleEditImage(index, e.target.files[0])
                      }
                    />
                    <div className="rawmaterialeditdltbtn">
                      <button
                        onClick={() =>
                          document
                            .getElementById(`rawfile-input-${index}`)
                            .click()
                        }
                      >
                        Edit
                      </button>

                      <button
                        onClick={() => handleDeleteImage(index)}
                        style={{ color: "red" }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* 🖼️ 2️⃣ Display newly uploaded images */}
            {newRawImg.map((file, index) => (
              <div key={index} className="rawmaterialimagecontainer">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`new-product-${index}`}
                  width="100"
                  height="100"
                />
                <button
                  onClick={() => handleDeleteNewImage(index)}
                  style={{ color: "red", background: "none" }}
                >
                  Delete
                </button>
              </div>
            ))}
            <div>
              {/* 🖼️ 3️⃣ Upload new images */}
              <input
                type="file"
                multiple
                id={`raw-add-file`}
                style={{ display: "none" }}
                onChange={handleNewImageUpload}
              />
              <strong
                onClick={() => document.getElementById("raw-add-file").click()}
                style={{ fontSize: "1rem", cursor: "pointer" }}
              >
                + Add Images
              </strong>
            </div>
          </div>

          <div>
            <div className="rawmaterial-leftsectiondiv">
              <p>
                <span>Details:</span>{" "}
                <input
                  value={formData.details}
                  name="details"
                  onChange={handleInputChangeFields}
                />
              </p>
              <p>
                <span>Price:</span>{" "}
                <input
                  value={formData.product_fixed_price}
                  name="product_fixed_price"
                  onChange={handleInputChangeFields}
                />
              </p>
              {/* <p>
              <span>Category:</span> <input value={formData.product_category} name="category" onChange={handleInputChangeFields}/>
            </p> */}
              <p>
                <span>Category:</span>
                <select
                  name="product_category"
                  value={formData.product_category}
                  onChange={handleInputChangeFields}
                >
                  {rawCategoryList?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category?.category_name} {/* Show category name */}
                    </option>
                  ))}
                </select>
              </p>

              {/* <p>
              <span>SubCategory:</span>{" "}
              <input value={formData.product_sub_category} name="subcategory" onChange={handleInputChangeFields}/>
            </p> */}
              <p>
                <span>SubCategory</span>
                <select
                  name="product_sub_category"
                  value={formData.product_sub_category}
                  onChange={handleInputChangeFields}
                >
                  {rawSubCategoryList?.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.sub_category_name}
                    </option>
                  ))}
                </select>
                <div style={{ display: "flex" }}>
                  <div style={{ cursor: "pointer" }} onClick={handleRawBackBtn} disabled={rawSubCatPage==1}>
                    <IoIosArrowBack />
                  </div>
                  <span>{rawSubCatPage}</span>
                  <div style={{ cursor: "pointer" }} onClick={handleRawForwardBtn} disabled={!rawSubCatPagination.next}>
                    <IoIosArrowForward />
                  </div>
                </div>
              </p>
            </div>

            <div className="rawmaterial-rightsectiondiv">
              <p>
                <span>Q1 Price:</span>{" "}
                <input
                  value={formData.q1_price}
                  name="q1_price"
                  onChange={handleInputChangeFields}
                />
              </p>
              <p>
                <span>Q2 Price:</span>{" "}
                <input
                  value={formData.q2_price}
                  name="q2_price"
                  onChange={handleInputChangeFields}
                />
              </p>
              <p>
                <span>Q3 Price:</span>{" "}
                <input
                  value={formData.q3_price}
                  name="q3_price"
                  onChange={handleInputChangeFields}
                />
              </p>
              <p>
                <span>Q4 Price:</span>
                <input
                  value={formData.q4_price}
                  name="q4_price"
                  onChange={handleInputChangeFields}
                />
              </p>
              <p>
                <span>Q5 Price:</span>{" "}
                <input
                  value={formData.q5_price}
                  name="q5_price"
                  onChange={handleInputChangeFields}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="raw-modal-buttons">
          <button className="raw-close-button" onClick={handleUpdateRawProduct}>
            Update
          </button>

          <button className="raw-close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EcomAllProducts;
