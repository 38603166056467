import React, { useState } from "react";
import "./EcomRegister.css";
import desk from "./desk.png";
import axios from "axios";
import InputField from "../InputField";
import { Form, useNavigate } from "react-router-dom";
import api from "../../../Components/Registration/Api";

function EcomRegister() {

  const [isLogin, setIsLogin] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [role, setRole] = useState("user");
  const navigate=useNavigate()

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    username: "",
    email: "",
    mobile_number: "",
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    password_confirm: "",
    otp: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTabClick = (tab) => {
    setIsLogin(tab === "login");
  };

  const handleOtpSubmit = () => {
    if (otp === "123456") {
      setOtpVerified(true);
    } else {
      alert("Invalid OTP");
    }
  };

  const handleSubmit = async (e) => {
    console.log("handlesubmit called");
    e.preventDefault();
    setError("");
    setSuccess("");

    // if (formData.password !== formData.password2) {
    //   setError('Passwords do not match');
    //   return;
    // }

    // Check if password length is greater than 3 characters
    // if (formData.password.length <= 3) {
    //   setError('Password must be greater than 3 characters');
    //   return;
    // }

    if(formData.mobile_number==""){
      setError("Enter Mobile Number")
      return;
    }

    if (!/^\d{10}$/.test(formData.mobile_number)) {
      setError("Mobile number must be a 10-digit number");
      return;
    }

    try {
      const link = process.env.REACT_APP_BASE_URL;
      const adminRoleValue = "2"; // Value from an input, as a string
      const adminRoleInt = parseInt(adminRoleValue, 10); // Convert to integer (2)

      const formDataObj=new FormData()
      formDataObj.append('username', formData.username)
      formDataObj.append('email',formData.email)
      formDataObj.append('fName',formData.fName)
      formDataObj.append('lName',formData.lName)
      formDataObj.append('mobile_number',formData.mobile_number)
      // formDataObj.append('admin_role', adminRoleInt)

      const response = await axios.post(`${link}/api/user/register`, formDataObj, 
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status == 200) {
        setError("");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        // Check for specific error messages from the response
        if (errorData.username && errorData.username.length > 0) {
          setError("Username already exists. Please choose a different one.");
        } else if (errorData.email && errorData.email.length > 0) {
          setError("Email already exists. Please use a different email.");
        } else {
          setError(errorData.Error || "An error occurred during registration");
        }
      } else {
        setError("Failed to register. Please try again later.");
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if(passwordData.otp==""){
      setError('Enter OTP')
      return;
    }
    if (passwordData.password !== passwordData.password_confirm) {
      console.log(passwordData.password, passwordData.password_confirm,"pass")
      setError("Passwords do not match");
      return;
    }
    if (passwordData.password.length <= 3) {
      setError("Password must be greater than 3 characters");
      return;
    }
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const formDataObj=new FormData()
      formDataObj.append('mobile_number', formData.mobile_number)
      formDataObj.append('otp', passwordData.otp)
      formDataObj.append('password', passwordData.password)
      formDataObj.append('password_confirm', passwordData.password_confirm)

      const response = await axios.post(`${link}/api/user/confirm`, formDataObj,{
        headers:{
          'Content-Type':'multipart/form-data'
        }
      }
       );

      if (response.status == 201) {
        alert("Account successfully created!");
        setError("");
        // Reset form data
        setPasswordData({
          otp: "",
          password: "",
          password_confirm: "",
        });
        setFormData({
          mobile_number: "",
          email: "",
          fName: "",
          lName: "",
          username: "",
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        // Check for specific error messages from the response
        if (errorData) {
          setError(errorData.error || "An error occurred during registration");
        }
      } else {
        setError("Failed to register. Please try again later.");
      }
    }
  };

  const handleLogin = async () => {
    if (username === "" || password === "") {
      alert("Please fill all details");
      return;
    }  

    // if(role===''){
    //   alert('Please Select Role')
    //   return
    // }
  
    const baseURL = process.env.REACT_APP_BASE_URL;
    const url = role == "admin" ? `${baseURL}/api/login` : `${baseURL}/api/user/login`;
    // const url=`${baseURL}/api/login/`

    try {

    console.log(username, password, role);

      const params = new URLSearchParams();
      
        params.append("admin_name", username);
        params.append("password", password);
      
  
      // Make the POST request with axios
      const response = await axios.post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        withCredentials: true,
         // Ensure cookies are included
      });

      // const response = await fetch(`${url}`, {
      //         method: "POST",
      //         body: params,
      //         headers: {
      //           "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      //         },
      //       },
      //       );

    
      if (response.status==200) {
        const data = response.data;
        console.log("loginData", data);
        
        // Store the access token in localStorage for future use
        localStorage.setItem('accessToken', data.access);
        localStorage.setItem('username',data.admin_name)
        localStorage.setItem('admin_id', data.admin_id)
        localStorage.setItem('role', data.role)

        const currentTimeISO = new Date().toISOString();
        console.log("login time Current Time in ISO 8601 Format:", currentTimeISO)
  
      if (data.role == "Admin") {
        setUserName('')
        setPassword('')
        setRole('')
        
        navigate("/admin");  // Replace with your admin page route
      } else if(data.role=="Customer") {
        navigate("/ecom");   // Replace with your user page route
      }
      }
      
      
      //  else {
      //   alert(`Login failed with status: ${response.status}`);
      // }
    } catch (error) {
      // This block will now only handle network errors or unexpected issues
      console.error("Error during login:", error);
  
      // Handle specific error responses
      if (error.response) {
        if (error.response.status === 400) {
          // Handle 400 error - User not found
          if (error.response.data && error.response.data.non_field_errors) {
            if (error.response.data.non_field_errors.includes("User not found.")) {
              alert('User not found. Please check your username or password.');
            } else {
              alert('Invalid username or password. Please try again.');
            }
          }
        } else {
          alert(`Error: ${error.response.status} - ${error.response.statusText}`);
        }
      } else if (error.request) {
        // This means the request was made but no response was received
        alert("No response from server. Please check your internet connection.");
      } else {
        // Other errors (unexpected issues)
        alert("An unexpected error occurred during login.");
      }
    }
  };
  
const handleForgotPassword=()=>{
  navigate('/forgotpassword')
}
  

  const handleApi = async () => {
    if (username === '' || password === '') {
      alert('Please Enter Username and Password');
      return;
    }
    try {
      // Send user input to the login API
      const response = await axios.post('/api/login', {
        username,
        password,
      });
  
      // Log the entire response to inspect it
      console.log(response.data);
  
      if (response.data && response.data.access) {
        console.log('Access Token:', response.data.access);        
  
        // Store tokens
        localStorage.setItem('accessToken', response.data.access);
  
        alert('Logged In Successfully');
     
        // navigate('/products');
      } else {
        console.error('Login failed: No access token in response');
      }
    } catch (error) {
      if (error.response) {
        console.error('Login failed:', error.response.data, error.response.status);
      } else if (error.request) {
        console.error('No response from server:', error.request);
      } else {
        console.error('Error:', error.message);
      }
    }
  };




  return (
    <div className="ecom-register-main">
      <div className="ecom-register">
        {/* Left Side */}
        <div className="ecom-reg-leftside">
          <img src={desk} alt="Desk Image" />
        </div>

        {/* Right Side - Register/Login Form */}
        <div className="ecom-reg-rightside">
          <div className="ecom-reg-rightdiv">
            {/* Tabs for Register/Login */}
            <div className="ecom-reg-tabs">
              <span
                className={isLogin ? "" : "active"}
                onClick={() => handleTabClick("register")}
              >
                Register
              </span>
              <span
                className={isLogin ? "active" : ""}
                onClick={() => handleTabClick("login")}
              >
                Login
              </span>
            </div>

       

            {/* Form */}
            <div className="ecom-reg-form">
              {!isLogin ? (
                <>
                  <div className="ecom-reg-input-group">
                    <InputField
                      type="text"
                      className="ecom-reg-ip"
                      placeholder="First Name"
                      value={formData.fName}
                      onChange={handleChange}
                      name="fName"
                      required
                    />
                    <InputField
                      type="text"
                      className="ecom-reg-ip"
                      placeholder="Last Name"
                      name="lName"
                      value={formData.lName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <InputField
                    type="text"
                    className="ecom-reg-ip"
                    placeholder="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                   <InputField
                    type="email"
                    className="ecom-reg-ip"
                    placeholder="Email Id"                    
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />

                  <InputField
                    type="tel"
                    className="ecom-reg-ip"
                    placeholder="Enter Mobile No."
                    name="mobile_number"
                    value={formData.mobile_number}
                    pattern="[0-9]{10}"
                    onChange={handleChange}
                    required
                  />

                  <div className="ecom-reg-input-group">
                    <InputField
                      className="ecom-reg-ip"
                      type="text"
                      placeholder="OTP"
                      name="otp"
                      value={passwordData.otp}
                      onChange={handlePasswordChange}
                      required
                    />
                    <button
                      type="submit"
                      className="ecom-reg-otp-btn"
                      // onClick={() => setOtpSent(true)}
                      onClick={handleSubmit}
                    >
                      Get OTP
                    </button>
                  </div>

                  {/* {otpSent && (
                    <button
                      type="button"
                      className="verify-btn"
                      onClick={handleOtpSubmit}
                    >
                      Verify OTP
                    </button>
                  )} */}
                </>
              ) : (
                <>
               <select value={role} onChange={handleRoleChange}>
                    <option value=''>Select Role</option>
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                  <InputField
                    type="email"
                    className="ecom-reg-ip"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                  <InputField
                    type="password"
                    className="ecom-reg-ip"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </>
              )}

              {/* Set Password Fields - Only shown when OTP is verified */}
              {!isLogin && (
                <div className="ecom-password-section">
                  <h5>Set Password</h5>
                  <div className="ecom-reg-password-div">
                    <InputField
                      type="password"
                      className="ecom-reg-ip"
                      placeholder="Password"
                      name='password'
                      value={passwordData.password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <InputField
                      className="ecom-reg-ip"
                      type="password"
                      placeholder="Confirm Password"
                      name="password_confirm"
                      value={passwordData.password_confirm}
                      onChange={handlePasswordChange}
                      required
                    />
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <button
                    type="submit"
                    onClick={handlePasswordSubmit}
                    className="register-btn"
                  >
                    Register
                  </button>
                </div>
              )}

              {isLogin && (
                <>
                  <p className="ecom-forgot-password" onClick={handleForgotPassword}>Forgot Password ?</p>
                  <button
                    type="submit"
                    className="ecom-login-btn"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

  

    </div>
  );
}

export default EcomRegister;
