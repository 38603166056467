import React from 'react';
import './DashboardCustomer.css';
import { Navigate, useNavigate } from "react-router-dom";
import ProductDetail from './ProductDetailCustomer';
import Card from './CardCustomer';
import homeled from './UserImages/homeled.png'
import accessories from './UserImages/electricAccessories.png'
import icecube from './UserImages/iceCube.png'
import iot from './UserImages/iot.png'
import turnkey from './UserImages/turnkey.png'


function Dashboard() {

  const navigate=useNavigate()

  const handleRawProduct=()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/productRawMaterial')
  }

  return (
   <>
    <div className="dashboard_customer">
      <div className="left-div_customer">
      <img src={homeled} alt="lights" />
      <div className="overlay_customer">
          <h3>LEDs LIGHTS</h3>
            <p>Produce light up to 90% more efficiently than incandescent light bulbs.</p>
            <button onClick={handleRawProduct}>Shop Now</button>
          </div>
      </div>
      
      <div className="right-div_customer">
        <div className="box_customer">
          <img src={accessories} alt="Accesories" />
          <div className="overlay_customer">
          <h3>Electrical Accesories</h3>
            <p>Components used in wiring installations or extra equipment in a vehicle.</p>
            <button>Shop Now</button>
          </div>
        </div>
        <div className="box_customer">
          <img src={icecube} alt="ice cube" />
          <div className="overlay_customer">
          <h3>Refrigeration Equipment</h3>
            <p>Produces ice for service in foodservice facilities, restaurants, bars and hotels or for customer self-service.</p>
            <button>Shop Now</button>
          </div>
        </div>
        <div className="box_customer">
          <img src={iot} alt="iot" />
          <div className="overlay_customer">
          <h3>IoT</h3>
            <p>Network of physical objects that can connect and share data with other devices and systems over the internet.</p>
            <button>View More</button>
          </div>
        </div>
        <div className="box_customer">
          <img src={turnkey} alt="turnkey" />
          <div className="overlay_customer">
          <h3>Turnkey</h3>
            <p>Service that manages the entire process of installing a solar and electrical power plant.</p>
            <button>View More</button>
          </div>
        </div>
      </div>
    </div>

    <Card/>
   </>
    
  );
}

export default Dashboard;
