import React, { useState } from 'react'
import './Payment.css'
import { useLocation, useNavigate } from 'react-router-dom'


function Payment() {
    const navigate=useNavigate()
    const location=useLocation()
    const {currentStep,priceDetailData,selectedAddressId }= location.state || {};
    const [paymentMethod, setPaymentMethod]=useState(null)


    const continue_pay=()=>{
      if(!paymentMethod){
        alert('Select Payment Method')
        return
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/summary', {state:{currentStep:4, priceDetailData, paymentMethod,selectedAddressId},
          replace: true, // Replaces the current route in the history stack)
        })         
    }


    const handlePaymentMethod=(mode)=>{
      setPaymentMethod(mode)
    }




  return (
    <>
    <div className='payment_pg_customer'>
   {/* Step Navigation */}
   <div className="step-navigation_customer">
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 1 ? "completed_customer" : ""
            }`}
          >
            1
          </div>
          <p className="step-label_customer">Cart</p>
        </div>
        <div className={`line_customer ${currentStep >= 2 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 2 ? "completed_customer" : ""
            }`}
          >
            2
          </div>
          <p className="step-label_customer">Address</p>
        </div>
        <div className={`line_customer ${currentStep >= 3 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 3 ? "completed_customer" : ""
            }`}
          >
            3
          </div>
          <p className="step-label_customer">Payment</p>
        </div>
        <div className={`line_customer ${currentStep >= 4 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep === 4 ? "completed_customer" : ""
            }`}
          >
            4
          </div>
          <p className="step-label_customer">Summary</p>
        </div>
      </div>


<div className='main_payment_div_customer'>


{/* left side div */}
<div className='left__payment'>

<div className='left_payment__customer'>
  <h3>Select Payment Method</h3>
  <div className='left-payment-online'>
            <input type='radio' name='paymentMethod' onChange={()=>handlePaymentMethod('cash')} value={paymentMethod=='cash'}/><span>Cash On Delivery</span>
  </div>
  <div className='left-payment-online'>
            <input type='radio' name='paymentMethod' onChange={()=>handlePaymentMethod('card')} value={paymentMethod=='card'}/> <span>NetBanking</span>
  </div>
</div>

</div>

  {/* right side */}
  <div className="product_right_side_customer">
    <div className="price_detail_container_customer">
      <div className="pice_detail_customer">
        <h4>Price Detail</h4>
        <div>
          <div className="total_price_customer">
            <p>Total product price</p>
            <p>&#8377;{priceDetailData?.[0]}</p>
          </div>
          <div className="total_discount_customer">
            <p>Total Discount</p>
            <p>&#8377;{priceDetailData?.[1]}</p>
          </div>
        </div>
      </div>

      <hr />
      <div className="order-total_customer">
        <h4>Order Total</h4>
        <p>&#8377;{priceDetailData?.[2]}</p>
      </div>
      {priceDetailData?.[1] > 0 && (
  <div className="total_discount_msg_customer">
    <p>🎉 Yah, your total discount is {priceDetailData?.[1]} rs</p>
  </div>
)}
      <div className="continue_customer">
        <p>Click on continue will not deduct any money</p>
        <button  onClick={continue_pay}>continue</button>
      </div>
    </div>
  </div>
    </div>
    </div>
    </>
  )
}

export default Payment;