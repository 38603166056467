import React, { useState } from 'react';
import './NavbarCustomer.css';
import logo from './ctilogo.png';
import { CgProfile } from "react-icons/cg";
import { IoCartOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import api from '../Components/Registration/Api';
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const [menuOpenUser, setMenuOpenUser]=useState(false)
  const username=(localStorage.getItem('username'))
  const {cartCount}=useAuth()
  const admin_id=localStorage.getItem('admin_id')

  const toggleMenu=()=>{
      setMenuOpenUser(!menuOpenUser)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [showLedDropdown, setShowLedDropdown] = useState(false);
 const navigate=useNavigate();
  const toggleLedDropdown = () => {
    setShowLedDropdown(!showLedDropdown);
  };

function User_profile(){
  toggleMenu()
  navigate('/userprofile')
}

    const handleUserLogout=()=>{
        localStorage.clear()
        alert('Logged out')
        navigate('/register')
  }

  const myorderuser=()=>{
    navigate('/myorderuser')
    toggleMenu()
  }


  const [len, setLen]=useState(null)
  const [showlen, setShowlen]=useState(false)

  const fetchCartData = async () => {
    const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `/api/user/cart/${admin_id}`;
    const res = await api.get(`${link + endpoint}`);

    if (res.status == 200) {
      setLen(res.data.cart_items.length)
      setShowlen(true)
    }
    
// cartCount=null
    // setTimeout(() => {
    //   setShowlen(false);
    // }, 1000);

  };

  


  return (
    <nav className="navbar_customer">
      <div className="navbar-logo_customer">
        <img src={logo} alt="Company Logo" className="logo_customer" />
      </div>
     <div className='menu_list_customer'>
     <div className="hamburgerUser" onClick={toggleMenu}>
        ☰
      </div>
     <ul className={`navbar-menu_customer ${menuOpenUser ? 'openUser' : ''}`}>
        <li onClick={toggleMenu}><Link to="/ecom">Home</Link></li>

        {/* Products Dropdown */}
        <li className="dropdown_customer">
          <Link>
            Products <FaChevronDown className="dropdown-icon_customer" />
          </Link>
          <ul className="dropdown-menu_customer">
            <li 
              className="dropdown_customer"
              onMouseEnter={toggleLedDropdown} 
              onMouseLeave={toggleLedDropdown}
            >
              <Link>
                LED Lights Products <FaChevronDown className="dropdown-icon_customer" />
              </Link>
              {showLedDropdown && (
                <ul className="sub-dropdown-menu_customer">
                  <li><Link to="/productRawMaterial" onClick={toggleMenu}>Finished Material</Link></li>
                  <li><Link to='/productexactraw' onClick={toggleMenu}>Raw Material</Link></li>
                  <li><Link >Smart Lighting</Link></li>
                </ul>
              )}
            </li>
            <li><HashLink to='/products#solar_smart' smooth onClick={toggleMenu}>Electrical / Solar Turnkey</HashLink></li>
            <li><Link>Refrigeration Equipment</Link></li>
            <li><HashLink  to='/products#home_automation'  onClick={toggleMenu} smooth>Automotive Products</HashLink></li>
            <li><Link>Electrical Accessories</Link></li>
            <li><HashLink to='/products#hardware'  onClick={toggleMenu} smooth>Testing Kit</HashLink></li>
          </ul>
        </li>

        {/* Services Dropdown */}
        <li className="dropdown_customer" onClick={toggleMenu}>
          <Link >
            Services <FaChevronDown className="dropdown-icon_customer" />
          </Link>
          <ul className="dropdown-menu_customer">
            <li><Link >Consulting</Link></li>
            <li><Link >Installation</Link></li>
            <li><Link >Maintenance</Link></li>
          </ul>
        </li>



{
  username ? ( 
    <li className="profile-container_customer">
<Link>
<CgProfile className="profile-cart-icon_customer" />
</Link>
<div className="profile-dropdown_customer">

<div className="profile-option_customer" >
  <CgProfile className="profile-icon_customer" />
  <div className="profile-info_customer" onClick={User_profile}>
    <p className="profile-name_customer">{username}</p>
    {/* <p className="profile-phone_customer" >+123 456 7890</p> */}
  </div>
</div>

<div className="profile-option_customer" onClick={myorderuser}>My Orders</div>
<div className="profile-option_customer">Delete Account</div>
<div className="profile-option_customer" onClick={handleUserLogout}>Log out</div>
</div>

</li>
  ):(
    <li>
    <Link to="/register" className="signin-button_customer">
      Sign In
    </Link>
  </li>
  )
}
      
       {
        username ? (
          <li onClick={toggleMenu}>    
      <Link to="/useraddtocart"><IoCartOutline className='profile-cart-icon_customer'
       onClick={fetchCartData}
       />

     {
      showlen ? (
        
          len>1 ? <span className='profile-cart-count_customer'>
          {/* {len} */}
          </span>:""
         
      ):(
        
          cartCount!=null ? <span className='profile-cart-count_customer'>
          {cartCount}
          </span>:''
         
      )
     }
        
        </Link>
       </li>
        ):(
          <div></div>
        )
       }
      </ul>
     </div>
    </nav>
  );
};

export default Navbar;