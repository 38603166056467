import React, { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './RawMaterial.css'
import api from '../../Components/Registration/Api';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import RatingAndReview from './RatingAndReview';

function ExactRawDetail() {


const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate=useNavigate()
  const API_URL = process.env.REACT_APP_BASE_URL;
  const {rawId}=useParams()
  const admin_id=localStorage.getItem('admin_id')
  const incrementCartCount=useAuth()



 useEffect(() => {
    fetch(`${API_URL}/api/rawitemdetails/${rawId}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
        setSelectedImage(data?.product_image_url?.split(',')[0]);
        setLoading(false);
      })
      .catch(() => {
        setError('Failed to fetch product details  sss');
        setLoading(false);
      });

      handleShowRating()
  }, [rawId]);
  const imageUrls = product?.product_image_url?.split(',');

  
  const handleRawAddToCart=async()=>{
  if(!admin_id){
    alert('You need to SignIn for adding product')
    return;
  }
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const link = process.env.REACT_APP_BASE_URL;
 
  const addProduct={
    customer: admin_id,
    product: null,
    quantity: 1,
    raw_materials:product.id,
    product_price:product?.q1_price 
  }

  try {
    const res = await api.post(`${link}/api/user/addtocart`, 
      addProduct,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    );

    if (res.status == 201) {
      console.log(addProduct,'add product')
      alert('Product Added To Cart')
      incrementCartCount()
      
    } 
  } catch (error) {
    if (error.response && error.response.data) {
      const errors = error.response.data;
      if (errors.non_field_errors && errors.non_field_errors.includes('The fields cart, product must make a unique set.')) {
        alert('This product is already in your cart!');
      } else {
        alert('An error occurred. Please try again.');
      }
    } 
  }
  }


  const handleBuyRawItem=async(buySingleId)=>{
    navigate(`/buySingleItem/${buySingleId}` , {state:product});
  }


  const ratingMessage = product?.average_rating > 0
  ? product?.average_rating >= 4.5
    ? "⭐⭐⭐⭐⭐" // 4.5 and above show 5 stars
    : product?.average_rating >= 4
    ? "⭐⭐⭐⭐"  // 4 to 4.4 show 4 stars
    : product?.average_rating >= 3.5
    ? "⭐⭐⭐⭐"  // 3.5 to 3.9 show 4 stars
    : product?.average_rating >= 3
    ? "⭐⭐⭐"   // 3 to 3.4 show 3 stars
    : product?.average_rating >= 2.5
    ? "⭐⭐⭐"   // 2.5 to 2.9 show 3 stars
    : product?.average_rating >= 2
    ? "⭐⭐"    // 2 to 2.4 show 2 stars
    : product?.average_rating >= 1.5
    ? "⭐⭐"    // 1.5 to 1.9 show 2 stars
    : product?.average_rating >= 1
    ? "⭐"     // 1 to 1.4 show 1 star
    : "⭐"     // Any value below 1 show 1 star
    
  : "";



  
const [rating, setRating]=useState([])
const handleShowRating=async()=>{
  try {
    const link=process.env.REACT_APP_BASE_URL
    const endpoint=`/api/one/raw/product/rating?raw_product_id=${rawId}`
    const url=link+endpoint

    const res=await axios.get(url)
    if(res.status==200){
        setRating(res.data.results)
        console.log(res.data.results)
    }

    
  } catch (error) {
    
  }
}




   // Show loader while data is being fetched
   if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading product details...</p>
      </div>
    );
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!product) return <p>No product details available</p>;




  return (
    <div>
       <div className="product-detail-container_customer">
      {/* Left Section: Images */}
      <div className="product-images-section_customer">
        <div className="small-images_customer">
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => setSelectedImage(url)}
              className="thumbnail_customer"
            />
          ))}
        </div>

        <div className="main-image_customer">
          <img src={selectedImage} alt="Selected Product" />
        </div>
      </div>

      {/* Right Section: Product Details */}
      <div className="product-details-section_customer">
        <h5>{product?.details}</h5>
      
        <p className="price_customer">&#8377;{product?.q1_price}</p>
        <p>{ratingMessage}</p>
        <p>Category: {product?.product_category|| 'N/A'}</p>
        <p>Sub-Category: {product?.product_sub_category || 'N/A'}</p>
        <p>{product?.product_description}</p>
        
        <div className="product-specifications_customer">
          <h3>Specifications:</h3>
          <ul>
            <li>Weight: {product?.product_specifications?.weight || 'N/A'}</li>
            <li>Battery Life: {product?.product_specifications?.battery_life || 'N/A'}</li>
            <li>Color: {product?.product_specifications?.color || 'N/A'}</li>
            <li>Dimensions: {product?.product_specifications?.dimensions || 'N/A'}</li>
          </ul>
        </div>

       
        <div className="product-actions_btn_customer">
          <button className="add-to-cart_customer" onClick={handleRawAddToCart}>Add to Cart</button>
          <button className="buy-now_customer" 
          onClick={()=>handleBuyRawItem(product.id)}
            >Buy Now</button>
        </div>
       
    
   


      </div>

    

    </div>

    
    <div className='product-detail-review-rating-section'>
     <div className="customer-review-rating">
                <RatingAndReview
                productId={rawId}
                isFinishedProduct={false} />
              </div>
        <div className='product-rating-section'>
        {rating.length>0 && <h4>User Ratings and Reviews</h4>}
      {rating.length > 0 ? (
  [...rating]
    .sort((a, b) => {
      // Fetch the admin ID from local storage
      const adminId = localStorage.getItem("admin_id");
      if (adminId) {
        if (a.user == adminId) return -1; // Admin's review first
        if (b.user == adminId) return 1;
      }
      return 0; // Keep the rest in their order
    })
        .map((item, index) => (
          <div key={index} className="rating-item">
            <p><strong>User ID:</strong> {item.user}</p>
            <p>
              <strong>Rating:</strong>{" "}
              {"⭐".repeat(item.score)} {/* Display stars based on score */}
            </p>
            <p>
              <strong>Review:</strong>{" "}
              {item.review ? item.review : "No review provided"}
            </p>
            <p><strong>Date:</strong> {new Date(item.created_at).toLocaleString()}</p>
            <hr />
          </div>
        ))
      ) : (
        <p></p>
      )}
       
        </div>
  </div>


    </div>
  )
}

export default ExactRawDetail;