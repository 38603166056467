import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../../Components/Registration/Api';
import DashBoardHead from '../../DashBoard/DashBoardHead';
import { Link } from 'react-router-dom';


function EcomAddAllSubCategory() {

    const location = useLocation();
    const {subcategoryId}=useParams();
    const [categoryList, setCategoryList]=useState([])
    const [selectedCategoryId, setSelectedCategoryId]=useState(null)
  
    const navigate=useNavigate()
    const admin_id=localStorage.getItem('admin_id')
  
   
    useEffect(()=>{
          fetchCategoryData()
      },[])
  
      const fetchCategoryData=async()=>{
         try {
          const link=process.env.REACT_APP_BASE_URL
          const endpoint='/api/listproductCategory'
          const url=link+endpoint
  
          const res=await api.get(`${url}`)
          if(res.status==200){
              const data=res.data.results
              console.log(data)
              setCategoryList(data)
          }
         } catch (error) {
          console.error(error)
         }
      }

      const handleCategoryChange = (e) => {
        setSelectedCategoryId(e.target.value); // Set selected category ID
      };
    


    const [name, setName] = useState('');
    const [watt, setWatt] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState(true)

    
  
    const handleAddSubCategory = async () => {

        if (!selectedCategoryId) {
            alert('Please select a category');
            return;
          }

      const link = process.env.REACT_APP_BASE_URL;
      const updatedDate = new Date().toISOString();

      const updatedProduct = {
    sub_category_name: name,
    sub_category_description: description,
    sub_category_active_status: activeStatus,
    created_date: updatedDate,
    updated_date: updatedDate,
    created_by: admin_id,
    updated_by: admin_id,
    product_category:selectedCategoryId,
      };
  
     
      const response = await api.post(`${link}/api/createproductsubcategory`, 
        updatedProduct,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
  
      const data = response.data || response;
      console.log(data); 

      if (response.status==201 ) {
        alert('Added Product Sub-Category successfully!');
      } else {
        alert('Failed to add subcategory.');
      }
    };
  
  
    return (
      <div>
        <div className="ecom-allusers-container">
         <DashBoardHead/>
  
          <div className='ecom-product-detail-main-section'>
              <div><Link to='/admin/allsubcategory'>Back</Link></div>
          <div className='ecom-product-detail-container'>
  
  
  <div className="ecom-product-detail-section">
      <div className="ecom-product-left-detail-section">
       
     <div className='ecom-subcategory-activestate'>
     <p>Active Status: </p>
     <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="true"
                    checked={activeStatus === true}
                    onChange={() => setActiveStatus(true)}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="false"
                    checked={activeStatus === false}
                    onChange={() => setActiveStatus(false)}
                  />
                  False
                </label>
     </div>
          <p>Name: <input type="text" placeholder="Street Light 2b" value={name} onChange={(e) => setName(e.target.value)} /></p>  
          <p>Product Category: <select onChange={handleCategoryChange} value={selectedCategoryId || ''}>
          <option value="" disabled>Select a category</option>
          {categoryList.map((category) => (
            <option key={category.id} value={category.id}>
              {category.category_name}
            </option>
          ))}
        </select></p>      
      </div>
  </div>
  
  
  <div className='ecom-product-detail-desc-section'>
  <p>Description:</p>
  <div className='ecom-product-detail-desc-text'>
  <textarea
                placeholder="Sub-category Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    resize: 'none', // Optional: Remove resize handle
                  }}
              />
  </div>
  </div>
  
  
  <div className='ecom-product-detail-btns'>
      <button className='ecom-product-detail-cancel-btn' onClick={() => navigate('/admin/allsubcategory')}>Cancel</button>
      <button className='ecom-product-detail-save-btn' onClick={handleAddSubCategory}>Save</button>
  </div>
          </div>
          </div>
          </div>
      </div>
    )
  }

export default EcomAddAllSubCategory
