import React, { useEffect, useState } from 'react'
import DashBoardHead from '../../DashBoard/DashBoardHead'
import api from '../../../Components/Registration/Api'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


function EcomAllSubCategory() {

    const [activeTab, setActiveTab]=useState('led')
    const [subCategoryList, setSubCategoryList]=useState([])
    const [page, setPage]=useState(1)
    const navigate=useNavigate()
    const [pagination, setPagination]=useState({next:null})

    const [loading, setLoading]=useState(true)
  
    const handleTabClick=(tab)=>{
      setActiveTab(tab)
    }
  
    useEffect(()=>{
      if(activeTab=='led'){
        fetchSubCategoryData()
      }
    },[activeTab, page])
    
    const fetchSubCategoryData=async()=>{
       try {
        const link=process.env.REACT_APP_BASE_URL
        const endpoint=`/api/listproductSubCategory?page=${page}`
        const url=link+endpoint
        const res=await api.get(`${url}`)
        if(res.status==200){
            const data=res.data.results
            console.log(data)
            setSubCategoryList(data)
            setPagination({next:res.data.next})
            setLoading(false)
        }
       } catch (error) {
        console.error(error)
       }
    }


    const [subcategories, setSubcategories] = useState([]); // Store all subcategories
    const [selectedItems, setSelectedItems] = useState([]); // Store selected subcategories for deletion
    const [selectAll, setSelectAll] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState({}); 
    const [productId, setProductId]=useState(null)


     // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((itemId) => itemId !== id) // Deselect
        : [...prevSelectedItems, id] // Select
    );
  };

  // Handle global checkbox (Select/Deselect All)
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]); // Deselect all
    } else {
      setSelectedItems(subcategories.map((subcategory) => subcategory.id)); // Select all
    }
    setSelectAll(!selectAll); // Toggle global checkbox
  };

  // Delete selected items
  const handleDelete = async () => {
    const link = process.env.REACT_APP_BASE_URL;
    try {
      const response = await api.delete(`${link}/api/subcategories/delete`, {
        data: { ids: selectedItems }, // Send the selected IDs for deletion
      });

      if (response.status === 200) {
        alert('Selected subcategories deleted successfully!');
        // Refresh the list or remove the deleted items from the state
        setSubcategories(subcategories.filter((subcategory) => !selectedItems.includes(subcategory.id)));
        setSelectedItems([]); // Clear selected items after deletion
        setSelectAll(false); // Deselect global checkbox
      } else {
        alert('Failed to delete selected subcategories.');
      }
    } catch (error) {
      console.error('Error deleting subcategories:', error);
      alert('Failed to delete selected subcategories.');
    }
  };


  const handleSelectAllDelete = (e) => {
    setSelectAll(e.target.checked);
    const updatedSelection = {};
    subCategoryList.forEach((product) => {
      updatedSelection[product.id] = e.target.checked;
    });
    setSelectedProducts(updatedSelection);
  };



  const handleProductDelete = async(e, productId) => {
    const updatedSelection = { ...selectedProducts, [productId]: e.target.checked };
    setSelectedProducts(updatedSelection);

    console.log('subcategory delete ',updatedSelection)

    if (e.target.checked) {
      setProductId(productId); // Set only the currently selected product
    } else {
      setProductId(null); // Uncheck and clear the selection
    }

  };

  const handleDeleteItem = async () => {

    if(productId==null){
      alert('Select Item')
      return;
    }
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint=`/api/productSubCategory-delete/${productId}`
      const url=link+endpoint

      const res=await api.delete(url)

       
          
      alert("Selected products deleted successfully.");
      
      // Optionally, you can refresh the product list after deletion
      fetchSubCategoryData();

      // Clear selected products
      setSelectedProducts({}); 
      setProductId(null)
        

    } catch (error) {
      console.error("Error deleting products:", error);
      alert("Failed to delete selected products.");
    }
  }

  useEffect(() => {
    if (selectAll) {
      const updatedSelection = {};
      subCategoryList.forEach((product) => {
        updatedSelection[product.id] = true;
      });
      setSelectedProducts(updatedSelection);
    } else {
      setSelectedProducts({});
    }
  }, [selectAll, subCategoryList]);

  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleForwardClick = () => {
    if(pagination.next){
    setPage(page + 1);
    }
  };


   // Show loader while data is being fetched
   if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  
  return (
    <div>
      <div className='ecom-allusers-container'>
        <DashBoardHead/>
        
        <div className="ecom-endusers-headers">
          <div className="ecom-endusers-left-header">
            <div className="ecom-endusers-above-heading">
              <p onClick={()=>handleTabClick('led')} className={activeTab=='led' ? 'ecom-product-active':''}>LEDs</p>
              <p onClick={()=>handleTabClick('simulator')} className={activeTab=='simulator' ? 'ecom-product-active' : ''}>Testing (Simulators)</p>
              <p onClick={()=>handleTabClick('solar')} className={activeTab=='solar' ? 'ecom-product-active' : ''}>Solar Turnkey</p>
              <p onClick={()=>handleTabClick('electrical')} className={activeTab=='electrical' ? 'ecom-product-active' :''}>Electrical Turnkey</p>
              <p onClick={()=>handleTabClick('refrigerator')} className={activeTab=='refrigerator' ? 'ecom-product-active' :''}>Refrigerator</p>
              <p onClick={()=>handleTabClick('accessories')} className={activeTab=='accessories' ? 'ecom-product-active' :''}>Accessories</p>
            </div>
          </div>
        </div>

<div className='ecom-subcategory-newdiv'>
    <button onClick={handleBackClick} disabled={page == 1}>
              <IoIosArrowBack />
            </button>
            <span>{page}</span>
            <button onClick={handleForwardClick} disabled={!pagination.next}>
              <IoIosArrowForward />
            </button>
  
    <button className='ecom-subcategory-addbtn' onClick={()=>navigate('/admin/addsubcategory')}><IoMdAdd className='ecom-subcategory-add'/>New Product</button>
    <button onClick={handleDeleteItem}><RiDeleteBin6Line className='ecom-subcategory-delicon' /></button>
</div>

        <div>
         {activeTab=='led' && 
          <div>
              <div className="">
            <div className="">
        <table className="user-table">
          <thead>
            <tr>
            <th>
              {/* <input type="checkbox" checked={selectAll} onChange={handleSelectAllDelete} /> */}
              </th>
              <th>S.No.</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Render table rows based on active tab */}
            {subCategoryList.map((user, index) => {
              console.log(user)
             return(             
              <tr key={index}>
                {/* <td>{user.id}</td> */}
                <td>
                <input
                                type="checkbox"
                                checked={productId==user.id}
                                onChange={(e) => handleProductDelete(e, user.id)}
                              />
                      </td>
                <td>{index+1}</td>
                <td>{user.sub_category_name}</td>
                <td>{user.sub_category_active_status==true ? 1 :0}</td>
                
                <td>
                  <Link
                  // to='/admin/viewProductDetail'
                   to={{ pathname: `/admin/subcategoryDetail/${user.id}`, state: { user } }}>
                  <button className="ecom-alluser-view-details">View Details</button>
                  </Link>                  
                </td>             
              </tr>
             )
})}
          </tbody>
        </table>
    </div> 

            </div>
            </div>}</div>


        </div>      
    </div>
  )
}

export default EcomAllSubCategory;
