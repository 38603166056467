import React, { useEffect, useState } from "react";
import "./MyOrder.css";
import { Link, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import api from "../../Components/Registration/Api";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const MyOrder = () => {
  const navigate = useNavigate();
  const [wholeOrderData, setWholeOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading]=useState(true)
  const [pagination, setPagination]=useState({next:null})


  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleForwardClick = () => {
    if (pagination.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const orderData = [
    {
      id: 1,
      name: "CTI-SL 50W FT-D Street Light",
      price: 249,
      status: "Cancelled",
      date: "Aug 15, 2024",
      description: "As per your request, your item has been cancelled",
    },
    {
      id: 2,
      name: "CTI-SL 30W FT-D Street Light",
      price: 299,
      status: "Delivered",
      date: "Aug 15, 2024",
      description: "Your item has been delivered",
    },
    {
      id: 3,
      name: "CTI-SL 50W FT-D Street Light",
      price: 249,
      status: "Returned",
      date: "Aug 15, 2024",
      description: "As per your request, your item has been returned",
    },
    {
      id: 4,
      name: "CTI-SL 30W FT-D Street Light",
      price: 299,
      status: "Delivered",
      date: "Aug 15, 2024",
      description: "Your item has been delivered",
    },
  ];

  const statusColor = {
    Delivered: "green",
    Cancelled: "red",
    Returned: "orange",
  };

  useEffect(() => {
    fetchOrderData();
  }, [page]);


  const fetchOrderData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/user/history/order?page=${page}`;
      const url = link + endpoint;

      const res = await api.get(`${url}`);

      console.log(res);

      if (res.status == 200) {
     
        setWholeOrderData(res.data.results);
        setPagination({
          next:res.data.next
        })
        setLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  };




  const [searchData, setSearchData] = useState("");
  const filteredData = searchData
    ? wholeOrderData.filter((item) =>
        item?.order_items?.product?.product_name
          ?.toLowerCase()
          .includes(searchData.toLowerCase())
      )
    : wholeOrderData;

  const converttoIst = (dateString) => {
    const date = new Date(dateString);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString("en-IN", options);
  };

  const handleOrderDetails=(id)=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/Orderdelivery/${id}`)
  }

   // Show loader while data is being fetched
   if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="orders-container_customer">
      <div className="breadcrumb">
        <Link to="/ecom">Home</Link> &gt;
        <Link to="/userprofile"> My Account</Link> &gt;
        <span aria-current="page"> My Orders</span>
      </div>

      <div className="orders-main_customer">
        <aside className="orders-filter_customer">
          <h3>FILTERS</h3>
          <div className="orderstatus_customer">
            <h4>Order Status</h4>
            <label>
              <input type="checkbox" defaultChecked />
              <span> On the way</span>
            </label>
            <br />
            <label>
              <input type="checkbox" /> Delivered
            </label>
            <br />
            <label>
              <input type="checkbox" /> Cancelled
            </label>
            <br />
            <label>
              <input type="checkbox" /> Returned
            </label>
            <br />
          </div>
          <div className="orders-date-filter_customer">
            <h4>Date</h4>
            <label>
              <input type="checkbox" defaultChecked /> Last 30 days
            </label>
            <br />
            <label>
              <input type="checkbox" /> 2023
            </label>
            <br />
            <label>
              <input type="checkbox" /> 2022
            </label>
            <br />
            <label>
              <input type="checkbox" /> Older
            </label>
          </div>
        </aside>

        <div className="orders-content_customer">
          <div className="orders-search_customer">
            <input type="text" placeholder="Search Orders" />
            <button>Search Orders</button>
          </div>

          <div className="ecom-orderhistory-pagebtn">
            <button onClick={handleBackClick} disabled={page == 1}>
              <IoIosArrowBack />
            </button>
            <span>{page}</span>
            <button onClick={handleForwardClick} disabled={!pagination.next}>
              <IoIosArrowForward />
            </button>
          </div>


          <div className={`orders-list_customer `}>
            {wholeOrderData ? (
              wholeOrderData?.map((item) =>
                item.order_items?.map((order) => {

                  const hasMultipleItems = item?.order_items?.length > 1;
                  const productImages = order?.product?.product_image_url?.split(",") || [];
                  const rawMaterialImages = order?.raw_materials?.product_image_url?.split(",") || [];
                  const allImages = [...productImages, ...rawMaterialImages].filter(Boolean);

                  return (
                    <div className={` `}>
                      <div
                        key={order.id}
                        className={`order-card_customer`}
                        onClick={()=>handleOrderDetails(item.id)}
                      >
                        
                        {allImages.length > 0 ? (
              <img
                src={allImages[0]} // Show the first image dynamically
                alt="Order"
                className="order-image_customer"
              />
            ) : (
              <p>No images available</p> // Fallback if no images are present
            )}

                        <div className="order-info_customer">
                          <div className="order-details_customer">
                            <p className="order-name_customer">
                              {" "}
                              {order?.product?.product_name} {order?.raw_materials?.details}
                            </p>
                          </div>
                          <div className="order-details_customer">
                            <p className="order-name_customer">
                              Qty: {order?.quantity}
                            </p>
                          </div>
                          
                          <div className="order-details_customer">
                            <p className="order-name_customer" style={{color:'var(--color-blue)'}}>
                              Order ID: {item?.id}
                            </p>
                          </div>
       
                          <div>
                            <p className="order-price_customer">
                              ₹ {item?.total_price}
                            </p>
                          </div>

                          <div className="order-status_customer">
                            <div className="status-text_customer">
                              <span
                                className="status-indicator_customer"
                                // style={{ backgroundColor: statusColor[order?.status] }}
                              ></span>{" "}
                              &nbsp;
                              {converttoIst(item?.created_date)}
                            </div>
                        
                           
                      

                            <p className="order-description_customer">
                              Delivery Status:&nbsp;{item?.delivery_status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <p>No Items Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrder;