import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaShoppingCart } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import { VscHome } from "react-icons/vsc";

function DashBoardHead() {
    const navigate=useNavigate()

    const name=localStorage.getItem('username')

    const handleAdminLogOut=()=>{
        localStorage.clear()
        alert('Logged out')
        navigate('/register')
      }

  return (
    <div>
       <div className='ecom-dashboard-header'>
                <div className='ecom-dashboard-search'>
                    <input className='ecom-dashboard-search-input' placeholder='Search Here...' />
                    <CiSearch className='ecom-dashboard-search-icon'/>
                </div>

                <div className='ecom-dashboard-user-details'>
                    <div><b>Hello, {name}</b></div>
                    {/* <div className='ecom-dashboard-icon'><FaShoppingCart/></div> */}
                    <div className='ecom-dashboard-icon' onClick={()=>navigate('/')}><VscHome/></div>
                    <div className='ecom-dashboard-exit-icon' onClick={handleAdminLogOut}><BiExit/></div>
                </div>
           </div>
    </div>
  )
}

export default DashBoardHead;