import React, { useEffect, useRef, useState } from "react";
import "./EcomProductDetail.css";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import led from "../EcomImages/ecom-led.png";
import DashBoardHead from "../DashBoard/DashBoardHead";
import api from "../../Components/Registration/Api";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

function EcomAddAllProduct() {
  const { userId } = useParams();
  const admin_id = localStorage.getItem("admin_id");

  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [productImg, setProductImg] = useState([]);
  const [activeStatus, setActiveStatus] = useState(true);

  const [categoryList, SetCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCatPage, setsubCatPage]=useState(1)
    const [subcatPagination, setSubCatPagination]=useState({next:null})
  

  useEffect(() => {
    fetchCategoryData();

  }, []);

  useEffect(()=>{
    fetchSubCategoryData();
  },[subCatPage])

  const fetchCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/listproductCategory";
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        SetCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/listproductSubCategory?page=${subCatPage}`;
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        setSubCategoryList(data);
        setSubCatPagination({next:res.data.next})
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBackSubCategoryBtn=()=>{
    if(subCatPage>1){
      setsubCatPage(subCatPage-1)
    }
  }
  const handleForwardSubCategoryBtn=()=>{
    if(subcatPagination.next){
      setsubCatPage((prev)=>prev+1)
    }
  }

  const [AllData, setAllData] = useState({
    name: "",
    description: "",
    price: "",
    productImg: [],
    Category: null,
    SubCategory: null,
    categoryDetails: null,
    subCategoryDetails: null,
    breadth: null,
    height: null,
    weight: null,
    wattage: null,
    cutout: null,
    power: null,
    voltage: null,
    dimension: null,
    pf: null,
    light_flux: null,
    cut_dia: null,
    cut_size: null,
    q1_price: "",
    q2_price: "",
    q3_price: "",
    q4_price: "",
    q5_price: "",
  });

  const showOnRightSide = [
    "price",
    "q1_price",
    "q2_price",
    "q3_price",
    "q4_price",
    "q5_price",
    "categoryDetails",
    "subCategoryDetails",
  ];

  const getRightSideFields = (key) => {
    if (showOnRightSide.includes(key)) {
      const isTextArea =
        key === "categoryDetails" || key === "subCategoryDetails";

      return (
        <p key={key}>
          <span>{key.replace(/_/g, " ").toUpperCase()}:</span>
          {isTextArea ? (
            <textarea
              placeholder={`Enter ${key.replace(/_/g, " ")}`}
              value={AllData[key] || ""}
              onChange={(e) => updateField(key, e.target.value)}
            />
          ) : (
            <input
              type="text"
              placeholder={`Enter ${key.replace(/_/g, " ")}`}
              value={AllData[key] || ""}
              onChange={(e) => updateField(key, e.target.value)}
            />
          )}
        </p>
      );
    }
    return null;
  };

  const updateField = (field, value) => {
    setAllData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [files, setFiles] = useState([]);
  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleAddProduct = async () => {
    if (AllData.description == "") {
      alert("Please add Description");
      return;
    }
    if (AllData.name == "") {
      alert("Please Add Product Name");
      return;
    }

    if (!AllData.SubCategory || !AllData.Category) {
      alert("Please Select Category and SubCategory");
      return;
    }
    if(productImg.length==0){
      alert('Select Image')
      return
    }

    if (
      !AllData.price ||
      !AllData.q1_price ||
      !AllData.q2_price ||
      !AllData.q3_price ||
      !AllData.q4_price ||
      !AllData.q5_price
    ) {
      alert("Please fill prices");
      return;
    }

    try {
      const link = process.env.REACT_APP_BASE_URL;
      const updatedProduct = {
        product_name: AllData.name || "",
        product_description: AllData.description || "",
        product_fixed_price: AllData.price || "",
        // files: AllData.productImg || [],  // Assuming productImg is an array
        product_category: AllData.Category || null,
        product_sub_category: AllData.SubCategory || null,
        product_category_details: AllData.categoryDetails || null,
        product_sub_category_details: AllData.subCategoryDetails || null,
        product_active_status: activeStatus,
        breadth: AllData.breadth || null,
        height: AllData.height || null,
        weight: AllData.weight || null,
        wattage: AllData.wattage || null,
        cutout: AllData.cutout || null,
        power: AllData.power || null,
        voltage: AllData.voltage || null,
        dimension: AllData.dimension || null,
        pf: AllData.pf || null,
        light_flux: AllData.light_flux || null,
        cut_dia: AllData.cut_dia || null,
        cut_size: AllData.cut_size || null,
        created_by: admin_id,
        updated_by: admin_id,
        q1_price: AllData.q1_price || "",
        q2_price: AllData.q2_price || "",
        q3_price: AllData.q3_price || "",
        q4_price: AllData.q4_price || "",
        q5_price: AllData.q5_price || "",
      };
  
      const formData = new FormData();
      for (let key in updatedProduct) {
        formData.append(key, updatedProduct[key]);
      }
  
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
  
      productImg.forEach((file, idx) => {
        formData.append("files", file);
      });
  
      formData.append("activeStatus", activeStatus);
  
      const response = await api.post(`${link}/api/createproductitem`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      const data = response.data || response;
      console.log(data);
  
      if (response.status == 201) {
        alert("Added Product successfully!");
      } else {
        alert("Failed to add Data");
      }
    } 
  
    catch (error) {
      alert(error)
    }
  };

  // for image section
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [editIndex, setEditIndex] = useState(null);

  // Handler for adding new images
  const handleAddImage = (e) => {
    setProductImg([...productImg, ...e.target.files]);
    fileInputRef.current.value = null; // Clear input after uploading
  };

  // Handler for replacing an image during edit
  const handleEditImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage && editIndex !== null) {
      const updatedImages = [...productImg];
      updatedImages[editIndex] = newImage; // Replace the specific image
      setProductImg(updatedImages);
      setEditIndex(null); // Reset edit mode
    }
    fileInputRef.current.value = null; // Clear input after uploading
  };

  // Trigger file input for editing
  const handleEditClick = (index) => {
    setEditIndex(index);
    fileInputRef.current.click();
  };

  // Handler for deleting an image
  const handleDeleteImage = (index) => {
    setProductImg(productImg.filter((_, imgIndex) => imgIndex !== index));
  };

  // Open file dialog for adding new images
  const handleAddImageClick = () => {
    setEditIndex(null); // Reset edit mode for adding new images
    fileInputRef.current.click();
  };



  // Raw material data handled
  const [material, setMaterial] = useState("finished");
  const handleMaterialChange = (e) => {
    setMaterial(e.target.value);
  };

  const [rawActiveStatus, setRawActiveStatus] = useState(true);
  const [rawProductImage, setRawProductImage] = useState([]);
  const fileRawInputRef = useRef();
  const [rawEditIndex, setRawEditIndex] = useState(null);

  const handleRawDeleteImage = (index) => {
    setRawProductImage(rawProductImage.filter((_, idx) => idx !== index));
  };

  const handleRawEditClick = (index) => {
    setRawEditIndex(index);
    fileRawInputRef.current.click();
  };

  const handleRawAddImage = (e) => {
    setRawProductImage([...rawProductImage, ...e.target.files]);
    fileRawInputRef.current.value = null;
  };

  const handleRawEditImage = (e) => {
    const newImg = e.target.files[0];
    if (newImg && rawEditIndex !== null) {
      const updateImgs = [...rawProductImage];
      updateImgs[rawEditIndex] = newImg;
      setRawProductImage(updateImgs);
      setRawEditIndex(null);
    }
    fileRawInputRef.current.value = null;
  };

  const [rawAllData, setRawAllData] = useState({
    details: "",
    price: "",
    q1_price: "",
    q2_price: "",
    q3_price: "",
    q4_price: "",
    q5_price: "",
    Category: null,
    SubCategory: null,
    description: "",
  });

  const handleRawInpChange = (e) => {
    const { name, value } = e.target;
    setRawAllData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveRawMaterial = async () => {
    if (
      !rawAllData.q1_price ||
      !rawAllData.q2_price ||
      !rawAllData.q3_price ||
      !rawAllData.q4_price ||
      !rawAllData.q5_price ||
      !rawAllData.price
    ) {
      alert("Fill All Prices");
      return;
    }

    if (rawAllData.details.length < 10) {
      alert("Detail must be more than 10char");
      return;
    }

    if (rawProductImage.length === 0) {
      alert("Select Image");
      return;
    }

    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/createproductrawitem/";
      const url = link + endpoint;

      const updateData = {
        details: rawAllData.details || "",
        product_category: rawAllData.Category || null,
        product_sub_category: rawAllData.SubCategory || null,
        product_fixed_price:rawAllData.price ||"",
        q1_price: rawAllData.q1_price || "",
        q2_price: rawAllData.q2_price || "",
        q3_price: rawAllData.q3_price || "",
        q4_price: rawAllData.q4_price || "",
        q5_price: rawAllData.q5_price || "",
        updated_by: admin_id,
        created_by: admin_id,
      };

      const formData = new FormData();
      for (let key in updateData) {
        formData.append(key, updateData[key]);
      }

      rawProductImage.forEach((file, idx) => {
        formData.append("files", file);
      });
      // formData.append("activeStatus", rawActiveStatus);

      const response = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data || response;
      console.log(data);

      if (response.status == 201) {
        alert("Added Product successfully!");
        setRawProductImage([])
        setRawAllData({
          details:'',
          Category:null,
          SubCategory:null,
          price:'',
          q1_price:'',
          q2_price:'',
          q3_price:'',
          q4_price:'',
          q5_price:'',
          description:''
        })
      } else {
        alert("Failed to add Data");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="ecom-allusers-container">
        <DashBoardHead />

        <div className="ecom-product-detail-main-section">
          <div>
            <Link to="/admin/allProducts">Back</Link>
          </div>

          <div className="ecom-rawmaterial-product-dropdown">
            <p>Select Product Material</p>
            <div>
              <select value={material} onChange={handleMaterialChange}>
                <option value="finished">Finished Material</option>
                <option value="raw">Raw Material</option>
              </select>
            </div>
          </div>

          {material == "finished" && (
            <div className="ecom-product-detail-container">
              <div className="ecom-product-detail-image-section">
                {productImg.length > 0 &&
                  productImg.map((file, index) => (
                    <div key={index} className="ecom-product-detail-images">
                      <div className="ecom-product1-image">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Selected Image ${index + 1}`}
                        />
                      </div>
                      <div className="ecom-product-detail-edit-image-text">
                        <p onClick={() => handleEditClick(index)}>Edit</p>
                        <p
                          style={{ color: "red" }}
                          onClick={() => handleDeleteImage(index)}
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  ))}

                <div className="ecom-product-detail-addimage-text">
                  <label>
                    <input
                      type="file"
                      multiple={editIndex === null}
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }} // Hide the actual file input
                      onChange={
                        editIndex === null ? handleAddImage : handleEditImage
                      }
                    />
                    <p
                    // onClick={() => { if (editIndex === null) fileInputRef.current.click(); }}
                    // onClick={handleAddImageClick}
                    >
                      Add Images
                    </p>
                  </label>
                </div>
              </div>

              <div className="ecom-subcategory-activestate">
                <p>Active Status: </p>
                <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="true"
                    checked={activeStatus === true}
                    onChange={() => setActiveStatus(true)}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="false"
                    checked={activeStatus === false}
                    onChange={() => setActiveStatus(false)}
                  />
                  False
                </label>
              </div>

              <div className="ecom-product-detail-section">
                <div className="ecom-product-left-detail-sectionwise">
                  {Object.keys(AllData).map((key) => {
                    if (
                      showOnRightSide.includes(key) ||
                      key == "description" ||
                      key == "productImg"
                    ) {
                      return null;
                    }

                    return (
                      <p>
                        <span>{key.replace(/_/g, " ").toUpperCase()}:</span>
                        {key == "Category" ? (
                          // Dropdown for 'category'
                          <select
                            value={AllData[key] || ""}
                            onChange={(e) => updateField(key, e.target.value)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {categoryList.map((option) => (
                              <option key={option} value={option.id}>
                                {option.category_name}
                              </option>
                            ))}
                          </select>
                        ) : key == "SubCategory" ? (
                          <>
                          <select
                            value={AllData[key] || ""}
                            onChange={(e) => updateField(key, e.target.value)}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {subCategoryList.map((option) => (
                              <option key={option} value={option.id}>
                                {option.sub_category_name}
                              </option>
                            ))}
                          </select>
                           <div style={{display:'flex'}}> <div
                                                 style={{cursor:'pointer'}}
                                                 onClick={handleBackSubCategoryBtn} disabled={subCatPage==1}
                                                 >
                                                                <IoIosArrowBack />
                                                              </div>
                                                              <span>{subCatPage}</span>
                                                              <div 
                                                              style={{cursor:'pointer'}}
                                                              onClick={handleForwardSubCategoryBtn} disabled={!subcatPagination.next}
                                                              >
                                                                <IoIosArrowForward />
                          </div></div>
                          </>
                        ) : (
                          <input
                            type="text"
                            placeholder={`Enter ${key.replace(/_/g, " ")}`}
                            value={AllData[key] || ""}
                            onChange={(e) => updateField(key, e.target.value)}
                          />
                        )}
                      </p>
                    );
                  })}
                </div>

                <div className="ecom-product-right-detail-section">
                  {Object.keys(AllData).map(getRightSideFields)}
                </div>
              </div>

              <div className="ecom-product-detail-desc-section">
                <p>Description:</p>
                <div className="ecom-product-detail-desc-text">
                  <textarea
                    placeholder="Product Description"
                    value={AllData.description}
                    onChange={(e) => updateField("description", e.target.value)}
                    style={{
                      background: "none",
                      border: "none",
                      outline: "none",
                      resize: "none", // Optional: Remove resize handle
                    }}
                  />
                </div>
              </div>

              <div className="ecom-product-detail-btns">
                <button
                  className="ecom-product-detail-cancel-btn"
                  onClick={() => navigate("/admin/allProducts")}
                >
                  Cancel
                </button>
                <button
                  className="ecom-product-detail-save-btn"
                  onClick={handleAddProduct}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {material == "raw" && (
            <div className="ecom-product-detail-container">
              <div className="ecom-product-detail-image-section">
                {rawProductImage.length > 0 &&
                  rawProductImage.map((file, index) => (
                    <div key={index} className="ecom-product-detail-images">
                      <div className="ecom-product1-image">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Selected Image ${index + 1}`}
                        />
                      </div>
                      <div className="ecom-product-detail-edit-image-text">
                        <p onClick={() => handleRawEditClick(index)}>Edit</p>
                        <p
                          style={{ color: "red" }}
                          onClick={() => handleRawDeleteImage(index)}
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  ))}

                <div className="ecom-product-detail-addimage-text">
                  <label>
                    <input
                      multiple={rawEditIndex == null}
                      type="file"
                      accept="image/*"
                      ref={fileRawInputRef}
                      style={{ display: "none" }}
                      onChange={
                        rawEditIndex == null
                          ? handleRawAddImage
                          : handleRawEditImage
                      }
                    />
                    <p>Add Images</p>
                  </label>
                </div>
              </div>

              <div className="ecom-subcategory-activestate">
                <p>Active Status</p>
                <label>
                  <input
                    type="radio"
                    name="rawActiveStatus"
                    value="true"
                    checked={rawActiveStatus == true}
                    onChange={() => setRawActiveStatus(true)}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="rawActiveStatus"
                    value="false"
                    checked={rawActiveStatus == false}
                    onChange={() => setRawActiveStatus(false)}
                  />
                  False
                </label>
              </div>

              <div className="ecom-product-detail-section">
                <div className="ecom-product-left-detail-sectionwise">
                  <p>
                    <span>Details: </span>
                    <input
                      name="details"
                      value={rawAllData.details}
                      onChange={handleRawInpChange}
                    />
                  </p>
                  <p>
                    <span>Category:</span>
                    <select
                      name="Category"
                      value={rawAllData.Category || ""}
                      onChange={handleRawInpChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {categoryList.map((option) => (
                        <option key={option} value={option.id}>
                          {option.category_name}
                        </option>
                      ))}
                    </select>
                  </p>
                  <p>
                    <span>SubCategory:</span>
                    <select
                      value={rawAllData.SubCategory || ""}
                      name="SubCategory"
                      onChange={handleRawInpChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {subCategoryList.map((option) => (
                        <option key={option} value={option.id}>
                          {option.sub_category_name}
                        </option>
                      ))}
                    </select>
                    <div style={{display:'flex'}}> <div
                                                 style={{cursor:'pointer'}}
                                                 onClick={handleBackSubCategoryBtn} disabled={subCatPage==1}
                                                 >
                                                                <IoIosArrowBack />
                                                              </div>
                                                              <span>{subCatPage}</span>
                                                              <div 
                                                              style={{cursor:'pointer'}}
                                                              onClick={handleForwardSubCategoryBtn} disabled={!subcatPagination.next}
                                                              >
                                                                <IoIosArrowForward />
                          </div></div>
                  </p>
                  <p>
                    <span>Enter Price:</span>
                    <input
                      type="text"
                      name="price"
                      value={rawAllData.price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                </div>
                <div className="ecom-product-right-detail-section">
                  <p>
                    <span>Q1 Price: </span>
                    <input
                      type="text"
                      name="q1_price"
                      value={rawAllData.q1_price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                  <p>
                    <span>Q2 Price:</span>{" "}
                    <input
                      type="text"
                      name="q2_price"
                      value={rawAllData.q2_price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                  <p>
                    <span>Q3 Price:</span>{" "}
                    <input
                      type="text"
                      name="q3_price"
                      value={rawAllData.q3_price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                  <p>
                    <span>Q4 Price:</span>{" "}
                    <input
                      type="text"
                      name="q4_price"
                      value={rawAllData.q4_price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                  <p>
                    <span>Q5 Price: </span>
                    <input
                      type="text"
                      name="q5_price"
                      value={rawAllData.q5_price}
                      onChange={handleRawInpChange}
                    />
                  </p>
                </div>
              </div>

              <div className="ecom-product-detail-desc-section">
                <p>Description:</p>
                <div className="ecom-product-detail-desc-text">
                  <textarea
                    placeholder="Product Description"
                    value={rawAllData.description}
                    name="description"
                    onChange={handleRawInpChange}
                    style={{
                      background: "none",
                      border: "none",
                      outline: "none",
                      resize: "none",
                    }}
                  />
                </div>
              </div>

              <div className="ecom-product-detail-btns">
                <button
                  className="ecom-product-detail-cancel-btn"
                  onClick={() => navigate("/admin/allProducts")}
                >
                  Cancel
                </button>
                <button
                  className="ecom-product-detail-save-btn"
                  onClick={handleSaveRawMaterial}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EcomAddAllProduct;
