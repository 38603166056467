import React, { useEffect, useState } from 'react';
import ProfileSidebar from '../ProfilesideBar/ProfileSidebar';
import "./UserProfileEdit.css";
import footerimg from '../footer_img.png'
import api from '../../Components/Registration/Api';
import { useNavigate } from 'react-router-dom';

const UserProfileEdit = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal]=useState(false)
  const [updatedInfo, setUpdatedInfo]=useState(null)
  const [password, setPassword]=useState('')
  const [errorMessage, setErrorMessage]=useState('')
  const navigate=useNavigate()


  const [userInfo, setUserInfo] = useState({
    fName: "",
    lName: "",
    email: "",
    mobile_number: "",
    admin_name:'',
  });

  const handleEditToggle = () => {
    setIsEditing((prevState) => !prevState);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSave = async() => {
    // Add save functionality if needed, e.g., API call to save changes
    setUpdatedInfo(userInfo)
    setShowModal(true)   
    setErrorMessage('')
    setPassword('')
    setIsEditing(false);
  };


  useEffect(()=>{
    fetchUserDetails()
  },[])

  const fetchUserDetails=async()=>{
    try {
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/user/profile'
      const url=link+endpoint

      const res=await api.get(url)
      if(res.status==200){
        const data=res.data

        setUserInfo({
          fName:data.fName,
          lName:data.lName,
          email:data.email,
          mobile_number:data.mobile_number,
          admin_name:data.admin_name
        })
      }
    } catch (error) {
      console.error(error)
    }
  }


  const handleUpdateDetails=async()=>{

    if(!password){
      alert('Enter Password')
    }

    try {
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/user/update/profile'
      const url=link+endpoint

      const payload={
        ...userInfo,
        password
      }

      const res=await api.patch(url, payload)
      if(res.status==200){
        setShowModal(false)
        setPassword('')
        setErrorMessage('')
        fetchUserDetails()        
        alert('Updated Successfully')
      }
      
    } catch (error) {
      if(error.response){
        if(error.response?.data){
          setErrorMessage(error.response?.data?.detail)
        }
        else{
          setErrorMessage('Failed to update profile. Please try again later.');
        }
      }
    }
  }


  const handleForgotPassword=()=>{
    navigate('/forgotpassword')
  }


  return (
    <div className='main_div_userprofile_customer'>
      <div className='user_profile_pg_div_customer'>        
        <ProfileSidebar />
      </div>
      <div className="userprofile-page_customer">
        <div className="userprofile-content_customer">
          <h4>
            Profile Information
            <button onClick={handleEditToggle} className="edit-link_customer">
              {isEditing ? "Cancel" : "Edit"}
            </button>
          </h4>

          <div className="userprofile-edit-section_customer">
            <input 
              type="text"
              name="fName"  
              placeholder="First Name"
              value={userInfo.fName}
              onChange={handleChange}
              disabled={!isEditing}
              className="userprofile-input_customer"
            />
            <input 
              type="text"
              name="lName"
              placeholder="Last Name"
              value={userInfo.lName}
              onChange={handleChange}
              disabled={!isEditing}
              className="userprofile-input_customer"
            />
          </div>

          <h3>Email Address</h3>
          <div className="userprofile-edit-section_customer">
            <input 
              type="email"
              name="email"
              placeholder="Email Address"
              value={userInfo.email}
              onChange={handleChange}
              disabled={!isEditing}
              className="userprofile-input_customer"
            />
          </div>

          <h3>Mobile Number</h3>
          <div className="userprofile-edit-section_customer">
            <input 
              type="text"
              name="mobile_number"
              placeholder="Mobile Number"
              value={userInfo.mobile_number}
              onChange={handleChange}
              disabled={!isEditing}
              className="userprofile-input_customer"
            />
          </div>

          {isEditing && (
            <button onClick={handleSave} className="userprofile-save-button_customer">
              SAVE
            </button>
          )}


          {
            showModal && (
<>
<div className="modalupadte-overlay_customer" onClick={() => setShowModal(false)}></div>

              <div className='updateprofilemodal'>
                <h5>Enter Password</h5>
                <input 
                type='text'
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                
                placeholder='Enter Password'
                className='userprofile-input_customer'/>

{showModal && errorMessage && (
  <div className="error-message">
    <p style={{color:'red'}}>{errorMessage}</p>
  </div>
)}

                <button className="userprofile-save-button_customer" onClick={handleUpdateDetails}>UPDATE</button>
                
                <div>
                <b style={{cursor:'pointer'}} onClick={handleForgotPassword}>Forgot Password ? </b>
                </div>
              </div>  
              </>
            )
          }

<h3>FAQs</h3>
        <div className="userprofile-faq_customer">
          <p className="userprofile-faq-question_customer">What happens when I update my email address (or mobile number)?</p>
          <p className="userprofile-faq-answer_customer">Your login email id (or mobile number) changes, likewise. You'll receive all your account-related communication on your updated email address (or mobile number).</p>

          <p className="userprofile-faq-question_customer">When will my E-commerce account be updated with the new email address (or mobile number)?</p>
          <p className="userprofile-faq-answer_customer">It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes.</p>

          <p className="userprofile-faq-question_customer">What happens to my existing E-commerce account when I update my email address (or mobile number)?</p>
          <p className="userprofile-faq-answer_customer">Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information, and personal details.</p>

          <p className="userprofile-faq-question_customer">Does my Associate account get affected when I update my email address?</p>
          <p className="userprofile-faq-answer_customer">Our E-commerce has a 'single sign-on' policy. Any changes will reflect in your Seller account also.</p>
        </div>

        <div className='del_deactive_btn_customer'>
        <p className='deactive_customer'>Deactivate Account</p>
        <p className='delete_profile_customer'>  Delete Account</p>
        </div>
        <div className='footer_img_customer'>
            {/* <img src={footerimg}/> */}
        </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileEdit;
