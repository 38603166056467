import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


function ExactRawMaterial() {

    
     const [isCategoryOpen, setCategoryOpen] = useState(false);
      const [selectedCategories, setSelectedCategories] = useState([]);
      const [isPriceOpen, setPriceOpen] = useState(false);
      const [price, setPrice] = useState(0);
      const [isRatingOpen, setIsRatingOpen] = useState(false);
      const [selectedRatings, setSelectedRatings] = useState([]);
      const [page, setPage]=useState(1)
      const [pagination, setPagination]=useState({next:null})

    const navigate=useNavigate()
    
    const [loading, setLoading]=useState(true)
    
      const toggleCategory = () => {
        setCategoryOpen(!isCategoryOpen);
      };
      const togglePrice = () => {
        setPriceOpen(!isPriceOpen);
      };
       // Toggle dropdown visibility
       const toggleRating = () => {
        setIsRatingOpen(!isRatingOpen);
      };
    
    
      // Handle checkbox change
      const handleRatingChange = (event) => {
        const value = event.target.value;
        setSelectedRatings((prevRatings) => {
          if (prevRatings.includes(value)) {
            // Remove rating if it's already selected
            return prevRatings.filter((rating) => rating !== value);
          } else {
            // Add rating if it's not selected
            return [...prevRatings, value];
          }
        });
      };
    
      const handleCategoryChange = (event) => {
        const value = event.target.value;
        setSelectedCategories((prevSelected) => {
          if (prevSelected.includes(value)) {
            return prevSelected.filter((item) => item !== value);
          } else {
            return [...prevSelected, value];
          }
        });
      };
        // Handle price range selection
        const handlePriceChange = (event) => {
            setPrice(event.target.value);
          };
    

const [products, setProducts]=useState([])

useEffect(() => {
  const fetchData=async()=>{
    const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `/api/listproductrawitem/?page=${page}`;
    const url = link + endpoint;
    try {
      const res = await axios.get(`${url}`);  
      if (res.status == 200) {
        setPagination({next:res.data.next})
        setProducts(res.data.results);
        setLoading(false)
      } else if (res.status == 401) {
        console.warn("Session Expired , LogIn Again");
      }
    } catch (error) {
      console.error(error);
    };
  }  
  fetchData()
  },[page])

  const viewMore = (rawId) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/userProductRawDetail/${rawId}`);
  };

  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleForwardClick = () => {
  
    if(pagination.next){
      setPage((prevPage) => prevPage + 1);
    }
  };

    // Show loader while data is being fetched
    if (loading) {
      return (
        <div className="loader-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      );
    }

  return (
   <>

     <div className='main_subdropdown_div_customer'>
         
           {/* <div className='main_left_div_customer'>
             <div className='led_head_para_customer'>
               <h4>Raw Materials</h4>
                                    

             </div>
            
           <div class="filter_customer">
     <p class="filter-title_customer">FILTERS</p>
     <hr class="filter-divider_customer" />
     <div className="filter-option_customer" onClick={toggleCategory}>
                 <p>Category</p>
                 <span>&#9662;</span>
               </div>
               {isCategoryOpen && (
                 <div className="checkbox-group_customer">               
                   <label>
                     <input
                       type="checkbox"
                       value="Category1"
                       checked={selectedCategories.includes('Category1')}
                       onChange={handleCategoryChange}
                     />
                     LEDs Lights
                   </label>                
                   <label>
                     <input
                       type="checkbox"
                       value="Category2"
                       checked={selectedCategories.includes('Category2')}
                       onChange={handleCategoryChange}
                     />
                    Flood Lights
                   </label>
                   <label>
                     <input
                       type="checkbox"
                       value="Category3"
                       checked={selectedCategories.includes('Category3')}
                       onChange={handleCategoryChange}
                     />
                     Street Lights
                   </label>
                    <label>
                     <input
                       type="checkbox"
                       value="Category4"
                       checked={selectedCategories.includes('Category4')}
                       onChange={handleCategoryChange}
                     />
                     Category 4
                   </label> 
                 </div>
               )}
   
     <div class="filter-option_customer">
       <p>Size</p>
       <span>&#9662;</span>
     </div>
     <div className="filter-option_customer" onClick={togglePrice}>
                 <p>Price</p>
                 <span>&#9662;</span>
               </div>
               {isPriceOpen && (
                 <div className="slider-container_customer">
                   <input
                     type="range"
                     min="0"
                     max="3000"
                     value={price}
                     className="price-slider"
                     onChange={handlePriceChange}
                   />
                   <div className="slider-labels_customer">
                     <span>₹0</span>
                     <div className="slider-steps_customer">
                       <div className="step-line"></div>
                       <div className="step-line"></div>
                       <div className="step-line"></div>
                     </div>
                     <span>₹3000</span>
                   </div>
                   <div className="price-display_customer">
                     <span>Price: ₹{price}</span>
                   </div>
                 </div>
               )}
   
   <div className='filter-item_rating_customer' onClick={toggleRating}>
               <p>Rating</p>
               <span>&#9662;</span>
             </div>
             {isRatingOpen && (
               <div className='rating-checkbox-group_customer'>
                 {[1, 2, 3, 4, 5].map((rating) => (
                   <label key={rating}>
                     <input
                       type='checkbox'
                       value={rating}
                       checked={selectedRatings.includes(rating.toString())}
                       onChange={handleRatingChange}
                     />
                     {rating} Star{rating > 1 ? 's' : ''}
                   </label>
                 ))}
               </div>
             )}
   
           
     <div class="filter-option_customer">
       <p>Discount</p>
       <span>&#9662;</span>
     </div>
   </div>
   
           </div> */}


           <div className='rightdivforshowingproductdetails'>
          
          <div className='paginationclass'>
          <button onClick={handleBackClick} disabled={page == 1}>
                           <IoIosArrowBack />
                         </button>
                         <span><b>{page}</b></span>
                         <button onClick={handleForwardClick} disabled={!pagination.next}>
                           <IoIosArrowForward />
                         </button>
          </div>
           <div className='main_right_div_raw_customer'>
           {products.length==0 && <p style={{textAlign:'center'}}>Loading...</p>}
   
   {
     products.map((product, idx)=>{
       return (
         <div className='material_card_content_customer'>
         <div className='pro_material_img_customer'>
           <img src={product.product_image_url.split(',')[0]} // Show first image
                   alt={product.product_name}/>
         </div>
         <div className='material_product_content_customer'>
           <p className='product_name_customer'>{product?.details?.length > 10 
    ? product.details.slice(0, 10) + '...' 
    : product?.details}</p>
           <div className="price_section_customer">
           <span className="discounted_price_customer">{`${product?.q1_price}`}</span>
             <span className="original_price_customer">{`${product?.q1_price}`}</span>
             <span className="discount_percentage_customer">7% Off</span>
           </div>
           <div className="rating_customer">
             {product?.average_rating}&nbsp; <FaStar />
           </div>
         </div>
         <button className="view_more_button_customer" onClick={()=>viewMore(product?.id)}>View More
         </button>
       </div>
       )
     })
   }
   
   </div>
   </div>
         </div>
   </>
  )
}

export default ExactRawMaterial
