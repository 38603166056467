import React from 'react'
import './EcomEmployeeDetail.css'
import { FaShoppingCart } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import DashBoardHead from '../../DashBoard/DashBoardHead';


function EcomUserDetail() {
  return (
    <div>

<div className='ecom-user-detail-header'>
<DashBoardHead/>
</div>


<div className='ecom-user-details-div'>
<div className='ecom-user-back'><Link to='/admin/allEmployees'><p>Back</p></Link></div>

     <div className='ecom-user-detail-container'>  
        <div className='ecom-user-detail-main'>
            <div className='ecom-user-detail-section'>
                <div className='ecom-user-left-section-detail'>
                    <div>
                        <p>Type: <b> Electrician</b></p>
                        <br/>
                        <p>Degree:<b></b></p>
                        <p>Age:</p>
                        <p>Address:</p>
                        <p>City:</p>
                        <p>State:</p>
                        <p>Pin Code:</p>
                        <p>Documents</p>
                    </div>
                </div>
                <div className='ecom-user-right-section-detail'>
                    <div className='ecom-user-right-profile'>

                    </div>
                    <div className='ecom-user-right-profile-detail'>
                        <h5>Arun Sharma</h5>
                        <h5>+91 012345678</h5>
                        <h5>arun@gmail.com</h5>
                    </div>
                </div>
            </div>



            <div className='ecom-user-detail-btns'>
                <button className='ecom-user-discard-btn'>Discard</button>
                <button className='ecom-user-approve-btn'>Approve</button>
            </div>
        </div>
     </div>
</div>
    </div>
  )
}


export default EcomUserDetail;

