import React, { useEffect, useState } from "react";
import DashBoardHead from "../DashBoard/DashBoardHead";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import api from "../../Components/Registration/Api";
import { Link, useNavigate } from "react-router-dom";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";

function EcomOrderHistory() {
  const [allHistoryData, setAllHistoryData] = useState([]);
  const [deliveryStatus, setDeliveryStatus] = useState("pending");
  const [page, setPage] = useState(1);
  const name = localStorage.getItem("username");
  const navigate = useNavigate();
  const [loading, setLoading]=useState(true)
  const [pagination, setPagination]=useState({next:null})

  const [searchName, setSearchName] = useState("");
  const handleSearchName = (val) => {
    setSearchName(val);
  };

  const filteredName = searchName
    ? allHistoryData.filter((item) =>
        item?.shipping_address.name
          ?.toLowerCase()
          .includes(searchName.toLowerCase())
      )
    : allHistoryData;

  const converttoIst = (dateString) => {
    const date = new Date(dateString);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString("en-IN", options);
  };

  useEffect(() => {
    fetchAllHistoryData();
  }, [page, deliveryStatus]);

  const fetchAllHistoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/history/order/${deliveryStatus}?page=${page}`;
      const url = link + endpoint;

      const res = await api.get(url);
      if (res.status == 200) {
        setAllHistoryData(res.data.results);
        setPagination({next:res.data.next})
        setLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBackClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleForwardClick = () => {
    if(pagination.next){
      setPage(page + 1);
    }
  };

  const handleAdminLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("admin_id");
    alert("Logged out");
    navigate("/register");
  };

  const handleViewDetails = (id) => {};

   // Show loader while data is being fetched
   if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }
  
  return (
    <div>
      <div>
        {" "}
        <div className="ecom-dashboard-header">
          <div className="ecom-dashboard-search">
            <input
              className="ecom-dashboard-search-input"
              value={searchName}
              onChange={(e) => handleSearchName(e.target.value)}
              placeholder="Search Here..."
            />
            <CiSearch className="ecom-dashboard-search-icon" />
          </div>

          <div className="ecom-dashboard-user-details">
            <div>
              <b>Hello, {name}</b>
            </div>
            {/* <div className='ecom-dashboard-icon'><FaShoppingCart/></div> */}
            <div className="ecom-dashboard-icon">
              <IoMdNotifications />
            </div>
            <div
              className="ecom-dashboard-exit-icon"
              onClick={handleAdminLogOut}
            >
              <BiExit />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="ecom-orderhistory-newdiv">
          <div className="ecom-orderhistory-dropdown">
            <div>Delivery Status &nbsp;</div>
            <select onChange={(e) => setDeliveryStatus(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="delivered">Delivered</option>
              <option value='failed'>Failed</option>
              <option value='shipped'>Shipped</option>
              
            </select>
          </div>

          <div className="ecom-orderhistory-pagebtn">
            <button onClick={handleBackClick} disabled={page == 1}>
              <IoIosArrowBack />
            </button>
            <span>{page}</span>
            <button onClick={handleForwardClick}>
              <IoIosArrowForward disabled={!pagination.next}/>
            </button>
          </div>
        </div>

        <div className="ecom-allusers-left-details">
          <table className="user-table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Customer Name</th>
                <th>Date</th>
                <th>Payment Status</th>
                <th>Total</th>
                {/* <th>Items</th> */}
                {/* <th>Delivery</th> */}
                <th>Details</th>
              </tr>
            </thead>

            <tbody>
              {allHistoryData &&
                filteredName
                  .sort(
                    (a, b) =>
                      new Date(b.created_date) - new Date(a.created_date)
                  )
                  .map((item) =>{
                    // item?.order_items?.map((order) => {
                      return (
                        <tr>
                          <td>{item.id}</td>
                          <td>{item?.shipping_address?.name}</td>
                          <td>{converttoIst(item.created_date)}</td>
                          <td>{item.payment_status}</td>
                          <td>{item?.total_price}</td>
                          {/* <td>{order?.quantity}</td> */}
                          {/* <td>{item.delivery_status}</td> */}
                          <td>
                            {/* <button onClick={()=>handleViewDetails(item.razorpay_order_id)}>View</button> */}
                            <Link
                              to={{
                                pathname: `/admin/customerhistoryDetail/${item.id}`,
                                state: { item },
                              }}
                            >
                              <button className="ecom-alluser-view-details">
                                View
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default EcomOrderHistory;