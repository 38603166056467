import axios from 'axios';
import React, { useState } from 'react'
import './DashBoard.css'
import api from '../../Components/Registration/Api';

function CreateCoupon() {
    const [formData, setFormData] = useState({
        code: "",
        discount_value: "",
        expiration_date: "",
        is_active: false,
      });

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      };

      const handleSubmit = async (e) => {
        console.log(formData)
        e.preventDefault();
        try {
            const link=process.env.REACT_APP_BASE_URL
            const endpoint='/api/create/coupon'
            const url=link+endpoint

          const response = await api.post(url, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          alert("Coupon created successfully!");
          console.log(response.data);
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.code?.[0] || "Failed to create coupon.";
                alert(errorMessage);
              } else {
                alert("An unexpected error occurred.");
              }
              console.error("Error creating coupon:", error);
            
        }
      };


      const [couponData, setCouponData]=useState([])
      const [showModal, setShowModal] = useState(false);
      const [selectedCoupon, setSelectedCoupon] = useState(null);

      const handlegetCouponData=async()=>{
        try {
          const link=process.env.REACT_APP_BASE_URL
          const endpoint='/api/get/coupons'
          const url=link+endpoint
          const res=await api.get(url)
          if(res.status==200){
            setCouponData(res.data)
          }
          
        } catch (error) {
          console.error(error)
        }
      }

      const handleUpdateCoupon = (id) => {
        const coupon = couponData.find((item) => item.id === id);
        setSelectedCoupon({ ...coupon });
        setShowModal(true);
      };

      // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCoupon((prev) => ({ ...prev, [name]: value }));
  };

  // const handleSave = () => {
  //   setCouponData((prev) =>
  //     prev.map((item) =>
  //       item.id === selectedCoupon.id ? { ...selectedCoupon } : item
  //     )
  //   );
  //   setShowModal(false);
  // };
  const handleSave = async () => {
    // Create FormData object
    const formData = new FormData();
    formData.append("coupon_id", selectedCoupon.id);
    formData.append("discount_value", selectedCoupon.discount_value);
    formData.append("expiration_date", selectedCoupon.expiration_date);
    // formData.append(
    //   "applicable_finished_products",
    //   selectedCoupon.applicable_finished_products.join(", ")
    // );
    // formData.append(
    //   "applicable_raw_products",
    //   selectedCoupon.applicable_raw_products.join(", ")
    // );
    
    formData.append("is_active", selectedCoupon.is_active ? 1 : 0);
  
    try {
      // Call PUT API
      const url=`${process.env.REACT_APP_BASE_URL}/api/update/coupon`
      const response = await api.put(url, formData, {
        headers:{
          "Content-Type":'multipart/form-data'
        }
      })
  
      if (response.status==200) {
        const updatedData = response.data;
  
        // Update the state with the updated coupon
        setCouponData((prev) =>
          prev.map((item) =>
            item.id === updatedData.id ? { ...updatedData } : item
          )
        );
  
        // Close the modal
        setShowModal(false);
  
        alert("Coupon updated successfully!");
      }
    } catch (error) {
      console.error(error);
     
    }
  };
  
    

  return (
    <div>
     <div className='couponclasscreationdiv'>
     <h4>Create Coupon</h4>
      <form onSubmit={handleSubmit}>
        <div className='couponinputfield'>
            Code:&nbsp;
          <label>            
            <input
              type="text"
              name="code"
              value={formData.code}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
        Discount Value:&nbsp;
          <label>
            <input
              type="number"
              name="discount_value"
              placeholder='in %'
              value={formData.discount_value}
              onChange={(e) => {
                const value = e.target.value;
                if (value > 0 || value === "") {
                  handleChange(e); // Only update state if value is valid
                } else {
                  alert("Discount value must be greater than 0.");
                }
              }}
              required
            />
          </label>
        </div>
        <div>
        Expiration Date:&nbsp;
          <label>
            <input
              type="date"
              name="expiration_date"
              value={formData.expiration_date}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
        Is Active:&nbsp;
          <label>
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
            />
          </label>
        </div>
        <button type="submit" className='creetecoupon'>Create Coupon</button>
      </form>
     </div>


     <div className='coupongetclassdiv'>
          <button className='creetecoupon' onClick={handlegetCouponData}>Get All Coupons</button>
        {
          couponData.length>0 &&  <div  className="coupontable-container">
          <table className='couponresponsive-table'>
       <thead>
         <tr>
           <th>ID</th>
           <th>Code</th>
           <th>Discount (in %)</th>
           <th>Expiration Date</th>
           <th>Finished Products Id</th>
           <th>Raw Products Id</th>
           <th>Active Status</th>
           <th>Update Coupon</th>
         </tr>
       </thead>
       <tbody>
         {couponData.map((item) => (
           <tr key={item.id}>
             <td>{item.id}</td>
             <td>{item.code}</td>
             <td>{item.discount_value}</td>
             <td>{new Date(item.expiration_date).toLocaleString()}</td>
             <td>
               {item.applicable_finished_products.length > 0
                 ? item.applicable_finished_products.join(", ")
                 : "None"}
             </td>
             <td>
               {item.applicable_raw_products.length > 0
                 ? item.applicable_raw_products.join(", ")
                 : "None"}
             </td>
             <td>{item.is_active ? "Active" : "Inactive"}</td>
             <td><button className='update-coupon' onClick={(e)=>handleUpdateCoupon(item.id)}>Update</button></td>
           </tr>
         ))}
       </tbody>
     </table>
          </div>
        }
     </div>


     {showModal && (
        <div className="couponmodal-overlay">
          <div className="couponmodal">
            <h3>Update Coupon</h3>
            <div className="couponform-group">
              <label>Code:</label>
              <input
                type="text"
                name="code"
                disabled
                value={selectedCoupon.code}
                onChange={handleInputChange}
              />
            </div>
            <div className="couponform-group">
              <label>Discount (in %):</label>
              <input
                type="number"
                name="discount_value"
                value={selectedCoupon.discount_value}
                onChange={handleInputChange}
              />
            </div>
            <div className="couponform-group">
              <label>Expiration Date:</label>
              <input
                type="date"
                name="expiration_date"
                value={selectedCoupon.expiration_date}
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="couponform-group">
              <label>Finished Products ID (comma-separated):</label>
              <input
                type="text"
                name="applicable_finished_products"
                value={selectedCoupon.applicable_finished_products.join(", ")}
                onChange={(e) =>
                  setSelectedCoupon((prev) => ({
                    ...prev,
                    applicable_finished_products: e.target.value.split(", "),
                  }))
                }
              />
            </div> */}
            {/* <div className="couponform-group">
              <label>Raw Products ID (comma-separated):</label>
              <input
                type="text"
                name="applicable_raw_products"
                value={selectedCoupon.applicable_raw_products.join(", ")}
                onChange={(e) =>
                  setSelectedCoupon((prev) => ({
                    ...prev,
                    applicable_raw_products: e.target.value.split(", "),
                  }))
                }
              />
            </div> */}
            
            <div className="couponform-group">
              <label>Active Status:</label>
              <select
                name="is_active"
                value={selectedCoupon.is_active ? "true" : "false"}
                onChange={(e) =>
                  setSelectedCoupon((prev) => ({
                    ...prev,
                    is_active: e.target.value === "true",
                  }))
                }
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="couponmodal-actions">
              <button onClick={handleSave}>Save</button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateCoupon
