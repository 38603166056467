import React from 'react'
import './DashBoard.css'
import DashBoardHead from './DashBoardHead';
import { useNavigate } from 'react-router-dom';



function DashBoard() {
   
    const navigate=useNavigate()


  return (
    <div className='ecom-dashboard-main'>

        <div className='ecom-dashboard-container'>
           <DashBoardHead/>
          


           <div className='ecom-dashboard-content'>

            <div className='ecom-dashboard-date'>

              
              <p>From:&nbsp;&nbsp;<span>10/10/2024</span></p>  
                <p>To:&nbsp;&nbsp;<span>12/10/2024</span></p>
             

                <div className='ecom-create-coupondiv'>
                    <button onClick={()=>navigate('/admin/coupon')}>Create Coupon</button>
                </div>

            </div>

            <div className='ecom-dashboard-revenues'>
                <div className='ecom-dashboard-revenue-box'>
                    <p className='ecom-dashboard-top-left'>Total Revenue</p>
                    <p className='ecom-dashboard-bottom-right'> 16K</p>
                </div>
                <div className='ecom-dashboard-revenue-box'>
                    <p className='ecom-dashboard-top-left'>Total Orders</p>
                    <p className='ecom-dashboard-bottom-right'>1200</p>
                </div>
                <div className='ecom-dashboard-revenue-box'>
                    <p className='ecom-dashboard-top-left'>Total Users</p>
                    <p className='ecom-dashboard-bottom-right'>1.6K</p>
                </div>
                <div className='ecom-dashboard-revenue-box'>
                    <p className='ecom-dashboard-top-left'>Total Users</p>
                    <p className='ecom-dashboard-bottom-right'>1.6K</p>
                </div>

            </div>


            <div className='ecom-dashboard-analytics'>

                <div className='ecom-dashboard-analytics-graph'>
                    <p className='ecom-analytics-text'>Analytics</p>
                    <div className='ecom-dashboard-analytic-chart'>

                    </div>

                </div>
                <div className='ecom-dashboard-analytics-growth'>
                    <div className='ecom-analytics-para'>
                    <p className='ecom-analytics-text'>Growth</p>
                    <div className='ecom-analytics-growth'>
                        <div className='ecom-analytics-3text'>
                        <p>Last vs current month</p>
                        <p className='ecom-dashboard-percent-text'>+35%</p>
                        <p>+480 products</p>
                        </div>
                    </div>
                    </div>
                    <div className='ecom-analytics-para'>
                    <p className='ecom-analytics-text'>Members</p>
                    <div className='ecom-analytics-growth'>
                    <div className='ecom-analytics-3text'>
                    <p>Last vs current month</p>
                        <p className='ecom-dashboard-percent-text'>+35%</p>
                        <p>+480 products</p>
                    </div>
                    </div>
                    </div>
                </div>

            </div>



            <div className='ecom-dashboard-total-products'>
            <p className='ecom-analytics-text'>Total Products</p>
               <div className='ecom-dashboard-total-product'>
               <div className='ecom-dashboard-product-box'>
                <p>Total LEDs</p>
                <p className='ecom-dashboard-total-product-count'>45</p>
               </div>
                <div className='ecom-dashboard-product-box'>
                    <p >Simulators</p>
                    <p className='ecom-dashboard-total-product-count'>12</p>
                </div>
                <div className='ecom-dashboard-product-box'>
                    <p>Ice Makers</p>
                    <p className='ecom-dashboard-total-product-count'>11</p>
                </div>
               </div>
            </div>
           </div>
        </div>
    </div>
  )
}

export default DashBoard;
