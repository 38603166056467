import React, { useState } from 'react';
import api from '../../Components/Registration/Api';

const RatingAndReview = ({ productId, isFinishedProduct }) => {
  const [rating, setRating] = useState(0); // State to hold rating value
  const [review, setReview] = useState(''); // State to hold review text
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission

  const [errorMessage, setErrorMessage] = useState("");
  const [editMode, setEditMode] = useState(false); // To toggle edit mode

  const admin_id=localStorage.getItem('admin_id')

  // Function to handle star click
  const handleStarClick = (star) => {
    setRating(star); // Set the rating when a star is clicked
  };

  // Function to handle review input change
  const handleReviewChange = (e) => {
    setReview(e.target.value); // Update the review state as user types
  };

  // Function to handle the submit action
  const handleSubmit = async () => {
    // if(!admin_id){
    //     alert('You Need to be signIn')
    //     return
    // }
    if (rating === 0 || !review) {
      alert("Please provide a rating and a review before submitting.");
      return;
    }


    setIsSubmitting(true); // Disable the submit button while submitting

    const link=process.env.REACT_APP_BASE_URL


    const endpoint = isFinishedProduct
      ? '/api/finished/product/rating'
      : '/api/raw/product/rating';

    const url=link+endpoint

    const payload = {
      [isFinishedProduct ? 'finished_product_id' : 'raw_product_id']: productId,
      score: rating,
      review: review,
    };

  


    try {    
        const response=await api.post(url, payload, {
            headers:{
                "Content-Type":'application/json'
            }
        })

      if (response.status==201) {
        alert('Thank you for your rating and review!');
      } 
    } catch (error) {
      console.error('Error submitting rating:', error);
      // Check if the error response exists
  if (error?.response?.data) {
    const responseData = error.response.data;

    // Check for the 'detail' field
    if (responseData.detail) {
      alert(responseData.detail);
    }

    // Check for 'non_field_errors'
    else if (responseData.non_field_errors?.length > 0) {
      if (responseData.non_field_errors[0] === "The fields finishedProduct, user must make a unique set.") {
        setErrorMessage("You have already rated this product.");
        alert("You have already rated this product.");
        setEditMode(true); // Enable edit mode
      }
    }
}
    }
    setIsSubmitting(false); // Re-enable the submit button
  };



  const handleEditReview=async()=>{
    if (rating == 0 || !review) {
        alert("Please provide a rating and a review before submitting.");
        return;
      }
   
      const link=process.env.REACT_APP_BASE_URL
  
      const endpoint = isFinishedProduct
        ? '/api/finished/product/rating'
        : '/api/raw/product/rating';
  
      const url=link+endpoint
  
      const payload = {
        [isFinishedProduct ? 'finished_product_id' : 'raw_product_id']: productId,
        score: rating,
        review: review,
      };
  
      try {    
          const response=await api.put(url, payload, {
              headers:{
                  "Content-Type":'application/json'
              }
          })
  
        if (response.status==201) {
          alert('Thank you for your rating and review!');
        } 
        
    } catch (error) {
        
    }
  }


  // Function to render stars
  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleStarClick(i)}
          style={{
            cursor: 'pointer',
            color: i <= rating ? '#FFD700' : '#ccc',
            fontSize: '30px',
          }}
        >
          ★
        </span>
      );
    }
    return stars;
  };


  return (
    <div className='ratingandreview-conatiner'>
      <h5>Rate and Review the Product</h5>
      <div>{renderStars()}</div>
      <textarea
        placeholder="Write your review here..."
        value={review}
        onChange={handleReviewChange}
        rows="4"
        // style={{ width: '60%', marginTop: '10px' }}
      />
      <br />
      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        style={{ marginTop: '10px', padding: '10px', backgroundColor: '#4CAF50', color: 'white' }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit Review'}
      </button>
      {errorMessage && !editMode ? (
        <p className="error-message">{errorMessage}</p>
      ) : null}
      {editMode && <button
      style={{ marginTop: '10px', marginLeft:'10px', padding: '10px', backgroundColor: '#4CAF50', color: 'white' }}
      onClick={handleEditReview}
      >Edit Review</button>}
    </div>
  );
};

export default RatingAndReview;