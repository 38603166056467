import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../../Components/Registration/Api';
import DashBoardHead from '../../DashBoard/DashBoardHead';
import { Link } from 'react-router-dom';


function EcomAddAllCategory() {

  
    const navigate=useNavigate()
    const admin_id=localStorage.getItem('admin_id')


    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState(true)

    
  
    const handleAddCategory = async () => {       
      const link = process.env.REACT_APP_BASE_URL;
      const updatedDate = new Date().toISOString();

      const updatedProduct = {
        category_name: name,
        category_description: description,
        Productcategory_active_status: activeStatus,
        created_date: updatedDate,
        updated_date: updatedDate,
        created_by: admin_id,
        updated_by: admin_id,
      };
  
     
      const response = await api.post(`${link}/api/createproductcategory`, 
        updatedProduct,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
  
      const data = response.data || response;
      console.log(data); 

      if (response.status==201 ) {
        alert('Added Product Category successfully!');
      } else {
        alert('Failed to add category.');
      }
    };
  
  
    return (
      <div>
        <div className="ecom-allusers-container">
         <DashBoardHead/>
  
          <div className='ecom-product-detail-main-section'>
              <div><Link to='/admin/allcategory'>Back</Link></div>
          <div className='ecom-product-detail-container'>
  
  
  <div className="ecom-product-detail-section">
      <div className="ecom-product-left-detail-section">
       
     <div className='ecom-subcategory-activestate'>
     <p>Active Status: </p>
     <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="true"
                    checked={activeStatus === true}
                    onChange={() => setActiveStatus(true)}
                  />
                  True
                </label>
                <label>
                  <input
                    type="radio"
                    name="activeStatus"
                    value="false"
                    checked={activeStatus === false}
                    onChange={() => setActiveStatus(false)}
                  />
                  False
                </label>
     </div>
            
          <p>Name: <input type="text" placeholder="Street Light 2b" value={name} onChange={(e) => setName(e.target.value)} /></p>  
               
      </div>
  </div>
  
  
  <div className='ecom-product-detail-desc-section'>
  <p>Description:</p>
  <div className='ecom-product-detail-desc-text'>
  <textarea
                placeholder="Category Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                    resize: 'none', // Optional: Remove resize handle
                  }}
              />
  </div>
  </div>
  
  
  <div className='ecom-product-detail-btns'>
      <button className='ecom-product-detail-cancel-btn' onClick={() => navigate('/admin/allcategory')}>Cancel</button>
      <button className='ecom-product-detail-save-btn' onClick={handleAddCategory}>Save</button>
  </div>
          </div>
          </div>
          </div>
      </div>
    )
  }

export default EcomAddAllCategory;
