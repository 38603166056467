import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './ProductDetail.css';

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [isZooming, setIsZooming] = useState(false);
  const imageContainerRef = useRef(null);
  const zoomedImageRef = useRef(null);


  const [cartQuantity, setCartQuantity] = useState(0);

  const originalPrice=500
  const discountedPrice=300
  const hasDiscount = originalPrice > discountedPrice;

   // Add to Cart functionality
   const handleAddToCart = () => {
    setCartQuantity(1); // Initially, set to 1 when "Add to Cart" is clicked
  };

  
  // Increase quantity
  const handleIncreaseQuantity = () => {
    setCartQuantity(prevQuantity => prevQuantity + 1);
  };

  // Decrease quantity
  const handleDecreaseQuantity = () => {
    if (cartQuantity > 1) {
      setCartQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  // Delete item from cart
  const handleDeleteItem = () => {
    setCartQuantity(0); // Set quantity back to 0
  };
  // const subtotal = product.price * cartQuantity;

  useEffect(() => {
    fetch(`https://fakestoreapi.com/products/${id}`)
      .then((response) => response.json())
      .then((data) => setProduct(data))
      .catch((error) => console.log(error));
  }, [id]);

  const handleMouseMove = (e) => {
    const imageContainer = imageContainerRef.current;
    const zoomedImage = zoomedImageRef.current;
    if (!imageContainer || !zoomedImage) return;

    const rect = imageContainer.getBoundingClientRect();
    const x = e.clientX - rect.left; // Get X coordinate inside container
    const y = e.clientY - rect.top;  // Get Y coordinate inside container

    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    zoomedImage.style.transformOrigin = `${xPercent}% ${yPercent}%`;
  };

  const handleMouseEnter = () => {
    setIsZooming(true);
  };

  const handleMouseLeave = () => {
    setIsZooming(false);
  };


  if (!product) {
    return <div>Loading...</div>;
  }


  return (
    <div className="ecomproductDetailContainer">
      <div className="ecomproductDetailImage">
      <div
        className="productImage zoom-container"
        ref={imageContainerRef}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={product.image}
          alt={product.title}
          ref={zoomedImageRef}
          style={{ transform: isZooming ? 'scale(2)' : 'scale(1)' }}
        />
      </div>
      </div>
      <div className="ecomproductDetailInfo">
        <h1>{product.title}</h1>
        <div className="reviews">⭐⭐⭐⭐⭐ </div>
        <p>{product.description}</p>

        {/* <p className="ecomproductDetailPrice">${product.price}</p> */}
         {/* Price Display */}
         <div className="ecomproductDetailPrice">
          {hasDiscount ? (
            <>
              <span className="ecomoriginalPrice">${originalPrice}</span>
              <span className="ecomdiscountedPrice">${discountedPrice}</span>
            </>
          ) : (
            <span>${product.price}</span>
          )}
        </div>



        <div className="ecomproductDetailActions">
           {/* Add to Cart functionality */}
        {cartQuantity === 0 ? (
          <button className="ecomaddToCartButton" onClick={handleAddToCart}>
            Add to Cart
          </button>
        ) : (
          <div className="ecomcartControls">
            <button className="ecomcartButton" onClick={handleDecreaseQuantity}>-</button>
            <span className="ecomcartQuantity">{cartQuantity}</span>
            <button className="ecomcartButton" onClick={handleIncreaseQuantity}>+</button>
            <button className="ecomdeleteButton" onClick={handleDeleteItem}>Delete</button>
          </div>
        )}

        {/* Display Subtotal if item is in the cart */}
        {cartQuantity > 0 && (
          <div className="ecomcartSubtotal">
            {/* <p>Subtotal: ${subtotal.toFixed(2)}</p> */}
            Total:
          </div>
        )}
          <button className="ecombuyNowButton">Buy Now</button>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;

