import React, { useEffect, useLayoutEffect } from 'react'
import { useState } from 'react';
import './Summary.css'
import { FaCaravan } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import api from '../../Components/Registration/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Summary() {
  const { error, isLoading, Razorpay } = useRazorpay();

  const location=useLocation()
  const {currentStep,priceDetailData ,paymentMethod ,selectedAddressId}= location.state || {};
    const [quantity, setQuantity] = useState(1);
    const {delAddress} = useAuth()
    const admin_id=localStorage.getItem('admin_id')
    const [address, setAddress]=useState(null)
    const navigate=useNavigate()
  
    
      // Handle Quantity Change
      const increaseQuantity = () => setQuantity(quantity + 1);
      const decreaseQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);
    
      // Handle Product Deletion
      const deleteProduct = () => {
        console.log("Product deleted");
      };
      const Place_order=()=>{
        console.log("ORDER PLACED")
      }

      function getDateAfter7Days() {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 7); // Add 7 days to the current date
        return currentDate.toISOString().split('T')[0]; // Format to "YYYY-MM-DD"
      }


      const [cartItems, setCartItems]=useState([])
      const [cartLength, setCartLength]=useState(null)
      

      useEffect(() => {    
        fetchCartData();
        getUserSelectedAddress()
      }, []);

      const fetchCartData = async () => {
       try{
        const link = process.env.REACT_APP_BASE_URL;
        const endpoint = `/api/user/cart/${admin_id}`;
        const res = await api.get(`${link + endpoint}`);
  
        if (res.status == 200) {
          setCartItems(res.data.cart_items);            
          setCartLength(res.data.cart_items.length)
        }
       }catch(err){
        console.error(err)
       }
      };


      const getUserSelectedAddress=async()=>{
        try{
          const link=process.env.REACT_APP_BASE_URL
          const endpoint=`/api/user/get/deliveryaddress/${selectedAddressId}`
          const url=link+endpoint;
      
          const res=await api.get(url)
          
          if(res.status=200){
            setAddress(res.data)
          }      
        }
        catch(err){
          console.error(err)
        }
      }



      const handleRazorPayment=async()=>{

        try {
          const link=process.env.REACT_APP_BASE_URL
        const endpoint='/api/user/initialize/order'
        const url=link+endpoint

        const data={
            amount:priceDetailData?.[2],  //frontend-rupee  , backend-paise
            currency:'INR'
        }

          const res=await api.post(url, data, {
            headers:{
              'Content-Type':'application/json'
            }
          })

          if(res.status==201){
              const order_id=res.data.data.id
              handlePayment(order_id)
          }
        } catch (error) {
          console.error(error)
        }
      }


      const handlePayment = (id) => {
        const keyS=process.env.REACT_APP_RAZORPAYKEY

        const options: RazorpayOrderOptions = {
         
          key: keyS,
          amount: priceDetailData?.[2], // Amount in paise
          currency: "INR",
          name: "Cellus Tech India Pvt. Ltd.",
          description: "Test Transaction",
          order_id: id, // Generate order_id on server
          handler: (response) => {
            console.log(response);
            completePayment(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
            alert("Payment Successful ! Thank You for shopping with us. Continue Shopping");
            navigate('/ecom')            
          },
          prefill: {
            name: "John Doe",
            email: "john.doe@example.com",
            contact: "",
          },
          theme: {
            color: "#F37254",
          },
        };
    
        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
      };


      const completePayment=async(razorpay_payment_id,razorpay_order_id,razorpay_signature)=>{
        try {
          const link=process.env.REACT_APP_BASE_URL
         
        const endpoint='/api/user/create/order';
        const url=link+endpoint;

        const data={
          payment_id: razorpay_payment_id,
    razorpay_order_id: razorpay_order_id,
    shipping_address: selectedAddressId,
    razorpay_signature: razorpay_signature,
    amount:priceDetailData?.[2],
    customer: admin_id
        }
        const res=await api.post(url, data, {
          headers:{
            'Content-Type':'application/json'
          }
        })
        } catch (error) {
          console.error(error)
        }
      }


      const handleCashPayment=async()=>{
        try {
          const link=process.env.REACT_APP_BASE_URL
          const endpoint='/api/user/create/cashondelivery/order'
          const url=link+endpoint

          const payload={
            shipping_address:selectedAddressId,
            amount:priceDetailData?.[2],
            customer:admin_id
          }

          const res=await api.post(url, payload, {
            headers:{
              "Content-Type":'application/json'
            }
          })

          if(res.status==201){
            alert('ThankYou! Your order has been placed and will be delivered within a week....')
            navigate('/ecom')
          }
          
        } catch (error) {
          console.error(error)
        }
      }

      
  return (
   <>
    <div className='summary_pg_customer'>
   {/* Step Navigation */}
   <div className="step-navigation_customer">
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 1 ? "completed_customer" : ""
            }`}
          >
            1
          </div>
          <p className="step-label_customer">Cart</p>
        </div>
        <div className={`line_customer ${currentStep >= 2 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 2 ? "completed_customer" : ""
            }`}
          >
            2
          </div>
          <p className="step-label_customer">Address</p>
        </div>
        <div className={`line_customer ${currentStep >= 3 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 3 ? "completed_customer" : ""
            }`}
          >
            3
          </div>
          <p className="step-label_customer">Payment</p>
        </div>
        <div className={`line_customer ${currentStep >= 4 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep === 4 ? "completed_customer" : ""
            }`}
          >
            4
          </div>
          <p className="step-label_customer">Summary</p>
        </div>
      </div>


<div className='main_summary_div_customer'>
{/* left side div */}
<div className="summary_left_side_customer">
  
    <div className="product_head_summary_customer">
      <h4>Product Details</h4>
      <div className='edit_btn_customer'>
      <h5 onClick={()=>navigate('/useraddtocart')}>EDIT DETAILS</h5>
            </div>
      
    </div>
    <div className='estimated_delivery_customer'>
        <p> <FaCaravan className="delivery-icon_customer" /> Estimated Delivery By Wednesday, {getDateAfter7Days()}</p>
    </div>

    <div className='summary-product-section_customer'>
      {
        cartItems.length==0 && <p>No Items Found</p>
      }

      {
        cartItems && cartItems.map((cart,idx)=>{
          return(
            <div className="cart-content_summary_customer">
            {/* Product Details */}
            <div className="product-details_customer">
              <div className="product-left_customer">
                {/* Product Image and Info */}
                <div className="summary-image__customer">
                  <img src={cart?.product_details?.product_image_url.split(",")[0]} alt="Product_customer" />
                </div>
                <div className="product-info_customer">
                  <h3>{cart?.product_details?.product_name}</h3>
                  <p>Price: {cart?.product_price}  (Qty:{cart?.quantity})</p>
                  
                </div>
              </div>
      
              {/* Product Actions */}
            <div className='edit_btn_customer'>
              {/* <h5>EDIT</h5> */}
            </div>
            </div>
          </div>
          )
        })
      }
    </div>
   

  <div className='Delivery_address_customer'>
  <div className="product_head_customer">
      <h4>Delivery Address</h4>
    </div>
    <div className='delivery_adress_content_customer'>
        <div className='name_edit_btn_customer'>
   <p>{address?.name}</p>
   {/* <h5 onClick={()=>navigate('/deliveryaddress')}>EDIT</h5> */}
        </div>
        <p>{address?.phone_number}</p>
        <p>{address?.address} {address?.locality} {address?.city}, {address?.state}, {address?.pincode}</p>
    </div>


    <div className='paymet_content_customer'>
    <div className="product_head_customer">
      <h4>Payment Mode</h4>
      
    </div>
    <div className='payment_mode_content_customer'>
        {
          paymentMethod=='cash' ? <p>Cash On Delivery</p> : <p>NetBanking</p>
        }
      {/* <h5 onClick={()=>navigate('/paymentMethod')}>EDIT</h5> */}
    </div>
    </div>

  </div>
  </div>

  {/* right side */}
  <div className="summary_right_side_customer">
  <div className="price_detail_container_customer">
      <div className="pice_detail_customer">
        <h4>Price Detail ({cartLength} Items)</h4>
        <div>
          <div className="total_price_customer">
            <p>Total product price</p>
            <p>&#8377;{priceDetailData?.[0]}</p>
          </div>
          <div className="total_discount_customer">
            <p>Total Discount</p>
            <p>&#8377;{priceDetailData?.[1]}</p>
          </div>
        </div>
      </div>

      <hr />
      <div className="order-total_customer">
        <h4>Order Total</h4>
        <p>&#8377;{priceDetailData?.[2]}</p>
      </div>
      {priceDetailData?.[1] > 0 && (
  <div className="total_discount_msg_customer">
    <p>🎉 Yah, your total discount is {priceDetailData?.[1]} rs</p>
  </div>
)}
      <div className="continue_customer">
        <button onClick={()=>{
          if(paymentMethod && paymentMethod=='card'){
            handleRazorPayment()
          }else if(paymentMethod && paymentMethod=='cash'){
            handleCashPayment()
          }

          }}>Place Order</button>
      </div>
    </div>
  </div>
    </div>
    </div>
   </>
  )
}




export default Summary;