import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../Components/Registration/Api';
import './BuySingleItem.css'
import { useAuth } from '../../AuthContext';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";


function BuySingleItem() {

  const admin_id=localStorage.getItem('admin_id')
  // const [cart, setCart]=useState(null)
  const {buySingleId}=useParams()
  // const [totalPrice, setTotalPrice]=useState(null)
  const [qty, setQty]=useState(1)
  const [totalProductPrice, setTotalProductPrice] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const [showModal, setShowModal]=useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const { error, isLoading, Razorpay } = useRazorpay();

  const location=useLocation()
  const cart=location.state

 

  const handleContinueClick = () => {
    if(addresses.length==0){
      alert('Add Address')
      return;
    }
    setShowModal(true); // Show the modal
};

const handlePaymentSelection = (method) => {
    setSelectedPaymentMethod(method);
    placeOrder(method); // Call place order logic
};

const placeOrder = (method) => {
    // Simulate order placement logic
    console.log(`Order placed using: ${method}`);
    setShowModal(false); // Close modal
    alert(`Your order has been placed using ${method}!`);
};


const [paymentMethod, setPaymentMethod]=useState('cash')
const handlePaymentMethod=(mode)=>{
  setPaymentMethod(mode)
}





  const navigate=useNavigate()
  useEffect(() => {
    if (cart?.product_fixed_price) {
      setTotalProductPrice((cart?.product_fixed_price));
      setActualTotalPrice(cart?.product_fixed_price)
    }
  }, [cart]);
  

  const handleQty=(val)=>{
    const numericValue = Math.max(1, Number(val) || 1); 
    setQty(numericValue)
    updateQty(numericValue)
  }


  const updateQty = async (newQuantity) => {  
    const link = process.env.REACT_APP_BASE_URL;
    // const url = `${link}/api/user/updatecartitem`;
  
    // // Find the item in carts
    // const cart = carts.find((item) => item.id === id);
    // if (!cart) return;
  
    let updatedProductPrice;
  
    if (newQuantity <= 5) {
      // Use fixed price when quantity <= 5
      const productFixedPrice = parseFloat(cart.product_fixed_price || 0);
      updatedProductPrice = (productFixedPrice * newQuantity).toFixed(2);
    } else {
      // Determine price tier for quantity > 5
      let priceKey;
      if (newQuantity > 5 && newQuantity <= 10) {
        priceKey = "q1_price";
      } else if (newQuantity > 10 && newQuantity <= 50) {
        priceKey = "q2_price";
      } else if (newQuantity > 50 && newQuantity <= 100) {
        priceKey = "q3_price";
      } else if (newQuantity > 100 && newQuantity<=500) {
        priceKey = "q4_price";
      }
      else if(newQuantity>500){
        priceKey="q5_price"
      }
  
      // Calculate price using tiered price
      const tieredPricePerUnit = parseFloat(cart[priceKey] || 0);
      updatedProductPrice = (tieredPricePerUnit * newQuantity).toFixed(2);
    }
  
    setTotalProductPrice(updatedProductPrice)
   // If quantity is unchanged, keep the original price
  // if (newQuantity == cart.quantity) {
  //   updatedProductPrice = cart.product_price;
  // }

  // Apply discount on the updated product price
  calculateDiscountedPrice(parseFloat(updatedProductPrice));

  //  console.log('pricess', newQuantity, updatedProductPrice)

  };


    // Function to calculate the discounted price
    const calculateDiscountedPrice = (productPrice) => {
      let discountPercentage = 0;
  
      if (productPrice > 5000) {
        discountPercentage = 10;  // 10% discount for products priced above 2000
      } else if (productPrice >= 1000 && productPrice <= 2000) {
        discountPercentage = 5;   // 5% discount for products priced between 1000 and 2000
      }
  
      const discountAmount = (productPrice * discountPercentage) / 100;
      const discountedPrice = (productPrice - discountAmount).toFixed(2);
  
      setTotalDiscount(discountAmount)
      setActualTotalPrice(discountedPrice)
      // return {
      //   discountedPrice: parseFloat(discountedPrice),
      //   discountAmount: parseFloat(discountAmount),
      // };
    };
  
  
  
    

  useEffect(() => {
     
  }, []);

  const increaseQuantity=()=>{
    handleQty(qty+1)
  }

  const decreaseQuantity=()=>{
   handleQty(qty-1)
  }



  const priceDetailData=[
    totalProductPrice,totalDiscount, actualTotalPrice
 ]
 
   const handleDelivery = () => {
     window.scrollTo({ top: 0, behavior: 'smooth' });
    //  navigate("/deliveryaddress", { state: { currentStep: 2 , priceDetailData:priceDetailData} });
   };



  //  delivery address
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null); //id of selected object from array
  const [errorMessage, setErrorMessage] = useState('');
  const [addresses, setAddresses] = useState([])  //array of all data
  const [clickeditbtn, setClickEditBtn]=useState(false)
  const {setDelAddress}=useAuth()


  useEffect(()=>{
    getDeliveryAddressData()
  },[])

  const getDeliveryAddressData=async()=>{
    try {
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/user/deliveryaddress'
      const url=link+endpoint
        const res=await api.get(`${url}`)
  
        if(res.status==200){
          console.log(res,"del")
          // setDeliveryAddress(res.data)
          setAddresses(res.data)
          if (res.data.length > 0) {
            setSelectedAddressId(res.data[0].id); // the address has an id
        }
        }
    } catch (error) {
      
    }
   
  }


  const [editingAddress, setEditingAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
      name: '',
      phone_number:'',
      email: '',
      address: '',
      locality: "",
      city: '',
      state: '',
      pincode: '',
      type_of_address: 'home'
  });


  const handleEdit = async(curraddress, id) => {
      // setEditingAddress(curraddress);
      setNewAddress(curraddress);
      setShowAddressForm(true);
setClickEditBtn(true)
      setSelectedAddressId(id)
  };


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === 'phone_number') {
        // Allow only numbers and limit to 10 digits
        const validNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
        if (validNumber.length <= 10) {
            setNewAddress((prev) => ({ ...prev, [name]: validNumber }));
        }
    } else {
        setNewAddress((prev) => ({ ...prev, [name]: value }));
    }
  };


  const saveAddress = async() => {
      // Check if any required fields are empty
      if (!newAddress.name || !newAddress.phone_number || !newAddress.pincode || !newAddress.locality || 
          !newAddress.city || !newAddress.state || !newAddress.address || !newAddress.email) {
          setErrorMessage('All fields are required');
          return;
      }

      if (newAddress.phone_number.length !== 10) {
        alert('Mobile number must be exactly 10 digits!');
        return;
    }


      // if (editingAddress) {
      //    setAddresses((prev) =>
      //         prev.map((address) =>
      //             address.id === editingAddress.id ? { ...address, ...newAddress } : address
      //         )
      //     );
      // } else {
      //     setAddresses((prev) => [newAddress, ...prev]);  // Add new address at the top
      // }  
try {
  

      const link=process.env.REACT_APP_BASE_URL
      const endpoint=`/api/user/deliveryaddress`
      const url=link+endpoint

      const address={
        name: newAddress.name || addresses.name,
        phone_number:newAddress.phone_number || addresses.phone_number,
        email: newAddress.email || addresses.email,
        address: newAddress.address || addresses.address,
        locality: newAddress.locality || addresses.locality,
        city: newAddress.city || addresses.city,
        state: newAddress.state || addresses.state,
        pincode: newAddress.pincode || addresses.pincode,
        type_of_address: newAddress.type_of_address || addresses.type_of_address
      }

      console.log("curr and ups", address)
      const response = await api.post(`${url}`, 
        address,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }          
      );

      if(response.status==200){
      }
      
      getDeliveryAddressData()

      setShowAddressForm(false);

      setEditingAddress(null);
      setNewAddress({
      name: '',
      phone_number:'',
      email: '',
      address: '',
      locality: "",
      city: '',
      state: '',
      pincode: '',
      type_of_address: 'home'
      });
      setErrorMessage('');
    } catch (error) {
  
    }
  };


const getSelectedAddress=()=>{
const res=addresses.find((add)=>add.id===selectedAddressId)
return res
}

  const handleEditAddress=async()=>{
    const link=process.env.REACT_APP_BASE_URL
    const endpoint=`/api/user/deliveryaddress/${selectedAddressId}`
    const url=link+endpoint

    const address={
      name: newAddress.name || addresses.name,
      phone_number:newAddress.phone_number || addresses.phone_number,
      email: newAddress.email || addresses.email,
      address: newAddress.address || addresses.address,
      locality: newAddress.locality || addresses.locality,
      city: newAddress.city || addresses.city,
      state: newAddress.state || addresses.state,
      pincode: newAddress.pincode || addresses.pincode,
      type_of_address: newAddress.type_of_address || addresses.type_of_address
    }

    console.log("curr and ups", address)
    const response = await api.put(`${url}`, 
      address,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }          
    );

    if(response.status==200){
      setShowAddressForm(false)
      getDeliveryAddressData()
    }
  }


  const handleCancel = () => {
    setClickEditBtn(false);
      setShowAddressForm(false);
      setEditingAddress(null);
      setNewAddress({
        name: '',
        phone_number:'',
        email: '',
        address: '',
        locality: "",
        city: '',
        state: '',
        pincode: '',
        type_of_address: 'home'
      });
      setErrorMessage('');
  };



  const handleAddressSelect = (addressId) => {
      if (selectedAddressId === addressId) {
          setSelectedAddressId(null);  // Deselect the address if it's already selected
      } else {
          setSelectedAddressId(addressId);  // Select the new address
      }
  };

  const handleDelete = async (addressId) => {
    const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `${link}/api/user/deliveryaddress/${addressId}`;
    const res = await api.delete(endpoint);


    if (res.status == 204) {
        // Remove the deleted address from the state
        // setAddresses((prev) => prev.filter((address) => address.id !== addressId));
    getDeliveryAddressData()

    }
};

  function deliver(){
    if (!selectedAddressId) {
      alert("Please select an address.");
      return;
  }

  const deliveryAddress=getSelectedAddress()
      setDelAddress(deliveryAddress)
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // navigate('/paymentMethod' , { state: { currentStep: 3, priceDetailData, selectedAddressId } })
  }

  
// payment status
const handlePlaceOrder=()=>{
  
  if(paymentMethod=='card'){
    handleRazorPayment()
  }
}

const handleRazorPayment=async()=>{
  try {
    const link=process.env.REACT_APP_BASE_URL
  const endpoint='/api/user/initialize/order'
  const url=link+endpoint

  const price=discountCoupon ? discountCoupon.discounted_price : actualTotalPrice

  const data={
      amount:price,  //frontend-rupee  , backend-paise
      currency:'INR'
  }

    const res=await api.post(url, data, {
      headers:{
        'Content-Type':'application/json'
      }
    })

    if(res.status==201){
        const order_id=res.data.data.id
        handlePayment(order_id)
    }
  } catch (error) {
    console.error(error)
  }
}


const handlePayment = (id) => {
  const keyS=process.env.REACT_APP_RAZORPAYKEY

  const options: RazorpayOrderOptions = {
   
    key: keyS,
    amount: actualTotalPrice, // Amount in paise
    currency: "INR",
    name: "Cellus Tech India Pvt. Ltd.",
    description: "Test Transaction",
    order_id: id, // Generate order_id on server
    handler: (response) => {
      console.log(response);
      completePayment(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
      alert("Payment Successful ! Thank You for shopping with us. Continue Shopping");
      navigate('/ecom')            
    },
    prefill: {
      name: "John Doe",
      email: "john.doe@example.com",
      contact: "22222222",
    },
    theme: {
      color: "#F37254",
    },
  };

  const razorpayInstance = new Razorpay(options);
  razorpayInstance.open();
};


const completePayment=async(razorpay_payment_id,razorpay_order_id,razorpay_signature)=>{
  try {
    const link=process.env.REACT_APP_BASE_URL
   
  const endpoint='/api/user/create/one/order';
  const url=link+endpoint;


  const data={
    payment_id: razorpay_payment_id,
razorpay_order_id: razorpay_order_id,
shipping_address: selectedAddressId,
razorpay_signature: razorpay_signature,
amount:actualTotalPrice,
customer: admin_id,
quantity:qty,
product_price:cart.product_fixed_price
  }

  if (cart.details) {
    data.raw_product_id = cart.id; // Send raw_material_id if details are present
    data.finished_product_id = null 

  } else {
    data.finished_product_id = cart.id; // Otherwise, send finished_material_id
    data.raw_product_id=null;
  }

  console.log(data)
  const res=await api.post(url, data, {
    headers:{
      'Content-Type':'application/json'
    }
  })
  } catch (error) {
    console.error(error)
  }
}

const [code, setCode]=useState('')
const [discountCoupon, setDiscountCoupon]=useState(null)

const handleApplyCode=async()=>{
  if(!code){
    alert('Enter Code')
    return
  }
  try {

    const formData=new FormData()
    formData.append('code', code)
    formData.append('total_amount', parseInt(actualTotalPrice, 10))

    const link=process.env.REACT_APP_BASE_URL
    const endpoint='/api/user/apply/coupon'
    const url=link+endpoint
    const res=await api.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    if(res.status==200){
      setDiscountCoupon(res.data)
    }
    console.log(res)
    
  } catch (error) {
    console.error(error)
  }
}

console.log(discountCoupon)


  return (

    <div className='buysingleItem_customer'>
<div className="singleproduct_left_side_customer">      

<div className='buysingleItem__customer'>
<div className="singlecart-content_customer" 
key={cart?.id}
>
                  
                  
                  <div className="product-details_customer">
                    <div className="product-left_customer">
                      <div className="product-image_customer">
                        <img
                          src={cart?.product_image_url.split(",")[0]}
                          alt="Product Image"
                        />
                      </div>
                      <div className="product-info_customer">
                        <h3>{cart?.product_name} {cart?.details}</h3>
                        <p>{cart?.product_description}</p>
                        <p style={{fontWeight:'bold', fontSize:'15px'}}>&#8377;{cart?.product_fixed_price}</p>
                      </div>
                    </div>

                    <div className="product-actions_customer">
                      <div>
                        {" "}
                        <span
                          className="delete_customer"
                          // onClick={()=>deleteProduct(cart.id)}
                        >
                          X
                        </span>
                      </div>

                      <div className="quantity-counter_customer">
                        <button className="minus_customer" 
                        disabled={qty<=1}
                          onClick={()=>decreaseQuantity()}
                          >
                            - </button>
                        <input
                          type="number"
                          style={{width:'5rem', textAlign:'center'}}
                          className="quantity-input_customer"
                          min="1"
                          value={qty}
                          onChange={(e)=>handleQty(e.target.value)}
                          
                        />
                        <button className="plus_customer"
                         onClick={()=>increaseQuantity()}
                         >+</button>
                      </div>
                    </div>
                  </div>
                  
                  {
                   <div className="singleproduct-left-discount_customer">
                    <p>(5+ units:&nbsp;<span>{cart?.q1_price}</span>)</p>
                    <p>(10+ units:&nbsp;<span>{cart?.q2_price}</span>)</p>
                    <p>(50+ units:&nbsp;<span>{cart?.q3_price}</span>)</p>
                    <p>(100+ units:&nbsp;<span>{cart?.q4_price}</span>)</p>
                    <p>(500+ units:&nbsp;<span>{cart?.q5_price}</span>)</p>
                                        </div>
                  }
                </div>


              
             <div>
             <div className="select_address_customer">
                            <h4>Select Delivery Address</h4>
                            <h5 onClick={() => setShowAddressForm(true)}>+ ADD NEW ADDRESS</h5>
                        </div>

                <div className='leftbuysingleitem_side_customer'>

                        {showAddressForm && (
                            <div className='address_form_customer'>
                                {/* Error message display */}
                                {errorMessage && <div className="error_message_customer">{errorMessage}</div>}
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Name' 
                                        className='input_field_customer'
                                        value={newAddress.name}
                                        onChange={handleInputChange}
                                        name="name"
                                    />
                                    <input 
                                        type='Number' 
                                        placeholder='Phone Number' 
                                        className='input_field_customer'
                                        value={newAddress.phone_number}
                                        onChange={handleInputChange}
                                        name="phone_number"
                                    />
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email"
                                        value={newAddress.email} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Pincode' 
                                        className='input_field_customer'
                                        value={newAddress.pincode}
                                        onChange={handleInputChange}
                                        name="pincode"
                                    />
                                    <input 
                                        type='text' 
                                        placeholder='Locality' 
                                        className='input_field_customer'
                                        value={newAddress.locality}
                                        onChange={handleInputChange}
                                        name="locality"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <textarea 
                                        placeholder='Address' 
                                        className='address_input_customer'
                                        value={newAddress.address}
                                        onChange={handleInputChange}
                                        name="address"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='City' 
                                        className='input_field_customer'
                                        value={newAddress.city}
                                        onChange={handleInputChange}
                                        name="city"
                                    />
                                    
                                    <input
                                    type='text'
                                    placeholder='State'
                                    className='input_field_customer'
                                    value={newAddress.state}
                                    onChange={handleInputChange}
                                    name='state'
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='home' 
                                            checked={newAddress.type_of_address=='home'}
                                            onChange={handleInputChange}                                            
                                        /> Home
                                    </label>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='work' 
                                            checked={newAddress.type_of_address=='work'}
                                            onChange={handleInputChange}
                                            
                                        /> Work
                                    </label>
                                </div>
                                <div className='form_row_customer'>
                                   
                                   {
                                    clickeditbtn ?<button className='cancel_text_customer' onClick={handleEditAddress}>Update</button>:
                                    <button className='save_btn_customer' onClick={saveAddress}>Save</button>
                                   }
                                    <span className='cancel_text_customer' onClick={handleCancel}>Cancel</span>
                                </div>
                            </div>
                        )}


                        {addresses.map((address) => (
                            <div
                            //  className='address_content_customer' 
                            className={`address_item_customer ${selectedAddressId === address.id ? 'selected' : ''}`}
                            key={address.id}>
                                <div className='name_edit_customer'>
                                    <p> <label>
                                        <input 
                                            type="radio" 
                                            name="selectedAddress" 
                                            checked={selectedAddressId === address.id}
                                            onChange={() => handleAddressSelect(address.id)} 
                                            className='radio_select_address'
                                        />
                                       
                                    </label>{address.name}</p>
                                    <h5 onClick={() => handleEdit(address, address.id)}>EDIT</h5>
                                    <h5 onClick={()=>handleDelete(address.id)}>DELETE</h5>

                                </div>
                                <div className='address_para_customer'>
                                  <p>{address.phone_number}, {address.email}</p>
                                <p>{address.address}, {address.locality}, {address.city}, {address.state}, {address.pincode}</p>
                                </div>
                                <div className='delivery_customer'>
                                   
                                <button
                                disabled={selectedAddressId !== address.id}
                                style={{
                                  marginBottom: "10px",
                                  padding: "10px",
                                  backgroundColor: selectedAddressId === address.id ? "green" : "lightgray",
                                  color: "white",
                                  border: "none",
                                  cursor: selectedAddressId === address.id ? "pointer" : "not-allowed",
                                }}
                                >Delivery to this address</button>
                                </div>
                            </div>
                        ))}
                    </div>
             </div>
             

<div></div>

</div>




                <div className="product_right_side_customer">
    <div className="price_detail_container_customer">
      <div className="pice_detail_customer">
        <h4>Price Detail</h4>
        <div>
          <div className="total_price_customer">
            <p>Total product price</p>
            <p>&#8377;{totalProductPrice}</p>
          </div>
          <div className="total_discount_customer">
            <p>Total Discount</p>
            <p>&#8377;{totalDiscount}</p>
          </div>
        </div>
      </div>

      <hr />
      <div className="order-total_customer">
        <h4>Order Total</h4>
        {/* <p>&#8377;{actualTotalPrice}</p> */}
        <p>&#8377;{discountCoupon ? discountCoupon?.discounted_price:actualTotalPrice}</p>
      </div>
      

  <div className="total_discount_msg_customer animate-fade-in">
                    {
                      totalDiscount>0 && <p>🎉 Yah, your total discount is {totalDiscount}rs!</p>
                    }
                    {
                      discountCoupon &&  <p>🎉 Yah, you got extra discount {discountCoupon.discount_value}%</p>
                    }
                </div>

                <div className="userdiscountentercouponcode">
  <p><span>Enter Coupon Code:</span><input value={code} onChange={(e)=>setCode(e.target.value)}/></p>
<button className="applycodebtn" onClick={handleApplyCode}>Apply Code</button>
</div>
 

      <div className="continue_customer">
        <p>Click on continue will not deduct any money</p>
        <button onClick={handleContinueClick}>Continue</button>
      </div>
    </div>
  </div>
        </div> 





        {showModal && (
                <div className="modalbuysingleitem_overlay">
                    <div className="paymentbuysingleitem_modal">
                        
                        <h3>Select Payment Method</h3>

                        
                        <p>Total Price:&nbsp; <b>
                          <p>&#8377;{discountCoupon ? discountCoupon?.discounted_price:actualTotalPrice}</p>
                            </b></p>

  {/* <div className='left-payment-online'>
            <input type='radio' name='paymentMethod' onChange={()=>handlePaymentMethod('cash')} checked={paymentMethod=='cash'}/><span>Cash On Delivery</span>
  </div> */}
  <br/>
  <div className='left-payment-online'>
            <input type='radio' name='paymentMethod' onChange={()=>handlePaymentMethod('card')} checked={paymentMethod=='card'}/> <span>Pay Online</span>
  </div>

  <br/>
                        <div className='buysingleItem__buttons'>
                          <button className='busysingleitem_orderbtn' onClick={handlePlaceOrder}>Place Order</button>
                          <button className='busysingleitem_orderbtn' onClick={()=>setShowModal(false)}>Cancel</button>
                          </div>
                    </div>
                </div>
            )}
      
    </div>
  )
}

export default BuySingleItem;

