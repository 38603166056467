import React, { useState } from "react";
import "./EcomAllEmployees.css";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import { Link } from "react-router-dom";
import DashBoardHead from "../DashBoard/DashBoardHead";

function EcomAllUsers() {

  const [activeTab, setACtiveTab]=useState('all')

  const handleTabClick=(tab)=>{
    setACtiveTab(tab)
  }

   // Sample data for each tab
   const data = {
    all: [
      { name: 'Rahul Dubey', phone: '+91 92827 82728', email: 'NA', type: 'Electrician', status: 'Unapproved' },
      { name: 'Sunny Mokama', phone: '+91 34442 82728', email: 'NA', type: 'Electrician', status: 'Approved' }
    ],
    endUser: [
      { name: 'John Doe', phone: '+91 12345 67890', email: 'NA', type: 'End User', status: 'Pending' }
    ],
    shops: [
      { name: 'Shop1', phone: '+91 98765 43210', email: 'shop1@domain.com', type: 'Shops', status: 'Approved' }
    ],
    electrician: [
      { name: 'Rahul Dubey', phone: '+91 92827 82728', email: 'NA', type: 'Electrician', status: 'Unapproved' },
      { name: 'Sunny Mokama', phone: '+91 34442 82728', email: 'NA', type: 'Electrician', status: 'Approved' }
    ]
  };

  return (
    <div>
      <div className="ecom-allusers-container">
        <DashBoardHead/>

        <div className="ecom-allusers-headers">
          <div className="ecom-allusers-left-header">
            <div className="ecom-allusers-left-person-heading">
              <p onClick={()=>handleTabClick('all')} className={activeTab=='all' ? 'ecom-user-active':''}>All</p>
              <p onClick={()=>handleTabClick('shop')} className={activeTab=='shop' ? 'ecom-user-active' : ''}>Shops</p>
              <p onClick={()=>handleTabClick('electrician')} className={activeTab=='electrician' ? 'ecom-user-active' :''}>Electrician</p>
            </div>
          </div>

<div className="ecom-allusers-header-line"></div>
          <div className="ecom-allusers-right-header">
            <div className="ecom-allusers-right-person-approving">
              <p>All</p>
              <p>Unapproved</p>
              <p>Approved</p>
              <p>Pending</p>
            </div>
          </div>
        </div>



        <div>
          {activeTab=='all' && 
          <div>
              <div className="ecom-allusers-left-details">
            <div className="">
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Type</th>

           
            </tr>
          </thead>
          <tbody>
            {/* Render table rows based on active tab */}
            {data[activeTab].map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.email}</td>
                <td>{user.type}</td>
                <td>
                  <Link to='/admin/viewEmpDetail'>
                  <button className="ecom-alluser-view-details">View Details</button>
                  </Link>                  
                </td>
                <td>
                  <button className="ecom-alluser-approve">Approved</button>
                </td>                
              </tr>
            ))}
          </tbody>
        </table>
    </div> 

            </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

export default EcomAllUsers;
