import React, { useEffect, useState } from 'react';
import './CardCustomer.css';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from '../Components/Registration/Api';
import axios from 'axios';


function Card() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const API_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading]=useState(true)
  
  // Fetch products from API
  useEffect(() => {
  const fetchData=async()=>{
    const link = process.env.REACT_APP_BASE_URL;
    const endpoint = `/api/user/listproductitem/`;
    const url = link + endpoint;
    try {
      const res = await axios.get(`${url}`);  
      if (res.status == 200) {
        setProducts(res.data.results);
        setLoading(false)
      } 
    } catch (error) {
      console.error(error);
    };
  }  
  fetchData()
  },[])


  // Navigate to product detail page with the product ID
  const viewMore = (productId) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/userProductDetail/${productId}`);
  };

  const handleRawProduct=()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/productRawMaterial')
  }
  
    // Show loader while data is being fetched
    if (loading) {
      return (
        <div className="loader-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      );
    }


  return (
    <>
    {!products && <p>Loading....</p>}
    <div className="main_div_customer">
      <div className="heading_customer">
        <p>LED LIGHTS</p>
        <div className='heading_button_customer'><button className='haeding_see_btn' onClick={handleRawProduct}>See All</button></div>
      </div>
      
      <div className="cards-container-wrapper_customer">
        <div className="cards-container_customer">
          {products.slice(0,6).map((product) => (
            <div className="shopping-card_customer" key={product.id}>
             <div className='images_customer'> <img
                src={product.product_image_url.split(',')[0]} // Show first image
                alt={product.product_name}
                className="product-img_customer"
              /></div>
              <div className="card-content_customer">
                <div className="card-header_customer">
                  <h3 className="product-title_customer">{product.product_name}</h3>
                  {/* <div className="rating">
                    <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                  </div> */}
                </div>
                <div className="card-footer_customer">
                  <p className="price_customer">&#8377;{`${product.product_fixed_price}`}</p>
                  <button className="view-more-btn_customer" onClick={() => viewMore(product.id)}>
                View More
              </button>
                </div>
              </div>
             
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default Card;
