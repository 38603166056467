import React from 'react'
import DashBoardHead from '../DashBoard/DashBoardHead'

function EcomAllServices() {
  return (
    <div>
      <DashBoardHead/>
    </div>
  )
}

export default EcomAllServices
