import React, { useEffect } from "react";
import "./OrderDeliver.css";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../Components/Registration/Api";
import RatingAndReview from "../RawMaterial/RatingAndReview";

const OrderDeliver = () => {
  const [status, setStatus] = useState("Your item has been shipped");

const [loading, setLoading]=useState(true)

  const [order, setOrder] = useState(null);
  const [deliveryStatus, setDeliveryStatus]=useState(null)

  const { orderid } = useParams();

  const handlePointerClick = (e) => {
    const newStatus = e.target.getAttribute("data-status");
    setStatus(newStatus);
  };


  const fetchOrderDetails = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/user/history/order/${orderid}`;
      const url = link + endpoint;

      const res = await api.get(url);
      console.log(res);
      setOrder(res.data)
      setDeliveryStatus(res.data.delivery_status)
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);



   // Delivery status logic
   const [timelineSteps, setTimelineSteps] = useState([]);
   useEffect(() => {
    if (order) {
      // Define the order statuses and their corresponding dates
      const steps = [
        { status: 'Order Confirmed', date: '2024-08-16', dotClass: 'dot1_customer' },
        { status: 'Shipped', date: '2024-08-17', dotClass: 'dot2_customer' },
        { status: 'Out for Delivery', date: '2024-08-19', dotClass: 'dot3_customer' },
        { status: 'Delivered', date: '2024-08-21', dotClass: 'dot4_customer' }
      ];

      // Map through the steps and mark the active ones based on the order's current status
      const updatedSteps = steps.map(step => ({
        ...step,
        isActive: order.delivery_status === step.status
      }));

      setTimelineSteps(updatedSteps);
    }
  }, [order]);


   // Show loader while data is being fetched
   if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className="order-container_customer">
        {/* Breadcrumbs */}
        <div className="breadcrumb">
          <Link to="/ecom">Home</Link> &gt;
          <Link to="/userprofile"> My Account</Link> &gt;
          <Link to='/myorderuser'> My Orders</Link>&gt;
          <span>{order?.razorpay_order_id}</span>
        </div>

        {/* Flex Section */}
        <div className="summary-section_customer">
          <div className="delivery-address_customer">
            <h3>Delivery Address</h3>
            <p className="name_customer">{order?.shipping_address?.name}</p>
            <p>{order?.shipping_address?.address}, {order?.shipping_address?.locality}, {order?.shipping_address?.city}, {order?.shipping_address?.state}, {order?.shipping_address?.pincode}</p>
            <p>Phone number: {order?.shipping_address?.phone_number}</p>
            
          </div>

          <div className="rewards_customer">
            <h3>Your Rewards</h3>
            <p className="reward-highlight_customer">
              Early Access to this Sale
            </p>
            <p>For Cellus Tech Plus Members</p>
          </div>

          <div className="more-actions_customer">
            <h3>More Actions</h3>
            <p>Download Invoice</p>
            <button className="download-btn_customer">Download</button>
          </div>
        </div>

<div>
  <p>Order ID: <b>{order?.id}</b></p>
  <p>Delivery Status: <b>{order?.delivery_status}</b></p>
  {
  order?.order_items?.length>1 && <b>Total Price:{order?.total_price}</b>
}
</div>

        {/* Product Section */}
       {
        order?.order_items.length>1 ? order?.order_items.map((order)=>{

          const productImg=order?.product?.product_image_url.split(",")[0]||[]
          const rawImage=order?.raw_materials?.product_image_url.split(",")[0]
          return(
            <>
            <div className="order-section_customer">
            <div className="product-image_customer">
              <img
                src={order?.product ? order?.product.product_image_url.split(",")[0]:order?.raw_materials.product_image_url.split(",")[0]} // Replace with actual image
                alt="CTI-SL 50W FT-D Street Light"
              />
            </div>
            <div className="product-details__customer">
              <h4>{order?.product
                  ? order?.product?.product_name  
                  : order?.raw_materials?.details} </h4>
              <p className="price_customer">₹{order?.product_price}</p>
            </div>
            </div>
            
</>
            
          )
        }) : 
        <>
        <div className="order-whole-sectionwithrating">
          <div className="order-section_customer">
          <div className="product-image_customer">
              <img
                src={order?.order_items[0]?.product ? order?.order_items[0]?.product?.product_image_url.split(",")[0]:
                  order?.order_items[0]?.raw_materials?.product_image_url.split(",")[0]
                } // Replace with actual image
                alt="CTI-SL 50W FT-D Street Light"
              />
            </div>
            <div className="product-details__customer">
              <h4>{order?.order_items[0]?.product ? order?.order_items[0]?.product?.product_name: order?.order_items[0]?.raw_materials?.details}</h4>
              <p className="price_customer">₹{order?.total_price}</p>
            </div>
          </div>

            {/* {
              order?.delivery_status == 'Delivered' && (
                <div className="customer-review-rating">
                <RatingAndReview 
                productId={order?.order_items[0]?.product?.id || order?.order_items[0]?.raw_materials?.id}
                isFinishedProduct={!!order?.order_items[0]?.product} />
              </div>
              )              
            } */}
            </div></>
       }




          <div>
          {/* Vertical Timeline */}
          {/* <div className="order-timeline_customer">
            <div className="timeline-step_customer">
              <span className="step-dot_customer active"></span>
              <div className="step-text_customer">Order Confirmed</div>
              <div className="dots-container_customer">
                <div className="dot1_customer"></div>
              </div>
              <div className="step-date_customer">Fri, 16th Aug</div>
            </div>
            <div className="timeline-step_customer">
              <span className="step-dot_customer active"></span>
              <div className="step-text_customer">Shipped</div>
              <div className="dots-container_customer">
                <div className="dot2_customer"></div>
              </div>
              <div className="step-date_customer">Sat, 17th Aug</div>
            </div>
            <div className="timeline-step_customer">
              <span className="step-dot_customer active"></span>
              <div className="step-text_customer">Out for Delivery</div>
              <div className="dots-container_customer">
                <div className="dot3_customer"></div>
              </div>
              <div className="step-date_customer">Mon, 19th Aug</div>
            </div>
            <div className="timeline-step_customer">
              <span className="step-dot_customer"></span>
              <div className="step-text_customer">Delivered</div>
              <div className="dots-container_customer">
                <div className="dot4_customer"></div>
              </div>
              <div className="step-date_customer">Wed, 21st Aug</div>
            </div>
          </div> */}



{/* timeline for order delivery status */}
<div className="order-timeline_customer">
      {/* {timelineSteps.map((step, index) => (
        <div key={index} className="timeline-step_customer">
          <span className={`step-dot_customer ${step.isActive ? 'active' : ''}`}></span>
          <div className="step-text_customer">{step.status}</div>
          <div className="dots-container_customer">
            <div className={step.dotClass}></div>
          </div>
          <div className="step-date_customer">{step.date}</div>
        </div>
      ))} */}
    </div>



          </div>

          {/* Scrollable Timeline */}
          {/* <div className="scroll-timeline">
        <div
          className="timeline-pointer active"
          data-status="Order Confirmed"
          onClick={handlePointerClick}
        ></div>
        <div
          className="timeline-pointer"
          data-status="Shipped"
          onClick={handlePointerClick}
        ></div>
        <div
          className="timeline-pointer"
          data-status="Out for Delivery"
          onClick={handlePointerClick}
        ></div>
        <div
          className="timeline-pointer"
          data-status="Delivered"
          onClick={handlePointerClick}
        ></div>
      </div> */}

          {/* Dynamic Status Text */}
          {/* <p className="status-text">{status}</p> */}
     
      </div>
    </>
  );
};

export default OrderDeliver;
