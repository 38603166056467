import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardHead from "../DashBoard/DashBoardHead";
import { Link } from "react-router-dom";
import "./EcomOrderHistory.css";
import api from "../../Components/Registration/Api";

function EcomOrderHistoryDetail() {
  const { orderHistoryId } = useParams();

  const [data, setData] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [delStatus, setDelStatus] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/order/details/${orderHistoryId}`;
      const url = link + endpoint;

      const res = await api.get(url);
      if (res.status == 200) {
        setData(res.data);
        setUserAddress(res.data.shipping_address);
        setDelStatus(res.data?.delivery_status || "");
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(data, "all data order history");

  const handleUpdateDelivery = async (status) => {
    if (!delStatus) return;
    setDelStatus(status); // Set the status in state

    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/update/delivery/status/${orderHistoryId}`;

      const url = link + endpoint;
      const data = {
        delivery_status: status,
      };
      const res = await api.put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(res, "dddd");
      fetchDetails();
    } catch (error) {
      console.error(error);
    }
  };

  const [otp, setOtp] = useState("");
  const [isVerified, setIsVerified]=useState(false)

  const handleSendOTP = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/senddeliveryconfirmationotp`;
      const url = link + endpoint;

      const val = {
        customer_id: data?.customer,
        order_id: data?.id,
      };
      const res = await api.post(url, val, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(res, "otp");
      if (res.status == 200) {
        alert(res?.data?.Success);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      alert("Enter OTP");
      return;
    }
    try {
      const link=process.env.REACT_APP_BASE_URL
      const endpoint=`/api/verifydeliveryconfirmationotp`
      const url=link+endpoint

      const val={
        customer_id:data?.customer,
        order_id:data?.id,
        otp:otp
      }

      const res=await api.post(url, val,{
        headers:{
          "Content-Type": "application/json",
        }
      })

      if (res.status === 200 && res.data?.Message) {
        alert(res.data?.Message); // Success message
        setIsVerified(true)
      } 


    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400 && error.response.data?.Error) {
        alert(error.response.data?.Error); // Show Error message from API
      } else {
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

 const [updatePaymentStatus, setUpdatePaymentStatus]=useState('')
 const UpdatePaymentStatus=async(status)=>{
  setUpdatePaymentStatus(status)

   // Show confirmation dialog
   const confirmUpdate = window.confirm(
    `Are you sure you want to update the payment status to "${status}"?`
  );

  if (!confirmUpdate) {
    return;  // If the user cancels, do nothing
  }

  try {
    const link=process.env.REACT_APP_BASE_URL
    const endpoint='/api/update/cod/payment/status'
    const url=link+endpoint
    
    const payload={
      order_id:orderHistoryId,
      payment_status:status
    }

    const res=await api.put(url, payload, {
      headers:{
        "Content-Type":'application/json'
      }
    })

    fetchDetails()
  } catch (error) {
    console.error(error)
  }
 } 




  return (
    <div>
      <div className="ecom-allusers-container">
        <DashBoardHead />

        <div className="ecom-product-detail-main-section">
          <div>
            <Link to="/admin/orderHistory">Back</Link>
          </div>

          <div className="ecom-product-detail-container">
            <div className="ecom-order-history-container">
              <div className="ecom-orderhistory-leftside-div">
                <div className="ecom-orderhistory-address">
                  <p>
                    Name: <span>{userAddress?.name}</span>
                  </p>
                  <p>
                    Phone No.: <span>{userAddress?.phone_number}</span>
                  </p>
                  <p>
                    Email: <span>{userAddress?.email}</span>
                  </p>
                  <p>
                    Address:{" "}
                    <span>
                      {userAddress?.address}, {userAddress?.locality},{" "}
                      {userAddress?.city}, {userAddress?.state},{" "}
                      {userAddress?.pincode}
                    </span>
                  </p>
                  <p>
                    Total Price: <span>{data?.total_price}</span>
                  </p>
                  <p>
                    Delivery Status: <span><b>{data?.delivery_status}</b></span>
                  </p>
                  <p>
                    Payment Status: <span><b>{data?.payment_status}</b></span>
                  </p>
                 {
                  data?.delivery_status!=='Delivered' && (
                    <div className="ecom-orderhistroy-otpbtns">
                    <button
                      className="ecom-alluser-view-details"
                      onClick={handleSendOTP}
                    >
                      Send OTP
                    </button>
                    <input value={otp} onChange={(e)=>setOtp(e.target.value)} placeholder="Enter OTP" />
                    <button
                      className="ecom-alluser-view-details"
                      onClick={handleVerifyOtp}
                      disabled={isVerified} // Disable the button if OTP is verified
        style={{ 
          backgroundColor: isVerified ? '#28a745' : '#007bff',
          color: '#fff',
          border: 'none',
          padding: '10px 20px',
          cursor: isVerified ? 'not-allowed' : 'pointer'
        }}
      >
        {isVerified ? 'Verified' : 'Verify OTP'}
                    
                    </button>
                  </div>
                  )
                 }


                 {
                  data?.payment_method=='COD' && data?.payment_status!=='Completed'?
                  <div className="updatepaymentdiv">
                    Update Payment Status:
<select value={updatePaymentStatus} onChange={(e)=>UpdatePaymentStatus(e.target.value)}>
  <option value='' disabled>Payment Status</option>
  <option value='Completed'>Completed</option>
  <option value='Failed'>Failed</option>
  <option value='Refunded'>Refunded</option>
</select>
                  </div> :''
                 }
                </div>
              </div>

              <div className="ecom-orderhistory-rightside-div">
                {data?.order_items.length > 1 ? (
                  <div className="ecom-orderhistory-order-items">
                    {data?.order_items?.map((item, index) => (
                      <div key={index} className="ecom-orderhistory-order-item">
                        <div>
                          <img
                            src={item?.product ? item?.product.product_image_url.split(",")[0]:item?.raw_materials?.product_image_url.split(",")[0]}
                            alt={item?.product?.product_name}
                          />
                        </div>
                        <div>
                          <p>
                            Product: <span>{item?.product ? item?.product.product_name : item?.raw_materials?.details}</span>
                          </p>
                          <p>
                            Quantity: <span>{item?.quantity}</span>
                          </p>
                          <p>
                            Price: <span>{item?.product_price}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ecom-orderhistory-order-items">
                    <div key="" className="ecom-orderhistory-order-item">
                      <div>
                        <img
                          src={
                            data?.order_items[0]?.product ? data?.order_items[0]?.product?.product_image_url.split(
                              ","
                            )[0]:data?.order_items[0]?.raw_materials?.product_image_url.split(
                              ","
                            )[0]
                          }
                        />
                      </div>
                      <div>
                        <p>
                          Product:{" "}
                          <span>
                            {data?.order_items[0]?.product  ? data?.order_items[0]?.product.product_name : data?.order_items[0]?.raw_materials.details}
                          </span>
                        </p>
                        <p>
                          Quantity:{" "}
                          <span>{data?.order_items[0]?.quantity}</span>
                        </p>
                        <p>
                          Price:{" "}
                          <span>{data?.order_items[0]?.product_price}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

          {
            data?.delivery_status!=='Delivered' && (
              <div className="ecom-orderhistory-action-buttons">
              <button
                className="ecom-alluser-view-details"
                onClick={() => handleUpdateDelivery("Shipped")}
              >
                Mark as Shipped
              </button>
              <button
                className="ecom-alluser-view-details"
                onClick={() => handleUpdateDelivery("Failed")}
              >
                Mark as Failed
              </button>
              
              {/* <button/
                className="ecom-alluser-view-details"
                onClick={() => handleUpdateDelivery("Delivered")}
              >
                Mark as Delivered
              </button> */}
            </div>
            )
          }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EcomOrderHistoryDetail;
