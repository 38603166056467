import React, { useEffect, useState } from 'react'
import './Address.css'
import { useLocation, useNavigate } from 'react-router-dom'
import api from '../../Components/Registration/Api';
import { useAuth } from '../../AuthContext';


function Address() {

    const navigate=useNavigate()
    const location = useLocation();
    const { currentStep = 1, priceDetailData = [] } = location.state || {};
    const [deliveryAddress, setDeliveryAddress]=useState([])
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState(null); //id of selected object from array
    const [errorMessage, setErrorMessage] = useState('');
    const [addresses, setAddresses] = useState([])  //array of all data
    const [clickeditbtn, setClickEditBtn]=useState(false)

    const {setDelAddress}=useAuth()


    useEffect(()=>{
      getDeliveryAddressData()
    },[])

    const getDeliveryAddressData=async()=>{
      const link=process.env.REACT_APP_BASE_URL
      const endpoint='/api/user/deliveryaddress'
      const url=link+endpoint
        const res=await api.get(`${url}`)

        if(res.status==200){
          console.log(res,"del")
          // setDeliveryAddress(res.data)
          setAddresses(res.data)
          if (res.data.length > 0) {
            setSelectedAddressId(res.data[0].id); // the address has an id
        }
        }
    }


    const [editingAddress, setEditingAddress] = useState(null);
    const [newAddress, setNewAddress] = useState({
        name: '',
        phone_number:'',
        email: '',
        address: '',
        locality: "",
        city: '',
        state: '',
        pincode: '',
        type_of_address: 'home'
    });

  
    const handleEdit = async(curraddress, id) => {
        // setEditingAddress(curraddress);
        setNewAddress(curraddress);
        setShowAddressForm(true);
setClickEditBtn(true)
        setSelectedAddressId(id)
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
          // Allow only numbers and limit to 10 digits
          const validNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
          if (validNumber.length <= 10) {
              setNewAddress((prev) => ({ ...prev, [name]: validNumber }));
          }
      } else {
          setNewAddress((prev) => ({ ...prev, [name]: value }));
      }
    };


    const saveAddress = async() => {
        // Check if any required fields are empty
        if (!newAddress.name || !newAddress.phone_number || !newAddress.pincode || !newAddress.locality || 
            !newAddress.city || !newAddress.state || !newAddress.address || !newAddress.email) {
            setErrorMessage('All fields are required');
            return;
        }

        if (newAddress.phone_number.length !== 10) {
          alert('Mobile number must be exactly 10 digits!');
          return;
      }


        // if (editingAddress) {
        //    setAddresses((prev) =>
        //         prev.map((address) =>
        //             address.id === editingAddress.id ? { ...address, ...newAddress } : address
        //         )
        //     );
        // } else {
        //     setAddresses((prev) => [newAddress, ...prev]);  // Add new address at the top
        // }  

        const link=process.env.REACT_APP_BASE_URL
        const endpoint=`/api/user/deliveryaddress`
        const url=link+endpoint
  
        const address={
          name: newAddress.name || addresses.name,
          phone_number:newAddress.phone_number || addresses.phone_number,
          email: newAddress.email || addresses.email,
          address: newAddress.address || addresses.address,
          locality: newAddress.locality || addresses.locality,
          city: newAddress.city || addresses.city,
          state: newAddress.state || addresses.state,
          pincode: newAddress.pincode || addresses.pincode,
          type_of_address: newAddress.type_of_address || addresses.type_of_address
        }
  
        console.log("curr and ups", address)
        const response = await api.post(`${url}`, 
          address,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }          
        );

        if(response.status==200){
        }
        
        getDeliveryAddressData()

        setShowAddressForm(false);

        setEditingAddress(null);
        setNewAddress({
        name: '',
        phone_number:'',
        email: '',
        address: '',
        locality: "",
        city: '',
        state: '',
        pincode: '',
        type_of_address: 'home'
        });
        setErrorMessage('');
    };


 const getSelectedAddress=()=>{
  const res=addresses.find((add)=>add.id===selectedAddressId)
  return res
 }





    const handleEditAddress=async()=>{
      const link=process.env.REACT_APP_BASE_URL
      const endpoint=`/api/user/deliveryaddress/${selectedAddressId}`
      const url=link+endpoint

      const address={
        name: newAddress.name || addresses.name,
        phone_number:newAddress.phone_number || addresses.phone_number,
        email: newAddress.email || addresses.email,
        address: newAddress.address || addresses.address,
        locality: newAddress.locality || addresses.locality,
        city: newAddress.city || addresses.city,
        state: newAddress.state || addresses.state,
        pincode: newAddress.pincode || addresses.pincode,
        type_of_address: newAddress.type_of_address || addresses.type_of_address
      }

      console.log("curr and ups", address)
      const response = await api.put(`${url}`, 
        address,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }          
      );

      if(response.status==200){
        setShowAddressForm(false)
        getDeliveryAddressData()
      }
    }


    const handleCancel = () => {
      setClickEditBtn(false);
        setShowAddressForm(false);
        setEditingAddress(null);
        setNewAddress({
          name: '',
          phone_number:'',
          email: '',
          address: '',
          locality: "",
          city: '',
          state: '',
          pincode: '',
          type_of_address: 'home'
        });
        setErrorMessage('');
    };

 

    const handleAddressSelect = (addressId) => {
        if (selectedAddressId === addressId) {
            setSelectedAddressId(null);  // Deselect the address if it's already selected
        } else {
            setSelectedAddressId(addressId);  // Select the new address
        }
    };


 

    const handleDelete = async (addressId) => {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `${link}/api/user/deliveryaddress/${addressId}`;
      const res = await api.delete(endpoint);


      if (res.status == 204) {
          // Remove the deleted address from the state
          // setAddresses((prev) => prev.filter((address) => address.id !== addressId));
      getDeliveryAddressData()

      }
  };

    function deliver(){
      if (!selectedAddressId) {
        alert("Please select an address.");
        return;
    }

    const deliveryAddress=getSelectedAddress()
        setDelAddress(deliveryAddress)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/paymentMethod' , { state: { currentStep: 3, priceDetailData, selectedAddressId } })
    }

    


  return (
   <>
<div className='address_page_customer'>
       {/* Step Navigation */}
       <div className="step-navigation_customer">
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 1 ? "completed_customer" : ""
            }`}
          >
            1
          </div>
          <p className="step-label_customer">Cart</p>
        </div>
        <div className={`line_customer ${currentStep >= 2 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 2 ? "completed_customer" : ""
            }`}
          >
            2
          </div>
          <p className="step-label_customer">Address</p>
        </div>
        <div className={`line_customer ${currentStep >= 3 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep >= 3 ? "completed_customer" : ""
            }`}
          >
            3
          </div>
          <p className="step-label_customer">Payment</p>
        </div>
        <div className={`line_customer ${currentStep >= 4 ? "completed_customer" : ""}`}></div>
        <div className="step-item_customer">
          <div
            className={`step_customer ${
              currentStep === 4 ? "completed_customer" : ""
            }`}
          >
            4
          </div>
          <p className="step-label_customer">Summary</p>
        </div>
      </div>



<div className='address_content_main_div_customer'>
    {/* left side */}
    <div className='left_side_customer'>
                        <div className="select_address_customer">
                            <h4>Select Delivery Address</h4>
                            <h5 onClick={() => setShowAddressForm(true)}>+ ADD NEW ADDRESS</h5>
                        </div>
                        
                        {showAddressForm && (
                            <div className='address_form_customer'>
                                {/* Error message display */}
                                {errorMessage && <div className="error_message_customer">{errorMessage}</div>}
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Name' 
                                        className='input_field_customer'
                                        value={newAddress.name}
                                        onChange={handleInputChange}
                                        name="name"
                                    />
                                    <input 
                                        type='Number' 
                                        placeholder='Phone Number' 
                                        className='input_field_customer'
                                        value={newAddress.phone_number}
                                        onChange={handleInputChange}
                                        name="phone_number"
                                    />
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email"
                                        value={newAddress.email} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Pincode' 
                                        className='input_field_customer'
                                        value={newAddress.pincode}
                                        onChange={handleInputChange}
                                        name="pincode"
                                    />
                                    <input 
                                        type='text' 
                                        placeholder='Locality' 
                                        className='input_field_customer'
                                        value={newAddress.locality}
                                        onChange={handleInputChange}
                                        name="locality"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <textarea 
                                        placeholder='Address' 
                                        className='address_input_customer'
                                        value={newAddress.address}
                                        onChange={handleInputChange}
                                        name="address"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='City' 
                                        className='input_field_customer'
                                        value={newAddress.city}
                                        onChange={handleInputChange}
                                        name="city"
                                    />
                                    
                                    <input
                                    type='text'
                                    placeholder='State'
                                    className='input_field_customer'
                                    value={newAddress.state}
                                    onChange={handleInputChange}
                                    name='state'
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='home' 
                                            checked={newAddress.type_of_address=='home'}
                                            onChange={handleInputChange}                                            
                                        /> Home
                                    </label>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='work' 
                                            checked={newAddress.type_of_address=='work'}
                                            onChange={handleInputChange}
                                            
                                        /> Work
                                    </label>
                                </div>
                                <div className='form_row_customer'>
                                   
                                   {
                                    clickeditbtn ?<button className='cancel_text_customer' onClick={handleEditAddress}>Update</button>:
                                    <button className='save_btn_customer' onClick={saveAddress}>Save</button>
                                   }
                                    <span className='cancel_text_customer' onClick={handleCancel}>Cancel</span>
                                </div>
                            </div>
                        )}


                        {addresses.map((address) => (
                            <div
                            //  className='address_content_customer' 
                            className={`address_item_customer ${selectedAddressId === address.id ? 'selected' : ''}`}
                            key={address.id}>
                                <div className='name_edit_customer'>
                                    <p> <label>
                                        <input 
                                            type="radio" 
                                            name="selectedAddress" 
                                            checked={selectedAddressId === address.id}
                                            onChange={() => handleAddressSelect(address.id)} 
                                            className='radio_select_address'
                                        />
                                       
                                    </label>{address.name}</p>
                                    <h5 onClick={() => handleEdit(address, address.id)}>EDIT</h5>
                                    <h5 onClick={()=>handleDelete(address.id)}>DELETE</h5>

                                </div>
                                <div className='address_para_customer'>
                                  <p>{address.phone_number}, {address.email}</p>
                                <p>{address.address}, {address.locality}, {address.city}, {address.state}, {address.pincode}</p>
                                </div>
                                <div className='delivery_customer'>
                                   
                                <button
                                disabled={selectedAddressId !== address.id}
                                style={{
                                  margin: "10px",
                                  padding: "10px",
                                  backgroundColor: selectedAddressId === address.id ? "green" : "lightgray",
                                  color: "white",
                                  border: "none",
                                  cursor: selectedAddressId === address.id ? "pointer" : "not-allowed",
                                }}
                                >Delivery to this address</button>
                                </div>
                            </div>
                        ))}
                    </div>



   {/* right side */}
   <div className="product_right_side_customer">
    <div className="price_detail_container_customer">
      <div className="pice_detail_customer">
        <h4>Price Detail</h4>
        <div>
          <div className="total_price_customer">
            <p>Total product price</p>
            <p>&#8377;{priceDetailData?.[0] || "0"}</p>
          </div>
          <div className="total_discount_customer">
            <p>Total Discount</p>
            <p>&#8377;{priceDetailData?.[1] || "0"}</p>
          </div>
        </div>
      </div>

      <hr />
      <div className="order-total_customer">
        <h4>Order Total</h4>
        <p>&#8377;{priceDetailData?.[2] || "0"}</p>
      </div>
      {priceDetailData?.[1] > 0 && (
  <div className="total_discount_msg_customer">
    <p>🎉 Yah, your total discount is {priceDetailData?.[1]} rs</p>
  </div>
)}
      <div className="continue_customer">
        <p>Click on continue will not deduct any money</p>
        <button onClick={deliver}>Continue</button>
      </div>
    </div>
  </div>
</div>
</div>
   </>
  )
}

export default Address;
