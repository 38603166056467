import React, { useEffect, useState } from 'react';
import ProfileSidebar from '../ProfilesideBar/ProfileSidebar';
import './Manage_address.css';
import api from '../../Components/Registration/Api';

function Manage_address() {
  const [showAddressForm, setShowAddressForm] = useState(false);

  // Toggle form visibility
  const handleAddNewAddress = () => setShowAddressForm(!showAddressForm);
  const [errorMessage, setErrorMessage] = useState('');
  const [clickeditbtn, setClickEditBtn]=useState(false)

   const [editingAddress, setEditingAddress] = useState(null);
    const [newAddress, setNewAddress] = useState({
        name: '',
        phone_number:'',
        email: '',
        address: '',
        locality: "",
        city: '',
        state: '',
        pincode: '',
        type_of_address: 'home'
    });


    const [selectedAddressId, setSelectedAddressId] = useState(null); //id of selected object from array
      const [addresses, setAddresses] = useState([])  //array of all data
    

  useEffect(()=>{
    getDeliveryAddressData()
  },[])

  const getDeliveryAddressData=async()=>{
    const link=process.env.REACT_APP_BASE_URL
    const endpoint='/api/user/deliveryaddress'
    const url=link+endpoint
      const res=await api.get(`${url}`)

      if(res.status==200){
        console.log(res,"del")
        // setDeliveryAddress(res.data)
        setAddresses(res.data)
        if (res.data.length > 0) {
          setSelectedAddressId(res.data[0].id); // the address has an id
      }
      }
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone_number') {
      // Allow only numbers and limit to 10 digits
      const validNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (validNumber.length <= 10) {
          setNewAddress((prev) => ({ ...prev, [name]: validNumber }));
      }
  } else {
      setNewAddress((prev) => ({ ...prev, [name]: value }));
  }
};


const saveAddress = async() => {
  // Check if any required fields are empty
  if (!newAddress.name || !newAddress.phone_number || !newAddress.pincode || !newAddress.locality || 
      !newAddress.city || !newAddress.state || !newAddress.address || !newAddress.email) {
      setErrorMessage('All fields are required');
      return;
  }

  if (newAddress.phone_number.length !== 10) {
    alert('Mobile number must be exactly 10 digits!');
    return;
}


  // if (editingAddress) {
  //    setAddresses((prev) =>
  //         prev.map((address) =>
  //             address.id === editingAddress.id ? { ...address, ...newAddress } : address
  //         )
  //     );
  // } else {
  //     setAddresses((prev) => [newAddress, ...prev]);  // Add new address at the top
  // }  

  const link=process.env.REACT_APP_BASE_URL
  const endpoint=`/api/user/deliveryaddress`
  const url=link+endpoint

  const address={
    name: newAddress.name || addresses.name,
    phone_number:newAddress.phone_number || addresses.phone_number,
    email: newAddress.email || addresses.email,
    address: newAddress.address || addresses.address,
    locality: newAddress.locality || addresses.locality,
    city: newAddress.city || addresses.city,
    state: newAddress.state || addresses.state,
    pincode: newAddress.pincode || addresses.pincode,
    type_of_address: newAddress.type_of_address || addresses.type_of_address
  }

  console.log("curr and ups", address)
  const response = await api.post(`${url}`, 
    address,
  {
    headers: {
      'Content-Type': 'application/json',
    },
  }          
  );

  if(response.status==200){
  }
  
  getDeliveryAddressData()

  setShowAddressForm(false);

  setEditingAddress(null);
  setNewAddress({
  name: '',
  phone_number:'',
  email: '',
  address: '',
  locality: "",
  city: '',
  state: '',
  pincode: '',
  type_of_address: 'home'
  });
  setErrorMessage('');
};


const getSelectedAddress=()=>{
const res=addresses.find((add)=>add.id===selectedAddressId)
return res
}

const handleEditAddress=async()=>{
const link=process.env.REACT_APP_BASE_URL
const endpoint=`/api/user/deliveryaddress/${selectedAddressId}`
const url=link+endpoint

const address={
  name: newAddress.name || addresses.name,
  phone_number:newAddress.phone_number || addresses.phone_number,
  email: newAddress.email || addresses.email,
  address: newAddress.address || addresses.address,
  locality: newAddress.locality || addresses.locality,
  city: newAddress.city || addresses.city,
  state: newAddress.state || addresses.state,
  pincode: newAddress.pincode || addresses.pincode,
  type_of_address: newAddress.type_of_address || addresses.type_of_address
}

console.log("curr and ups", address)
const response = await api.put(`${url}`, 
  address,
{
  headers: {
    'Content-Type': 'application/json',
  },
}          
);

if(response.status==200){
  setShowAddressForm(false)
  getDeliveryAddressData()
}
}


const handleCancel = () => {
setClickEditBtn(false);
  setShowAddressForm(false);
  setEditingAddress(null);
  setNewAddress({
    name: '',
    phone_number:'',
    email: '',
    address: '',
    locality: "",
    city: '',
    state: '',
    pincode: '',
    type_of_address: 'home'
  });
  setErrorMessage('');
};

const handleDelete = async (addressId) => {
  const link = process.env.REACT_APP_BASE_URL;
  const endpoint = `${link}/api/user/deliveryaddress/${addressId}`;
  const res = await api.delete(endpoint);


  if (res.status == 204) {
      // Remove the deleted address from the state
      // setAddresses((prev) => prev.filter((address) => address.id !== addressId));
  getDeliveryAddressData()

  }
};


const handleEdit = async(curraddress, id) => {
  // setEditingAddress(curraddress);
  setNewAddress(curraddress);
  setShowAddressForm(true);
setClickEditBtn(true)
  setSelectedAddressId(id)
};



  return (
    <>
      <div className='manage_address_main_div_customer'>
        <div className='user_profile_pg_div_customer'>
          <ProfileSidebar />
        </div>
        <div className='manage_new_main_div_customer'>
          <div className='manage_new_address_customer'>
            <h4>Manage Addresses</h4>
            <p onClick={handleAddNewAddress}>+ ADD NEW ADDRESS </p>
          </div>

        {showAddressForm && (
                            <div className='address_form_customer'>
                                {/* Error message display */}
                                {errorMessage && <div className="error_message_customer">{errorMessage}</div>}
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Name' 
                                        className='input_field_customer'
                                        value={newAddress.name}
                                        onChange={handleInputChange}
                                        name="name"
                                    />
                                    <input 
                                        type='Number' 
                                        placeholder='Phone Number' 
                                        className='input_field_customer'
                                        value={newAddress.phone_number}
                                        onChange={handleInputChange}
                                        name="phone_number"
                                    />
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email"
                                        value={newAddress.email} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='Pincode' 
                                        className='input_field_customer'
                                        value={newAddress.pincode}
                                        onChange={handleInputChange}
                                        name="pincode"
                                    />
                                    <input 
                                        type='text' 
                                        placeholder='Locality' 
                                        className='input_field_customer'
                                        value={newAddress.locality}
                                        onChange={handleInputChange}
                                        name="locality"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <textarea 
                                        placeholder='Address' 
                                        className='address_input_customer'
                                        value={newAddress.address}
                                        onChange={handleInputChange}
                                        name="address"
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <input 
                                        type='text' 
                                        placeholder='City' 
                                        className='input_field_customer'
                                        value={newAddress.city}
                                        onChange={handleInputChange}
                                        name="city"
                                    />
                                    
                                    <input
                                    type='text'
                                    placeholder='State'
                                    className='input_field_customer'
                                    value={newAddress.state}
                                    onChange={handleInputChange}
                                    name='state'
                                    />
                                </div>
                                <div className='form_row_customer'>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='home' 
                                            checked={newAddress.type_of_address=='home'}
                                            onChange={handleInputChange}                                            
                                        /> Home
                                    </label>
                                    <label>
                                        <input 
                                            type='radio' 
                                            name='type_of_address' 
                                            value='work' 
                                            checked={newAddress.type_of_address=='work'}
                                            onChange={handleInputChange}
                                            
                                        /> Work
                                    </label>
                                </div>
                                <div className='form_row_customer'>
                                   
                                   {
                                    clickeditbtn ?<button className='cancel_text_customer' onClick={handleEditAddress}>Update</button>:
                                    <button className='save_btn_customer' onClick={saveAddress}>Save</button>
                                   }
                                    <span className='cancel_text_customer' onClick={handleCancel}>Cancel</span>
                                </div>
                            </div>
                        )}

          <div className='home_adress_main_div_customer'>
            {/* Existing address details */}
           {
            addresses && addresses.map((address, idx)=>(
              <div className='home_address_customer'>
              <div className='home_name_div_customer'>
                <p className='home_head_customer'>{address.type_of_address}</p>
                <p className='name_head_customer'>{address.name}</p>
                <p>{address.email}</p>
                <p className='address_content_div_customer'>
                  {address.address}&nbsp;{address.locality}&nbsp;{address.city}&nbsp;{address.state}&nbsp;{address.pincode}
                </p>
                <p className='address_phn_number_customer'>{address.phone_number}</p>
              </div>
              <div className='edit_del_address_div_customer'>
                <p className='edit_address_customer' onClick={() => handleEdit(address, address.id)} style={{cursor:'pointer'}}>Edit</p>
                <p className='del_address_customer' onClick={()=>handleDelete(address.id)} style={{cursor:'pointer'}}>Delete</p>
              </div>
            </div>
            ))
           }
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Manage_address;
