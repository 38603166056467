import React, { useEffect, useRef, useState } from "react";
import "./EcomProductDetail.css";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { BiExit } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import led from "../EcomImages/ecom-led.png";
import DashBoardHead from "../DashBoard/DashBoardHead";
import api from "../../Components/Registration/Api";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


function EcomProductDetail() {
  const { userId } = useParams();

  const navigate = useNavigate();
  const admin_id = JSON.parse(localStorage.getItem("admin_id"));

  const [productImg, setProductImg] = useState([]);
  const [activeStatus, setActiveStatus] = useState(true);
  const [pagination, setpagination]=useState({next:null})

  const [product, setProduct] = useState(null);

  const [AllData, setAllData] = useState({
    name: "",
    description: "",
    price: "",
    productImg: [],
    Category: null,
    SubCategory: null,
    categoryDetails: null,
    subCategoryDetails: null,
    breadth: null,
    height: null,
    weight: null,
    wattage: null,
    cutout: null,
    power: null,
    voltage: null,
    dimension: null,
    pf: null,
    light_flux: null,
    cut_dia: null,
    cut_size: null,
    q1_price: "",
    q2_price: "",
    q3_price: "",
    q4_price: "",
    q5_price: "",
  });

  const showOnRightSide = [
    "price",
    "q1_price",
    "q2_price",
    "q3_price",
    "q4_price",
    "q5_price",
    "categoryDetails",
    "subCategoryDetails",
  ];

  const getRightSideFields = (key) => {
    if (showOnRightSide.includes(key)) {
      const isTextArea =
        key === "categoryDetails" || key === "subCategoryDetails";

      return (
        <p key={key}>
          <span>{key.replace(/_/g, " ").toUpperCase()}:</span>
          {isTextArea ? (
            <textarea
              placeholder={`Enter ${key.replace(/_/g, " ")}`}
              value={AllData[key] || ""}
              onChange={(e) => updateField(key, e.target.value)}
            />
          ) : (
            <input
              type="text"
              placeholder={`Enter ${key.replace(/_/g, " ")}`}
              value={AllData[key] || ""}
              onChange={(e) => updateField(key, e.target.value)}
            />
          )}
        </p>
      );
    }
    return null;
  };

  const updateField = (field, value) => {
    setAllData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const fileInputRef = useRef(null); // Create a ref for the file input
  const [editIndex, setEditIndex] = useState(null);

  // Handler for adding new images
  const handleAddImage = (e) => {
    const newImages = Array.from(e.target.files);
    setProductImg((prev) => [...prev, ...newImages]);
    fileInputRef.current.value = null; // Clear input after uploading
  };

  // Handler for replacing an image during edit
  const handleEditImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage && editIndex !== null) {
      const updatedImages = [...productImg];
      updatedImages[editIndex] = newImage; // Replace the specific image
      setProductImg(updatedImages);
      setEditIndex(null); // Reset edit mode
    }
    fileInputRef.current.value = null; // Clear input after uploading
  };

  // Trigger file input for editing
  const handleEditClick = (index) => {
    setEditIndex(index);
    fileInputRef.current.click();
  };

  // Handler for deleting an image
  const handleDeleteImage = (index) => {
    setProductImg(productImg.filter((_, imgIndex) => imgIndex !== index));
  };

  const [categoryList, SetCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCatPage, setsubCatPage]=useState(1)

  useEffect(() => {
    fetchCategoryData();
  }, []);

  useEffect(()=>{
    fetchSubCategoryData();
  },[subCatPage])

  const fetchCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = "/api/listproductCategory";
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        SetCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const fetchSubCategoryData = async () => {
    try {
      const link = process.env.REACT_APP_BASE_URL;
      const endpoint = `/api/listproductSubCategory?page=${subCatPage}`;
      const url = link + endpoint;

      const res = await api.get(`${url}`);
      if (res.status == 200) {
        const data = res.data.results;

        setSubCategoryList(data);
        setpagination({next:res.data.next})
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBackSubCategoryBtn=()=>{
    if(subCatPage>1){
      setsubCatPage(subCatPage-1)
    }
  }

  const handleForwardSubCategoryBtn=()=>{
    if(pagination.next){
      setsubCatPage((prev)=>prev+1)
    }
  }

  useEffect(() => {
    // Fetch data using the userId
    const fetchProduct = async () => {
      const link = process.env.REACT_APP_BASE_URL;
      const response = await api.get(
        `${link}/api/product-details/?product_id=${userId}`
      ); // Assume the API is set up
      const data = response.data;
      console.log(data);
      setProduct(data);
      setAllData((prevData) => ({
        ...prevData,
        name: data?.product_name || "",
        description: data?.product_description || "",
        price: data?.product_fixed_price || "",
        Category: data?.product_category || "",
        SubCategory: data?.product_sub_category || "",
        categoryDetails:
          data?.product_category_details?.category_description || "",
        subCategoryDetails:
          data?.product_sub_category_details?.sub_category_description || "",
        breadth: data?.breadth || "",
        height: data?.height || "",
        weight: data?.weight || "",
        wattage: data?.wattage || "",
        cutout: data?.cutout || "",
        power: data?.power || "",
        voltage: data?.voltage || "",
        dimension: data?.dimension || "",
        pf: data?.pf || "",
        light_flux: data?.light_flux || "",
        cut_dia: data?.cut_dia || "",
        cut_size: data?.cut_size || "",
        q1_price: data?.q1_price || "",
        q2_price: data?.q2_price || "",
        q3_price: data?.q3_price || "",
        q4_price: data?.q4_price || "",
        q5_price: data?.q5_price || "",
      }));
      const imageUrls = data?.product_image_url
        ? data.product_image_url.split(",")
        : [];
      setProductImg(imageUrls);
    };
    fetchProduct();
  }, [userId]);

  const handleUpdateProduct = async () => {
    const link = process.env.REACT_APP_BASE_URL;
    const formData = new FormData();

    //     const excludedKeys = ['productImg','name','description','price']; // specify the keys you want to exclude

    // Object.entries(AllData).forEach(([key, value]) => {
    //   if (!excludedKeys.includes(key)) { // check if the key is not in excludedKeys
    //     formData.append(key, value);
    //   }
    // });

    if (!AllData.Category || !AllData.SubCategory) {
      alert("Enter Category and SubCategory");
      return;
    }

    const updatedProduct = {
      product_name: AllData.name || "",
      product_description: AllData.description || "",
      product_fixed_price: AllData.price || "",
      // files: AllData.productImg || [],  // Assuming productImg is an array
      product_category: AllData.Category || null,
      product_sub_category: AllData.SubCategory || null,
      product_category_details: AllData.categoryDetails || null,
      product_sub_category_details: AllData.subCategoryDetails || null,
      product_active_status: activeStatus,
      breadth: AllData.breadth || null,
      height: AllData.height || null,
      weight: AllData.weight || null,
      wattage: AllData.wattage || null,
      cutout: AllData.cutout || null,
      power: AllData.power || null,
      voltage: AllData.voltage || null,
      dimension: AllData.dimension || null,
      pf: AllData.pf || null,
      light_flux: AllData.light_flux || null,
      cut_dia: AllData.cut_dia || null,
      cut_size: AllData.cut_size || null,
      created_by: admin_id,
      updated_by: admin_id,
      q1_price: AllData.q1_price || "",
      q2_price: AllData.q2_price || "",
      q3_price: AllData.q3_price || "",
      q4_price: AllData.q4_price || "",
      q5_price: AllData.q5_price || "",
    };

    for (let key in updatedProduct) {
      formData.append(key, updatedProduct[key]);
    }

    productImg.forEach((file) => formData.append("files", file));

    // formData.append('product_name', AllData.name)

    const response = await api.put(
      `${link}/api/product-update/${userId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status == 200) {
      alert("Product updated successfully!");
    } else {
      alert("Failed to update product.");
    }
  };

  return (
    <div>
      <div className="ecom-allusers-container">
        <DashBoardHead />

        <div className="ecom-product-detail-main-section">
          <div>
            <Link to="/admin/allProducts">Back</Link>
          </div>

          <div className="ecom-product-detail-container">
            <div className="ecom-product-detail-image-section">
              {productImg.length > 0 &&
                productImg.map((file, index) => (
                  <div key={index} className="ecom-product-detail-images">
                    <div className="ecom-product1-image">
                      <img
                        src={
                          file instanceof File
                            ? URL.createObjectURL(file)
                            : file
                        }
                        alt={`Selected Image ${index + 1}`}
                      />
                    </div>
                    <div className="ecom-product-detail-edit-image-text">
                      <p onClick={() => handleEditClick(index)}>Edit</p>
                      <p
                        style={{ color: "red" }}
                        onClick={() => handleDeleteImage(index)}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                ))}

              <div className="ecom-product-detail-addimage-text">
                <label>
                  <input
                    type="file"
                    multiple={editIndex === null} // Allow multiple files only when not editing
                    ref={fileInputRef}
                    accept="image/*"
                    style={{ display: "none" }} // Hide the actual file input
                    onChange={
                      editIndex === null ? handleAddImage : handleEditImage
                    }
                  />
                  <p
                  // onClick={() => { if (editIndex === null) fileInputRef.current.click(); }}
                  // onClick={handleAddImageClick}
                  >
                    Add Images
                  </p>
                </label>
              </div>
            </div>

            <div className="ecom-subcategory-activestate">
              <p>Active Status: </p>
              <label>
                <input
                  type="radio"
                  name="activeStatus"
                  value="true"
                  checked={activeStatus === true}
                  onChange={() => setActiveStatus(true)}
                />
                True
              </label>
              <label>
                <input
                  type="radio"
                  name="activeStatus"
                  value="false"
                  checked={activeStatus === false}
                  onChange={() => setActiveStatus(false)}
                />
                False
              </label>
            </div>

            <div className="ecom-product-detail-section">
              <div className="ecom-product-left-detail-sectionwise">
                {Object.keys(AllData).map((key) => {
                  if (
                    showOnRightSide.includes(key) ||
                    key == "description" ||
                    key == "productImg"
                  ) {
                    return null;
                  }

                  return (
                    <p>
                      <span>{key.replace(/_/g, " ").toUpperCase()}:</span>
                      {key == "Category" ? (
                        // Dropdown for 'category'
                        <select
                          value={AllData[key] || ""}
                          onChange={(e) => updateField(key, e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {categoryList.map((option) => (
                            <option key={option} value={option.id}>
                              {option.category_name}
                            </option>
                          ))}
                        </select>
                      ) : key == "SubCategory" ? (
                        <>
                        <select
                          value={AllData[key] || ""}
                          onChange={(e) => updateField(key, e.target.value)}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                                                  
                          {subCategoryList.map((option) => (
                            <option key={option} value={option.id}>
                              {option.sub_category_name}
                            </option>
                          ))}
                        </select>
                       <div style={{display:'flex'}}> <div
                       style={{cursor:'pointer'}}
                       onClick={handleBackSubCategoryBtn} disabled={subCatPage==1}>
                                      <IoIosArrowBack />
                                    </div>
                                    <span>{subCatPage}</span>
                                    <div 
                                    style={{cursor:'pointer'}}
                                    onClick={handleForwardSubCategoryBtn} disabled={!pagination.next}>
                                      <IoIosArrowForward />
                                    </div></div>
                        </>
                      ) : (
                        <input
                          type="text"
                          placeholder={`Enter ${key.replace(/_/g, " ")}`}
                          value={AllData[key] || ""}
                          onChange={(e) => updateField(key, e.target.value)}
                        />
                      )}
                    </p>
                  );
                })}
              </div>

              <div className="ecom-product-right-detail-section">
                {Object.keys(AllData).map(getRightSideFields)}
              </div>
            </div>

            <div className="ecom-product-detail-desc-section">
              <p>Description:</p>
              <div className="ecom-product-detail-desc-text">
                <textarea
                  placeholder="Product Description"
                  value={AllData.description}
                  onChange={(e) => updateField("description", e.target.value)}
                  style={{
                    background: "none",
                    border: "none",
                    outline: "none",
                    resize: "none", // Optional: Remove resize handle
                  }}
                />
              </div>
            </div>

            <div className="ecom-product-detail-btns">
              <button
                className="ecom-product-detail-cancel-btn"
                onClick={() => navigate("/admin/allProducts")}
              >
                Cancel
              </button>
              <button
                className="ecom-product-detail-save-btn"
                onClick={handleUpdateProduct}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EcomProductDetail;

