import React, { useEffect, useState } from 'react'
import './Layout.css'
import { NavLink, Outlet } from 'react-router-dom';
import { MdArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { LuUsers } from "react-icons/lu";
import { MdProductionQuantityLimits } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { TbUsersPlus } from "react-icons/tb";
import { MdOutlineCategory } from "react-icons/md";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { IoBagOutline } from "react-icons/io5";

function Layout() {

    const[isSidebarExpanded, setIsSidebarExpanded]=useState(true);
    

    const toggleSideBar=()=>{
        setIsSidebarExpanded(!isSidebarExpanded);
    }



  return (
    <div className='ecom-layout-main'>

        <div className={`ecom-layout-sidebar ${isSidebarExpanded ? 'ecom-expanded' :'ecom-collapsed'}`}>
            <div className='ecom-sidebar-header'>
                <button onClick={toggleSideBar} className='ecom-toggle-button'>
                    {isSidebarExpanded ? <MdArrowBackIos/> :<MdOutlineArrowForwardIos />}
                </button>
            </div>

            <div className='ecom-nav-menu'>

                <NavLink to='/admin'
                className={({isActive})=>`ecom-nav-items ${isActive ? 'ecom-nav-active' :''}`}>
                <LuLayoutDashboard className='ecom-nav-icon'/> {isSidebarExpanded && <span>Dashboard</span>}
                </NavLink>

                <NavLink to='/admin/allEndUsers'
                 className={({isActive})=>`ecom-nav-items ${isActive ? 'ecom-nav-active' :''}`}>
                <LuUsers />  {isSidebarExpanded && <span>Users</span>}
                </NavLink>

                <NavLink to='/admin/allEmployees'
                className={({isActive})=>`ecom-nav-items ${isActive ? 'ecom-nav-active' :''}`}>
                    <TbUsersPlus/> {isSidebarExpanded && <span>Employees</span>}
                </NavLink>

                <NavLink to='/admin/allProducts'
                 className={({isActive})=>`ecom-nav-items ${isActive ? 'ecom-nav-active' :''}`}>
                <MdProductionQuantityLimits />  {isSidebarExpanded && <span>All Products</span>}
                </NavLink>

                <NavLink to='/admin/allcategory'
                className={({isActive}) => `ecom-nav-items ${isActive ? 'ecom-nav-active' : ''}`}>
                    <MdOutlineCategory/> {isSidebarExpanded && <span>All Category</span>}
                </NavLink>

                <NavLink to='/admin/allsubcategory'
                className={({isActive}) => `ecom-nav-items ${isActive ? 'ecom-nav-active' : ''}`}>
                    <VscTypeHierarchySub/> {isSidebarExpanded && <span>SubCategories</span>}
                </NavLink>

                <NavLink to='/admin/allServices'
                className={({isActive}) => `ecom-nav-items ${isActive ? 'ecom-nav-active' : ''}`}>
                    <GrServices/> {isSidebarExpanded && <span>All Services</span>}
                </NavLink>

                <NavLink to='/admin/orderHistory'
                className={({isActive}) => `ecom-nav-items ${isActive ? 'ecom-nav-active' : ''}`}>
                    <IoBagOutline /> {isSidebarExpanded && <span>Order History</span>}
                    </NavLink>

            </div>
        </div>

        <div className='ecom-content'>
            <Outlet/>
        </div>
      
    </div>
  )
}

export default Layout;