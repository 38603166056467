import React from 'react'
import './BuyProduct.css'

function BuyProduct() {
  return (
    <div>
      
    </div>
  )
}

export default BuyProduct
