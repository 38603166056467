import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
//   const { isLoggedIn } = useAuth();
  const isLoggedIn=localStorage.getItem('accessToken')

  if (!isLoggedIn) {
    // Redirect to login page if not logged in
    return <Navigate to="/register" replace />;
  }

  // Allow access if logged in
  return children;
};

export default ProtectedRoute;
